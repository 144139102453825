import { Editor } from 'ckeditor5/src/core';
import { EmitterMixin, EventInfo, type EventInfo as EventInfoType } from 'ckeditor5/src/utils';
import { Command, Plugin } from 'ckeditor5/src/core';
import { DowncastWriter, Position, ViewElement, Element, Node, DocumentFragment } from 'ckeditor5/src/engine';

class SuggestionCommand extends Command {
  protected callback: () => void;

  constructor(editor: Editor, callback: () => void) {
    super(editor);

    this.callback = callback;
  }

  override execute() {
    this.callback();
  }
}

export type AiSuggestionsFetcher = ((serializedHTML: string, instructions?: string[]) => Promise<string[]>) & {
  cancel: () => void;
};
export type Subscriber = (suggestions: string[], current: number) => void;
export type SuggestionCommandType = 'accept' | 'acceptWord' | 'acceptSentence' | 'prev' | 'next' | 'reject';
export type AcceptEventPayload = {
  suggestionSlice: string;
};
export type PlaceholderEventPayload = { element: HTMLElement; anchor: HTMLElement | null };

export type FetchOptions = {
  rootText?: string;
  instructions?: string[];
};

type SuggestionCommandConfig = {
  name: SuggestionCommandType;
  label: string;
  keystroke: string;
  preventDefault?: boolean;
};

export type SuggestionCommandItem = Omit<SuggestionCommandConfig, 'preventDefault'> & {
  command: SuggestionCommand;
};

type CommandHandlers = Record<SuggestionCommandType, (e?: KeyboardEvent) => void>;

export type Options = {
  debounceTimeMs: number;
};

class SuggestionsCommander {
  protected editor: Editor;
  items: Map<SuggestionCommandType, SuggestionCommandItem> = new Map();

  protected addItem(
    handlers: CommandHandlers,
    { name, label, keystroke, preventDefault = false }: SuggestionCommandConfig
  ) {
    const handler = handlers[name];

    if (!handler) return;

    const { commands, keystrokes } = this.editor;

    const command = new SuggestionCommand(this.editor, handler);

    commands.add(`suggestions:${name}`, command);
    keystrokes.set(keystroke, (e) => {
      if (!command.isEnabled) return;

      if (preventDefault) {
        e.preventDefault();
      }

      handler(e);
    });

    this.items.set(name, {
      name,
      label,
      keystroke,
      command,
    });
  }

  protected setup(handlers: CommandHandlers) {
    const { t, accessibility } = this.editor;

    this.addItem(handlers, {
      name: 'accept',
      label: t('Accept'),
      keystroke: 'Tab',
      preventDefault: true,
    });

    this.addItem(handlers, {
      name: 'acceptWord',
      label: t('Accept word'),
      keystroke: 'Ctrl + ArrowRight',
    });

    this.addItem(handlers, {
      name: 'acceptSentence',
      label: t('Accept sentence'),
      keystroke: 'Ctrl + Shift + ArrowRight',
    });

    this.addItem(handlers, {
      name: 'prev',
      label: t('Previous suggestion'),
      keystroke: 'Ctrl + [',
    });

    this.addItem(handlers, {
      name: 'next',
      label: t('Next suggestion'),
      keystroke: 'Ctrl + ]',
    });

    this.addItem(handlers, {
      name: 'reject',
      label: t('Reject'),
      keystroke: 'Esc',
    });

    accessibility.addKeystrokeInfos({
      keystrokes: Array.from(this.items).map(([_name, { label, keystroke }]) => ({ label, keystroke })),
    });
  }

  constructor(editor: Editor, handlers: CommandHandlers) {
    this.editor = editor;
    this.setup(handlers);
  }

  exec(name: SuggestionCommandType) {
    this.editor.execute(`suggestions:${name}`);
  }

  isNavigationEnabled() {
    const prevCommand = this.items.get('prev')?.command;
    const nextCommand = this.items.get('next')?.command;

    return !!(prevCommand?.isEnabled && nextCommand?.isEnabled);
  }

  disableNavigation = () => {
    const prevCommand = this.items.get('prev')?.command;
    const nextCommand = this.items.get('next')?.command;

    if (prevCommand) {
      prevCommand.isEnabled = false;
    }

    if (nextCommand) {
      nextCommand.isEnabled = false;
    }
  };

  enableNavigation = () => {
    const prevCommand = this.items.get('prev')?.command;
    const nextCommand = this.items.get('next')?.command;

    if (prevCommand) {
      prevCommand.isEnabled = true;
    }

    if (nextCommand) {
      nextCommand.isEnabled = true;
    }
  };
}

class CurrentSuggestion {
  protected commands: SuggestionsCommander;
  protected _root: string = '';
  protected _completed: string = '';
  protected _accepted: string = '';

  constructor(commands: SuggestionsCommander) {
    this.commands = commands;
  }

  get isAccepted() {
    return this._accepted.length > this._root.length;
  }

  get accepted() {
    return this._accepted;
  }

  get completed() {
    return this._completed;
  }

  get remaining() {
    return this._completed.slice(this._accepted.length);
  }

  reset = (rootText: string, value: string) => {
    this._root = rootText;
    this._completed = rootText + value;
    this._accepted = rootText;
  };

  accept = (suggestionSlice: string) => {
    this._accepted = `${this._accepted}${suggestionSlice}`;

    if (this.isAccepted) {
      this.commands.disableNavigation();
    }
  };

  reject = (suggestionSlice: string) => {
    this._accepted = this._accepted.slice(0, -suggestionSlice.length);

    if (!this.isAccepted) {
      this.commands.enableNavigation();
    }
  };
}

export class SuggestionsAPI extends EmitterMixin() {
  protected readonly debounceTimeMs: number;
  protected editor: Editor;

  protected _rootText: string = '';
  protected _fetcher: AiSuggestionsFetcher | null = null;
  protected _suggestions: string[] = [];
  protected _currentSuggestionIndex: number = 0;
  protected _currentSuggestion: CurrentSuggestion;

  commands: SuggestionsCommander;

  constructor(editor: Editor, { debounceTimeMs }: Options) {
    super();

    this.editor = editor;
    this.debounceTimeMs = debounceTimeMs;

    const commands = new SuggestionsCommander(editor, {
      accept: () => this.accept(),
      acceptWord: () => this.accept(/^\s*[^\s]+/),
      acceptSentence: () => this.accept(/\s*[^.!?]*[.!?]/),
      prev: this.prev,
      next: this.next,
      reject: this.reject,
    });

    this.commands = commands;
    this._currentSuggestion = new CurrentSuggestion(commands);
  }

  protected nextTick() {
    return new Promise((resolve) => setTimeout(resolve, 0));
  }

  get fetcher() {
    return this._fetcher;
  }

  set fetcher(fetcher: AiSuggestionsFetcher | null) {
    const prevFetcher = this._fetcher;

    if (prevFetcher) {
      prevFetcher.cancel();
    }

    if (!fetcher && prevFetcher) {
      this.editor.fire('suggestions:stop');
    }

    if (!fetcher) {
      this._fetcher = null;

      return;
    }

    let rejectFn: (reason?: any) => void;
    let timeout: any;

    const debouncedFetcher = async (html: string, instructions?: string[]) => {
      const debouncedPromise = new Promise<string[]>((resolve, reject) => {
        rejectFn = reject;
        timeout = setTimeout(async () => resolve(await fetcher(html, instructions)), this.debounceTimeMs);
      });

      try {
        return await debouncedPromise;
      } catch (error) {
        return [];
      }
    };

    this._fetcher = Object.assign(debouncedFetcher, {
      cancel: () => {
        clearTimeout(timeout);
        rejectFn?.(new Error('Fetcher was cancelled'));
        fetcher.cancel();
      },
    });

    if (prevFetcher) return;

    this.editor.fire('suggestions:ready', this, this._currentSuggestion);
  }

  get current() {
    return this._suggestions[this._currentSuggestionIndex] || '';
  }

  setCurrent(index: number) {
    // when some part of the suggestion is already in the document, we don't want to switch to another suggestion
    if (
      this._currentSuggestion.isAccepted ||
      index < 0 ||
      index >= this._suggestions.length ||
      index === this._currentSuggestionIndex
    )
      return;

    this._currentSuggestionIndex = index;

    if (this.suggestions.length > 1) {
      this.commands.enableNavigation();
    } else {
      this.commands.disableNavigation();
    }

    const currSuggestion = this._suggestions[this._currentSuggestionIndex];

    this._currentSuggestion.reset(this._rootText, currSuggestion);
    this.fire(new EventInfo({ suggestion: currSuggestion }, 'switch'));

    return currSuggestion;
  }

  get suggestions() {
    return this._suggestions;
  }

  set suggestions(suggestions: string[]) {
    this.fetcher?.cancel();

    this._suggestions = suggestions;
    this._currentSuggestion.reset(this._rootText, this.current);
    this.setCurrent(0);

    this.fire(new EventInfo({ suggestions: this._suggestions }, 'update'));
  }

  get rootText() {
    return this._rootText;
  }

  accept = (pattern?: RegExp) => {
    if (pattern) {
      const suggestionSlice = this._currentSuggestion.remaining.match(pattern)?.[0];

      if (suggestionSlice) {
        this.fire('accept', { suggestionSlice });
      }
    } else if (this._currentSuggestion.remaining) {
      this.fire('accept', { suggestionSlice: this._currentSuggestion.remaining });
    }
  };

  next = () => {
    if (!this.commands.isNavigationEnabled()) return null;

    return this.setCurrent((this._currentSuggestionIndex + 1) % this._suggestions.length);
  };

  prev = () => {
    if (!this.commands.isNavigationEnabled()) return null;

    return this.setCurrent((this._currentSuggestionIndex - 1 + this._suggestions.length) % this._suggestions.length);
  };

  reject = () => {
    this.suggestions = [];
  };

  async refetch({ rootText = this.rootText, instructions = [] }: FetchOptions = {}) {
    const {
      editing: { view },
    } = this.editor;

    await this.nextTick();

    const domRoot = view.getDomRoot();

    if (!this.fetcher || !domRoot) return;

    this._rootText = rootText;

    // TODO: remove it if the problem with white spaces remains
    const html = domRoot.innerHTML.replace(
      /<span id="suggestion"(\sdata-suggestion=".*?")?\s?><span id="suggestion-anchor"><\/span><\/span>/,
      ''
    );
    const newSuggestions = await this.fetcher(html, instructions);

    if (newSuggestions.length > 0) {
      this.suggestions = newSuggestions;
    }
  }

  async rejectAndRefetch(options?: FetchOptions) {
    this.reject();
    await this.refetch(options);
  }

  cancelPendingFetch() {
    this.fetcher?.cancel();
  }

  onUpdate(callback: Subscriber) {
    const onChange = () => callback(this.suggestions, this._currentSuggestionIndex);

    this.on('switch', onChange);
    this.on('update', onChange);

    return () => {
      this.off('switch', onChange);
      this.off('update', onChange);
    };
  }

  onAccept(callback: (payload: AcceptEventPayload) => void) {
    const listener = (_e: EventInfoType, payload: AcceptEventPayload) => callback(payload);

    this.on('accept', listener);

    return () => this.off('accept', listener);
  }

  onPlaceholder(callback: (type: 'enter' | 'exit', payload: PlaceholderEventPayload) => void) {
    const listener = (e: EventInfoType, payload: PlaceholderEventPayload) => {
      const type = e.name === 'suggestions:placeholder:enter' ? 'enter' : 'exit';

      callback(type, payload);
    };

    this.editor.on('suggestions:placeholder:enter', listener);
    this.editor.on('suggestions:placeholder:exit', listener);

    return () => {
      this.editor.off('suggestions:placeholder:enter', listener);
      this.editor.off('suggestions:placeholder:exit', listener);
    };
  }

  focusEditor() {
    this.editor.editing.view.focus();
  }
}

class PlaceholderDom {
  protected readonly MAX_ATTEMPTS = 20;

  protected _suggestionElement: HTMLElement | null = null;
  protected _anchorElement: HTMLElement | null = null;
  protected cancel: VoidFunction | null = null;
  protected retries = 0;

  get suggestionElement() {
    return this._suggestionElement;
  }

  get anchorElement() {
    return this._anchorElement;
  }

  getHtmlElement() {
    this.cancel?.();
    this.retries = 0;

    return new Promise<PlaceholderEventPayload | null>((resolve, reject) => {
      let interval: any;

      interval = setInterval(() => {
        if (++this.retries >= this.MAX_ATTEMPTS) {
          return this.cancel?.();
        }

        this._suggestionElement = document.getElementById('suggestion');

        if (!this._suggestionElement) return;

        this._anchorElement = document.getElementById('suggestion-anchor');
        clearInterval(interval);
        resolve({ element: this._suggestionElement, anchor: this._anchorElement });
      }, 100);

      this.cancel = () => {
        clearInterval(interval);
        reject();
      };
    });
  }
}

class SuggestionTarget {
  protected editor: Editor;
  protected target: ViewElement | null = null;
  protected placeholder: ViewElement | null = null;
  protected placeholderDom: PlaceholderDom;

  constructor(editor: Editor) {
    this.editor = editor;
    this.placeholderDom = new PlaceholderDom();
  }

  protected getPlaceholderPosition(element: Element) {
    const {
      editing: { mapper },
    } = this.editor;

    const position = Position._createAt(element, 'end');

    // This is a hacky solution, I didn't find a reason why it throws but it works
    try {
      return mapper.toViewPosition(position);
    } catch (error) {
      return mapper.toViewPosition(position.getShiftedBy(-1));
    }
  }

  protected insertPlaceholder(viewWriter: DowncastWriter, closestBlockElement: Element) {
    const position = this.getPlaceholderPosition(closestBlockElement);
    const anchor = viewWriter.createAttributeElement('span', { id: 'suggestion-anchor' });
    this.placeholder = viewWriter.createAttributeElement('span', { id: 'suggestion' });

    this.placeholder._insertChild(0, anchor);
    viewWriter.insert(position, this.placeholder);
  }

  exists() {
    return !!this.target;
  }

  isSame(target: ViewElement) {
    return this.target === target;
  }

  getModel() {
    const {
      editing: { mapper },
    } = this.editor;

    return this.target ? mapper.toModelElement(this.target) : null;
  }

  removeCurrent() {
    const {
      editing: { view },
    } = this.editor;
    const { suggestionElement: placeholderHtmlElement, anchorElement } = this.placeholderDom;

    if (placeholderHtmlElement) {
      this.editor.fire('suggestions:placeholder:exit', {
        element: placeholderHtmlElement,
        anchor: anchorElement,
      });
    }

    view.change((viewWriter) => {
      if (this.placeholder) {
        viewWriter.remove(this.placeholder);
        this.placeholder = null;
      }

      if (this.target) {
        viewWriter.removeAttribute('data-suggestion-target', this.target);
        this.target = null;
      }
    });
  }

  newTarget(modelElement: Element) {
    const {
      editing: { mapper, view },
    } = this.editor;

    const viewElement = mapper.toViewElement(modelElement);

    if (!viewElement) return;

    if (this.target) {
      this.removeCurrent();
    }

    this.target = viewElement;

    view.change(async (viewWriter) => {
      if (!this.target) return;

      viewWriter.setAttribute('data-suggestion-target', '', this.target);
      this.insertPlaceholder(viewWriter, modelElement);

      try {
        const payload = await this.placeholderDom.getHtmlElement();

        if (payload) {
          this.editor.fire('suggestions:placeholder:enter', payload);
        }
      } catch (error) {
        console.warn('Failed to find placeholder element in the DOM');
      }
    });
  }

  updatePlaceholder(text?: string | null) {
    const {
      editing: { view },
    } = this.editor;

    view.change((viewWriter) => {
      if (!this.placeholder) return;

      if (text) {
        viewWriter.setAttribute('data-suggestion', text, this.placeholder);
      } else {
        viewWriter.removeAttribute('data-suggestion', this.placeholder);
      }
    });
  }

  clearPlaceholder() {
    this.updatePlaceholder(null);
  }
}

export default class InlineSuggestions extends Plugin {
  protected currentPath: number[] = [];
  protected target: SuggestionTarget;
  protected api: SuggestionsAPI | null = null;

  protected cleanups: VoidFunction[] = [];
  protected current: CurrentSuggestion | null = null;

  constructor(editor: Editor) {
    super(editor);

    this.target = new SuggestionTarget(editor);
  }

  protected findClosestBlockElement(item: Position | Element | DocumentFragment): Element | null {
    if (item instanceof Element && item.name === 'paragraph') return item;

    if (item instanceof DocumentFragment) return null;

    if (!item.parent) return null;

    return this.findClosestBlockElement(item.parent);
  }

  protected getTextContent(element: Element): string {
    let textContent = '';

    for (const child of element.getChildren()) {
      if ('name' in child) {
        textContent += this.getTextContent(child as Element);
      } else {
        textContent += (child as Node & { data?: string }).data ?? '';
      }
    }

    return textContent;
  }

  protected removeCurrentSuggestion() {
    if (!this.target.exists()) return;

    this.target.removeCurrent();

    if (this.api) {
      this.api.suggestions = [];
    }
  }

  protected showNewSuggestion(blockElement: Element) {
    const currentText = this.getTextContent(blockElement);

    if (!this.api) return;

    this.target.newTarget(blockElement);
    this.api.rejectAndRefetch({ rootText: currentText });
  }

  protected handleSuggestionsUpdate = () => {
    this.target.updatePlaceholder(this.current?.remaining);
  };

  /**
   * This functions handles the accept event from the suggestions API.
   * It inserts the accepted slice of the suggestion into the model.
   * Such insertion triggers change:data event which calls the handleDataChange method which updates the view.
   */
  protected handleAccept = ({ suggestionSlice }: AcceptEventPayload) => {
    const { model } = this.editor;

    model.change((modelWriter) => {
      const modelElement = this.target.getModel();

      if (!this.api || !modelElement) return;

      modelWriter.insert(
        modelWriter.createText(suggestionSlice),
        Position._createAt(modelElement, modelElement.maxOffset)
      );
    });
  };

  protected handleApiReady = (_e: EventInfoType, api: SuggestionsAPI, currentSuggestion: CurrentSuggestion) => {
    this.api = api;
    this.current = currentSuggestion;
    this.cleanups = [this.api.onUpdate(this.handleSuggestionsUpdate), this.api.onAccept(this.handleAccept)];
  };

  protected handleApiStop = () => {
    this.cleanups.forEach((cleanup) => cleanup());

    this.api = null;
    this.current = null;
    this.cleanups = [];

    this.target.removeCurrent();
  };

  protected isSameElementPath(pathA: number[], pathB: number[]) {
    const parentAPath = pathA.slice(0, -1);
    const parentBPath = pathB.slice(0, -1);

    return parentAPath.length === parentBPath.length && parentAPath.every((item, index) => item === parentBPath[index]);
  }

  protected getOffsetDifference(pathA: number[], pathB: number[]) {
    return pathB[pathB.length - 1] - pathA[pathA.length - 1];
  }

  protected handleSelectionChange = () => {
    const {
      model: {
        document: { selection },
      },
    } = this.editor;

    const lastPosition = selection.getLastPosition();
    const element = lastPosition ? this.findClosestBlockElement(lastPosition) : null;

    if (!lastPosition || !element) {
      this.currentPath = [];

      return;
    }

    const prevPath = Array.from(this.currentPath);
    this.currentPath = Array.from(lastPosition.path);

    if (!this.api) return;

    if (!lastPosition.isAtEnd || lastPosition.parent.isEmpty) {
      this.api.cancelPendingFetch();
      this.removeCurrentSuggestion();

      return;
    }

    if (!this.isSameElementPath(prevPath, this.currentPath) || !this.target.exists()) {
      this.api.cancelPendingFetch();
      this.showNewSuggestion(element);

      return;
    }

    if (!this.current) return;

    const offsetDifference = this.getOffsetDifference(prevPath, this.currentPath);
    const textContent = this.getTextContent(element);

    if (offsetDifference !== 0) {
      this.api.cancelPendingFetch();
    }

    if (offsetDifference > 0) {
      const addedText = textContent.slice(-offsetDifference);

      if (this.current.remaining.startsWith(addedText)) {
        this.current.accept(addedText);
        this.target.updatePlaceholder(this.current.remaining);

        if (!this.current.remaining) {
          this.api.rejectAndRefetch({ rootText: textContent });
        }
      } else {
        // temporarily empty the placeholder
        this.target.clearPlaceholder();
        this.api.rejectAndRefetch({ rootText: textContent });
      }
    } else if (offsetDifference < 0) {
      const removedText = this.current.accepted.slice(offsetDifference);

      this.current.reject(removedText);
      this.target.updatePlaceholder(this.current.remaining);
      this.api.refetch({ rootText: textContent });
    }
  };

  init() {
    const {
      model: {
        document: { selection },
      },
    } = this.editor;

    this.editor.on('suggestions:ready', this.handleApiReady);
    this.editor.on('suggestions:stop', this.handleApiStop);

    selection.on('change:range', this.handleSelectionChange);
  }

  override destroy() {
    const {
      model: {
        document: { selection },
      },
    } = this.editor;

    this.editor.off('suggestions:ready', this.handleApiReady);
    this.editor.off('suggestions:stop', this.handleApiStop);

    selection.off('change:range', this.handleSelectionChange);
    this.handleApiStop();
  }
}
