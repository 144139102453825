import { useMemo, useCallback } from "react";
import {
  useNodes,
  Node,
  NodeToolbar,
  useStoreApi,
  useReactFlow,
  getNodesBounds,
} from "@xyflow/react";

import { getId } from "./utils";

const padding = 25;

export default function SelectedNodesToolbar() {
  const nodes = useNodes();
  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const selectedNodes = useMemo(
    () => nodes.filter((node) => node.selected && !node.parentId),
    [nodes]
  );
  const selectedNodeIds = useMemo(
    () => selectedNodes.map((node) => node.id),
    [selectedNodes]
  );
  const isVisible = selectedNodeIds.length > 1;

  const onGroup = useCallback(() => {
    const rectOfNodes = getNodesBounds(selectedNodes);
    const groupId = getId("group");
    const parentPosition = {
      x: rectOfNodes.x,
      y: rectOfNodes.y,
    };
    const groupNode = {
      id: groupId,
      type: "group",
      position: parentPosition,
      style: {
        width: rectOfNodes.width + padding * 2,
        height: rectOfNodes.height + padding * 2,
      },
      data: {},
    };

    const nextNodes: Node[] = nodes.map((node) => {
      if (selectedNodeIds.includes(node.id)) {
        return {
          ...node,
          position: {
            x: node.position.x - parentPosition.x + padding,
            y: node.position.y - parentPosition.y + padding,
          },
          extent: "parent",
          parentId: groupId,
        };
      }

      return node;
    });

    store.getState().resetSelectedElements();
    store.setState({ nodesSelectionActive: false });
    setNodes([groupNode, ...nextNodes]);
  }, [nodes, selectedNodes, selectedNodeIds, setNodes, store]);

  return (
    <NodeToolbar nodeId={selectedNodeIds} isVisible={isVisible}>
      <button onClick={onGroup}>Group selected nodes</button>
    </NodeToolbar>
  );
}
