import { useCallback, useEffect, useRef, useState } from 'react';

enum AbortReason {
  UNMOUNT = 'UNMOUNT',
  FLUSH = 'FLUSH',
  STOP = 'STOP',
  NEW_STREAM = 'NEW_STREAM',
}

const CHARACTERS_PER_CHUNK = 30;
const INTERVAL_BETWEEN_CHUNKS_MS = 150;

export function useMessageStreamer() {
  const [isStreaming, setIsStreaming] = useState(false);

  const contentRef = useRef('');
  const isStreamingRef = useRef(false);
  const chunkEndIndexRef = useRef(0);
  const eventSourceRef = useRef<EventSource | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    isStreamingRef.current = isStreaming;
  }, [isStreaming]);

  useEffect(() => {
    return () => {
      eventSourceRef.current?.close();
      abortControllerRef.current?.abort(AbortReason.UNMOUNT);
    };
  }, []);

  const getNextChunk = useCallback(() => {
    const endIndex = Math.min(chunkEndIndexRef.current + CHARACTERS_PER_CHUNK, contentRef.current.length);

    const chunk = contentRef.current.slice(chunkEndIndexRef.current, endIndex);
    chunkEndIndexRef.current = endIndex;

    return chunk;
  }, []);

  const getAllChunks = useCallback(() => {
    const endIndex = contentRef.current.length;

    const chunk = contentRef.current.slice(chunkEndIndexRef.current, endIndex);
    chunkEndIndexRef.current = endIndex;

    return chunk;
  }, []);

  const messageStream = useCallback(
    async function* (conversationId: string): AsyncGenerator<string, void, unknown> {
      eventSourceRef.current?.close();
      abortControllerRef.current?.abort(AbortReason.NEW_STREAM);

      // Reset stream state
      contentRef.current = '';
      chunkEndIndexRef.current = 0;

      setIsStreaming(true);
      isStreamingRef.current = true;

      const eventSource = new EventSource(
        `${process.env.REACT_APP_BASE_API_URL}/aoaiCompletionStream?conversation_id=${conversationId}`
      );

      const closeStream = () => {
        setIsStreaming(false);

        eventSourceRef.current?.close();
        eventSourceRef.current = null;
      };

      eventSource.onmessage = ({ data: chunk }) => {
        contentRef.current += chunk;
      };

      eventSource.addEventListener('end-of-stream', () => {
        closeStream();
      });

      eventSource.onerror = (error) => {
        console.error('SSE error:', error);

        closeStream();
      };

      eventSourceRef.current = eventSource;

      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      while (isStreamingRef.current || chunkEndIndexRef.current < contentRef.current.length) {
        if (chunkEndIndexRef.current < contentRef.current.length) {
          yield getNextChunk();
        }

        try {
          await new Promise<void>((resolve, reject) => {
            const timeout = setTimeout(() => {
              signal.removeEventListener('abort', abortHandler);
              resolve();
            }, INTERVAL_BETWEEN_CHUNKS_MS);

            const abortHandler = () => {
              clearTimeout(timeout);
              signal.removeEventListener('abort', abortHandler);
              reject(new Error('Operation aborted'));
            };

            signal.addEventListener('abort', abortHandler);
          });
        } catch (error) {
          const isAbortError = error instanceof Error && error.message === 'Operation aborted';

          if (!isAbortError) {
            throw error;
          }

          if (
            signal.reason === AbortReason.FLUSH ||
            signal.reason === AbortReason.NEW_STREAM
          ) {
            yield getAllChunks();
            continue;
          }

          if (
            signal.reason === AbortReason.UNMOUNT ||
            signal.reason === AbortReason.STOP ||
            signal.reason === AbortReason.NEW_STREAM
          ) {
            break;
          }
        }
      }

      closeStream();
    },
    [getAllChunks, getNextChunk]
  );

  const flushChunks = useCallback(() => {
    abortControllerRef.current?.abort(AbortReason.FLUSH);
  }, []);

  const stopStream = useCallback(async () => {
    abortControllerRef.current?.abort(AbortReason.STOP);
  }, []);

  return {
    isStreaming,
    messageStream,
    flushChunks,
    stopStream,
  };
}
