import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { createProject } from "../../redux/projects/projectsApi";
import CustomDrawer from "../../components/customDrawer";
import CreateProjectForm from "../projects/CreationForm";
import HomeTemplateBox from "./homeTemplateBox";

export default function Home() {
  const [formLoader, setFormLoader] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [formType, setFormType] = useState("");

  const { getAccessTokenSilently } = useAuth0();
  const { profileData } = useAppSelector<any>((state: any) => state.profile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const creatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProject({ body, accessToken })).then((res) => {
            if (res) {
              setOpenProject(false);
              setFormLoader(false);
              if (formType === "document") {
                navigate(
                  `/projects/${res?.payload?.data?.id}?active=documents&id=${res?.payload?.data?.components?.document?.id}`
                );
              }

              if (formType === "diagram") {
                navigate(
                  `/projects/${res?.payload?.data?.id}?active=diagrams&id=${res?.payload?.data?.components?.diagram?.id}`
                );
              }
            }
          });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProject = async (body: any) => {
    creatingProject(body);
  };

  function getGreeting() {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good Morning";
    } else if (currentHour < 18) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  }

  const getStartedArray = [
    {
      id: 1,
      title: "Create new Project",
      description:
        "Cras facilisis eget massa vel mattis. Integer pharetra justo purus, non consequat urna iaculis sit amet.",
      link: () => {
        setFormType("document");
        setOpenProject(true);
      },
    },
    {
      id: 2,
      title: "Create new Diagram",
      description:
        "Cras facilisis eget massa vel mattis. Integer pharetra justo purus, non consequat urna iaculis sit amet.",
      link: () => {
        setFormType("diagram");
        setOpenProject(true);
      },
    },
    {
      id: 3,
      title: "New from template",
      description:
        "Cras facilisis eget massa vel mattis. Integer pharetra justo purus, non consequat urna iaculis sit amet.",
      link: () => navigate("/community-templates"),
    },
    {
      id: 4,
      title: "Document Components",
      description:
        "Cras facilisis eget massa vel mattis. Integer pharetra justo purus, non consequat urna iaculis sit amet.",
      link: () => navigate("/document-components"),
    },
  ];

  const homeTemplatesList = [
    {
      id: "7ca5857f-d9a6-4981-bd61-e36f4870eb28",
      title: "Software development complete process",
      description:
        "Cras facilisis eget massa vel mattis. Integer pharetra justo purus.",
      updated_at: "Tuesday, September 24, 2024",
      category: "Personal",
      complexity: "High",
    },
    {
      id: "7ca5857f-d9a6-4981-bd61-e36f4870eb28",
      title: "Software development complete process",
      description:
        "Cras facilisis eget massa vel mattis. Integer pharetra justo purus.",
      updated_at: "Tuesday, September 24, 2024",
      category: "Personal",
      complexity: "High",
    },
    {
      id: "7ca5857f-d9a6-4981-bd61-e36f4870eb28",
      title: "Software development complete process",
      description:
        "Cras facilisis eget massa vel mattis. Integer pharetra justo purus.",
      updated_at: "Tuesday, September 24, 2024",
      category: "Personal",
      complexity: "High",
    },
    {
      id: "7ca5857f-d9a6-4981-bd61-e36f4870eb28",
      title: "Software development complete process",
      description:
        "Cras facilisis eget massa vel mattis. Integer pharetra justo purus.",
      updated_at: "Tuesday, September 24, 2024",
      category: "Personal",
      complexity: "High",
    },
  ];

  return (
    <div className="p-8 h-[calc(100vh_-_49px)] overflow-y-auto">
      <div className="w-full">
        <div>
          <h1 className="text-xl font-semibold text-customDarkBlue mb-3">
            {getGreeting()} {profileData && `${profileData.first_name}`}!
          </h1>
          <p className="text-sm text-gray-600 mb-4">
            Your full suite of tools for crafting high-quality Solution Design.
            Check our{" "}
            <Link
              to="https://solutionpilot.ai/documentation"
              className="text-blue-500 hover:underline"
            >
              documentation{" "}.
            </Link>
          </p>
        </div>

        <div className="mb-6">
          <h3 className="text-md font-semibold text-customDarkBlue mb-4">
            Get Started
          </h3>

          <div className="grid grid-cols-4 gap-4">
            {getStartedArray.map((data) => {
              return (
                <div
                  className="p-1 bg-gradient-to-r hover:from-blue-400 hover:via-purple-300 hover:to-purple-500 rounded-2xl transition duration-300 ease-in-out"
                  key={data.id}
                  onClick={data.link}
                >
                  <div className="bg-white h-full p-8 rounded-xl border border-zinc-200 cursor-pointer">
                    <h3 className="text-sm font-semibold mb-2 text-customDarkBlue">
                      {data.title}
                    </h3>
                    <p className="text-sm text-gray-600">{data.description}</p>

                    <div className="text-blue-500 text-sm mt-4">Try it now</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <h3 className="text-md font-semibold text-customDarkBlue mb-4">
            Explore Templates
          </h3>

          <div className={`grid w-full grid-cols-4 gap-4`}>
            {homeTemplatesList.map((data: any, index: number) => {
              return (
                <Link
                  key={index}
                  to={`/community-template/${data?.id}`}
                  className={`group relative mb-4`}
                >
                  <HomeTemplateBox data={data} />
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      <CustomDrawer
        open={openProject}
        onCloseModal={() => setOpenProject(false)}
        title="Create Project"
        description="Create new Project, provide Title, Status and Category. Title is mandatory field."
        distanced
      >
        <CreateProjectForm
          onCloseModal={() => setOpenProject(false)}
          editData={null}
          onSubmitForm={onSubmitProject}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>
    </div>
  );
}
