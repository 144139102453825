import React from 'react';
import { classNames } from 'src/utils/utilities';

interface ProgressBarProps {
  percent: string;
  styles?: string;
  color: string;
}

export default function ProgressBar({ percent, styles, color }: ProgressBarProps) {
  return (
    <div className={`w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 ${styles || ''}`}>
      <div
        className={`${color} h-1.5 rounded-full ${styles || ''}`}
        style={{ width: `${percent ? percent : 0}%` }}
      />
    </div>
  );
}

interface RatioBarProps {
  optionAArguments: any[] | null;
  optionBArguments: any[] | null;
}

export function RatioBar({ optionAArguments, optionBArguments }: RatioBarProps) {
  const totalAWeight = optionAArguments?.reduce((sum, { argumentWeight }) => sum + argumentWeight, 0) || 0;
  const totalBWeight = optionBArguments?.reduce((sum, { argumentWeight }) => sum + argumentWeight, 0) || 0;
  const totalWeight = totalAWeight + totalBWeight;

  const calculatePercentages = (weight: number) => {
    return Math.ceil((weight / totalWeight) * 100);
  };

  const optionAPercent = calculatePercentages(totalAWeight);
  const optionBPercent = calculatePercentages(totalBWeight);

  return (
    <div className='flex items-center justify-center'>
      <div
        className='flex items-center'
        style={{ width: '600px' }}
      >
        <span className='mr-2 text-xs font-semibold'>{`${optionAPercent}%`}</span>

        <div className='flex w-full h-1.5 bg-gray-200 rounded-full border'>
          <div
            className={classNames(
              'bg-[#26BD6C] rounded-l-full transition-[width] duration-500 ease-in-out',
              optionBPercent === 0 && 'rounded-r-full'
            )}
            style={{ width: `${optionAPercent}%` }}
          />

          <div
            className={classNames(
              'bg-[#F48E2F] rounded-r-full transition-[width] duration-500 ease-in-out',
              optionAPercent === 0 && 'rounded-l-full'
            )}
            style={{ width: `${optionBPercent}%` }}
          />
        </div>

        <span className='ml-2 text-xs font-semibold'>{`${optionBPercent}%`}</span>
      </div>
    </div>
  );
}
