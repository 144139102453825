import {
  AdjustmentsHorizontalIcon,
  CubeIcon,
  DocumentDuplicateIcon,
  LightBulbIcon,
  PencilSquareIcon,
  PuzzlePieceIcon,
  ScaleIcon,
  Squares2X2Icon,
  TableCellsIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useContext, useState } from 'react';

import Toolbar, { ToolbarItemType } from '../toolbar';
import { DetailsContext } from 'src/contexts/details/context';
import { useAppSelector } from 'src/hooks/store';
import type { SpecialChatProps } from 'src/pages/projectsDetails';
import { removeAllMediaElements } from 'src/utils/utilities';
import type { SpecialMessageType } from 'src/type';
import { SPECIAL_CHAT_IDS } from 'src/config';

interface DocumentHeaderProps {
  updatingProjectDocument: Function;
  documentSaveLoading: boolean;
  onOpenSaveProjectTemp?: Function;
  openSpecialChat: (props: SpecialChatProps) => void;
}

export default function DocumentHeader({
  updatingProjectDocument,
  documentSaveLoading,
  onOpenSaveProjectTemp,
  openSpecialChat,
}: DocumentHeaderProps) {
  const [, setAutoSaveLoader] = useState(false);

  const { projectsByIdRes, selectedDocument } = useAppSelector((state) => state.projects);
  const { type: contextType, toggleAutoSave, updateDocument, editorRef } = useContext(DetailsContext);

  const updatingProject = async () => {
    setAutoSaveLoader(true);

    try {
      await toggleAutoSave();
    } finally {
      setAutoSaveLoader(false);
    }
  };

  const updatingDocument = async (changes: Record<string, any>) => {
    if (!selectedDocument || !projectsByIdRes) return;

    const body = {
      ...selectedDocument,
      ...changes,
    };

    try {
      await updateDocument(
        {
          ...body,
          project_id: projectsByIdRes.id,
        },
        selectedDocument
      );
    } catch {}
  };

  const handleOpenSpecialChat = (type: SpecialMessageType) => {
    openSpecialChat({
      conversation_configuration_id: SPECIAL_CHAT_IDS[type],
      context: {
        type,
        document_id: selectedDocument?.id,
      },
      rootMessage: removeAllMediaElements(editorRef.current?.getData() || ''),
    });
  };

  const saveAsList = [
    {
      id: 1,
      name: 'Template',
      onActionClick: () => (onOpenSaveProjectTemp ? onOpenSaveProjectTemp(true) : console.log('...')),
      icon: <LightBulbIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 2,
      name: 'Design Standard',
      onActionClick: () => console.log('...'),
      icon: <PuzzlePieceIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
  ];

  const validateList = [
    {
      id: 1,
      name: 'Check for limitations',
      onActionClick: () => handleOpenSpecialChat('document:limitations'),
    },
    // {
    //   id: 2,
    //   name: 'Check for spelling mistakes',
    //   onActionClick: () =>
    //     dispatchValidationEvent('document', {
    //       action: 'check-for-spelling-mistakes',
    //       html: editorRef.current?.getData() || '',
    //     }),
    // },
    {
      id: 3,
      name: 'Check for improvements',
      onActionClick: () => handleOpenSpecialChat('document:improvements'),
    },
  ];

  const generateList = [
    {
      id: 1,
      name: 'Diagram',
      onActionClick: () => console.log('...'),
      icon: <CubeIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 2,
      name: 'T-Chart',
      onActionClick: () => console.log('...'),
      icon: <ScaleIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 3,
      name: 'Pros & Cons',
      onActionClick: () => console.log('...'),
      icon: <TableCellsIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 4,
      name: 'Decision Martix',
      onActionClick: () => console.log('...'),
      icon: <AdjustmentsHorizontalIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 5,
      name: 'SWOT',
      onActionClick: () => console.log('...'),
      icon: <Squares2X2Icon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
  ];

  const renderList: ToolbarItemType[] = [
    // new Toolbar.ChatSelectorItem({
    //   id: 1,
    //   selected: selectedDocument?.ai_conversation_id,
    //   setSelected: (chatId) => updatingDocument({ ai_conversation_id: chatId }),
    // }),
    contextType === 'project' && {
      id: 2,
      name: 'Generate',
      actions: generateList,
      width: 110,
    },
    new Toolbar.ValidateItem({
      id: 3,
      actions: validateList,
    }),
    contextType === 'project' && {
      id: 4,
      name: 'Save As',
      actions: saveAsList,
      width: 110,
    },
    new Toolbar.ButtonItem({
      id: 5,
      name: 'Save Changes',
      onActionClick: () => updatingProjectDocument(),
      loading: documentSaveLoading,
      iconComponent: DocumentDuplicateIcon,
      width: 145,
    }),
  ];

  const actionsList = [
    new Toolbar.ButtonItem({
      id: 6,
      name: 'Edit',
      onActionClick: () => console.log('...'),
      iconComponent: PencilSquareIcon,
      width: 0,
    }),
    new Toolbar.ButtonItem({
      id: 7,
      name: 'Delete',
      onActionClick: () => console.log('...'),
      iconComponent: TrashIcon,
      width: 0,
    }),
  ];

  return (
    <Toolbar.Container>
      <Toolbar>
        <Toolbar.Left>
          {contextType === 'project' && (
            <Toolbar.Switch
              enabled={projectsByIdRes?.autosave}
              setEnabled={() => updatingProject()}
            >
              Auto Save
            </Toolbar.Switch>
          )}
          <Toolbar.Switch
            setEnabled={() => updatingDocument({ ai_suggestions: !selectedDocument?.ai_suggestions })}
            enabled={!!selectedDocument?.ai_suggestions}
          >
            Assistant
          </Toolbar.Switch>
        </Toolbar.Left>
        <Toolbar.Right
          visibleItems={renderList}
          hiddenItems={contextType === 'project' ? actionsList : []}
        />
      </Toolbar>
    </Toolbar.Container>
  );
}
