import React, { useState } from "react";
import Account from "./account";
import WrapperLoader from "../../components/wrapperLoader";
import {classNames} from '../../utils/utilities'

const tabs: any = [
  { id: 1, name: "Account", current: true },
  // { id: 2, name: "Notifications", current: false },
  // { id: 3, name: "Billing", current: false },
  // { id: 4, name: "Teams", current: false },
  // { id: 5, name: "Integrations", current: false },
];

export default function ProfilePage() {
  const [tabsList, setProsConsList] = useState(tabs);
  const [activeId, setActiveId] = useState(1);
  const [prosConsByIdLoader] = useState(false);

  const onSelectTab = (id: number) => {
    const updatedProsConsList = tabsList.map((item: any) => ({
      ...item,
      current: item.id === id,
    }));

    setProsConsList(updatedProsConsList);
    setActiveId(id);
  };

  return (
    <WrapperLoader loading={prosConsByIdLoader}>
      <div className="px-6">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabsList.map((tab: any) => (
              <span
                key={tab.id}
                onClick={() => onSelectTab(tab.id)}
                className={classNames(
                  tab.current
                    ? "text-amber-500 border-amber-500"
                    : "border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 py-4 px-1 text-xs font-medium cursor-pointer"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
        <div className="bg-white rounded-md py-6 mt-5" style={{ height: 'calc(100vh - 100px)' }}>
          {activeId === 1 && <Account />}
        </div>
      </div>
    </WrapperLoader>
  );
}
