import {
  ArrowTopRightOnSquareIcon,
  TrashIcon,
  PaintBrushIcon,
} from "@heroicons/react/24/outline";
import {
  Handle,
  NodeResizer,
  NodeToolbar,
  Position,
  useReactFlow,
  useStore,
} from "@xyflow/react";
import { memo, useEffect, useRef, useState, useMemo } from "react";
import { Tooltip } from "react-tooltip";
import debounce from "lodash/debounce";
import { getRelativeNodesBounds } from "./utils";
import useDetachNodes from "./useDetachNodes";
import Popover from "../../../components/popover";
import ColorToolbar from "./shapeNode/colorToolbar";

const lineStyle = { borderColor: "white" };

function TextNode({ id, data }: any) {
  const { border_color, border_style } = data;
  const [nodeText, setNodeText] = useState("");

  const hasParent = useStore((store) => !!store.nodeLookup.get(id)?.parentId);
  const { deleteElements, setNodes } = useReactFlow();
  const detachNodes = useDetachNodes();

  const onDelete = () => deleteElements({ nodes: [{ id }] });
  const onDetach = () => detachNodes([id]);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { minWidth, minHeight } = useStore((store) => {
    const childNodes = Array.from(store.nodeLookup.values()).filter(
      (n) => n.parentId === id
    );
    const rect = getRelativeNodesBounds(childNodes);

    return {
      minWidth: rect.x + rect.width,
      minHeight: rect.y + rect.height,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  const handleResize = useMemo(() => debounce(() => {
    // Any resize logic that might cause the ResizeObserver warning can be placed here.
  }, 100), []);

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserverRef.current.observe(element);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [id, handleResize]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [nodeText]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        return; // Allow new line
      } else {
        onUpdateNodes();
        event.preventDefault(); // Prevent default behavior of Enter key
      }
    }
  };

  const onUpdateNodes = useMemo(() => debounce(() => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? { ...node, data: { ...node.data, label: nodeText || data?.label } }
          : node
      )
    );

    setNodeText("");
  }, 300), [id, nodeText, data?.label, setNodes]);

  const onChangeNodeText = (e: any) => {
    const value = e.target.value;
    setNodeText(value);
  };

  const iconStyle = "h-4 m-1 cursor-pointer text-zinc-800";

  const borderStyles = ["solid", "dashed", "dotted"];

  const applyBorderStyle = (style: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              border_style: style,
            },
          };
        }

        return node;
      })
    );
  };

  const onChangeBorderColor = (color: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              border_color: color,
            },
          };
        }

        return node;
      })
    );
  };

  return (
    <div
      className="relative px-4 py-2 h-[100%] bg-transparent border-2 rounded-md"
      style={{ borderColor: border_color, borderStyle: border_style }}
    >
      <div className="text-center">
        <textarea
          ref={textareaRef}
          autoFocus
          value={nodeText || data?.label}
          onChange={onChangeNodeText}
          onKeyDown={handleKeyDown}
          className="p-2 bg-transparent border-0 text-zinc-900 text-xl font-semibold text-center resize-none h-full w-full"
          style={{ boxShadow: "none", height: "100%_!important" }}
          draggable={false}
        />
      </div>

      <Handle
        type="target"
        className="z-50"
        position={Position.Top}
        id="top-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Bottom}
        id="bottom-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Left}
        id="left-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Right}
        id="right-target"
      />

      <Handle
        type="source"
        className="z-50"
        position={Position.Top}
        id="top-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Bottom}
        id="bottom-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Left}
        id="left-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Right}
        id="right-source"
      />

      <NodeResizer
        lineStyle={lineStyle}
        minHeight={minHeight}
        minWidth={minWidth}
        isVisible={true}
        handleStyle={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          border: 0,
        }}
      />

      <NodeToolbar
        style={{
          borderRadius: 7,
          boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
        }}
        className="nodrag absolute py-[3.2px] top-0 left-0 bg-white border border-zinc-300 px-2 flex items-center justify-center"
      >
        <Popover
          buttonContent={
            <PaintBrushIcon
              className={`${iconStyle} hover:text-green-600 h-[18px]`}
            />
          }
          popoverContent={
            <div className="p-4">
              <p className="text-xs font-semibold text-zinc-500">
                Border Style:
              </p>
              <ul className="flex flex-row gap-2 my-3">
                {borderStyles.map((style, index) => (
                  <li key={index} onClick={() => applyBorderStyle(style)}>
                    <div
                      className={`border-2 border-zinc-800 w-[50px] h-[25px] p-2 cursor-pointer transition-all hover:scale-110 ${style === border_style ? "scale-110 shadow-lg" : ""}`}
                      style={{ borderStyle: style }}
                    ></div>
                  </li>
                ))}
              </ul>
              <p className="text-xs font-semibold text-zinc-500 mb-2">
                Border Color:
              </p>
              <div className="flex flex-wrap">
                <ColorToolbar
                  onColorChange={onChangeBorderColor}
                  activeColor={border_color}
                />
              </div>
            </div>
          }
          classes="w-[400px] bottom-[29px] left-[-170px]"
        />

        <TrashIcon
          data-tooltip-id="delete-node-tooltip"
          onClick={onDelete}
          className={`${iconStyle} hover:text-red-600 h-[18px]`}
        />
        {hasParent && (
          <ArrowTopRightOnSquareIcon
            data-tooltip-id="detach-node-tooltip"
            onClick={onDetach}
            className={`${iconStyle} hover:text-blue-600 h-[18px]`}
          />
        )}
      </NodeToolbar>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="detach-node-tooltip" place="top">
        Detach
      </Tooltip>
    </div>
  );
}

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default memo(TextNode);
