import React, { useEffect, useState } from "react";
import CustomButton from "src/components/customButton";
import InputField from "../../../../components/formFields/inputField";

const criteriaNumbers = [
  { label: "Normal", value: "1" },
  { label: "Important", value: "2" },
  { label: "Very Important", value: "3" },
];

interface CriteriaFormProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
  editData: any;
}

export default function CriteriaForm({
  onCloseModal,
  loading,
  onSubmitData,
  editData,
}: CriteriaFormProps) {
  const [name, setName] = useState("");
  const [value, setValue] = useState<number>(1);
  const [isName, setIsName] = useState(false);

  useEffect(() => {
    if (editData) {
      if (editData?.title) setName(editData?.title);
      if (editData?.criteria_multiplier) setValue(editData?.criteria_multiplier);
    }
  }, [editData]);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    const payload = {
      title: name,
      criteria_multiplier: value,
      criteria_multiplier_title: criteriaNumbers[value-1].label,
    };

    if (name) {
      onSubmitData(payload);
    } else {
      setIsName(true);
    }
  };

  const onChangeName = (value: string) => {
    setName(value);
    setIsName(false);
  };

  const onChangeValue = (value: string) => {
    const data = parseInt(value);
    setValue(data);
  };

  return (
    <form className="flex h-full flex-col" onSubmit={onSubmitForm}>
      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
        <div>
          <label
            htmlFor="title"
            className="block text-xs font-medium leading-6 text-customDarkBlue"
          >
            Name
          </label>
        </div>
        <div className="sm:col-span-2">
          <InputField
            type="text"
            id="title"
            error={isName}
            name="title"
            value={name}
            onChange={(e) => onChangeName(e.target.value)}
          />
        </div>
      </div>

      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mt-4 items-center">
        <div>
          <label
            htmlFor="number-value"
            className="block text-xs font-medium leading-6 text-customDarkBlue"
          >
            Value
          </label>
        </div>
        <div className="sm:col-span-2">
          <div className="flex items-center justify-between">
            {criteriaNumbers?.map((data) => {
              return (
                <div className="flex items-center gap-x-2">
                  <input
                    type="radio"
                    name={`criteria_multiplier`}
                    value={data.value}
                    checked={`${value}` === `${data.value}`}
                    id={`${data.value}_criteria_multiplier`}
                    onChange={(e) => onChangeValue(e.target.value)}
                    className="h-4 w-4 border-gray-300 text-amber-500 focus:ring-blue-600"
                  />
                  <label
                    htmlFor={`${data.value}_criteria_multiplier`}
                    className="block text-xs text-customDarkBlue"
                  >
                    {data.value}-{data.label}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <div className="w-full text-right mt-5">
        <CustomButton
          type="button"
          onClickBtn={onCloseModal}
          text="Cancel"
          buttonType="secondary"
          btnStyle="mr-2"
        />

        <CustomButton
          text="Save"
          type="submit"
          onClickBtn={() => console.log()}
          loading={loading}
          buttonType="primary"
        />
      </div>
    </form>
  );
}
