import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import Command from '@ckeditor/ckeditor5-core/src/command';

class BaseCommand extends Command {
  protected scrollToEnd(editor: any) {
    const editorElement = editor.ui.getEditableElement();

    if (editorElement) {
      setTimeout(() => {
        editorElement.scrollTop = editorElement.scrollHeight;
      }, 0);
    }
  }

  protected scrollToTop(editor: any) {
    const editorElement = editor.ui.getEditableElement();

    if (editorElement) {
      setTimeout(() => {
        editorElement.scrollTop = 0;
      }, 0);
    }
  }
}

class PasteAtCursorCommand extends BaseCommand {
  override execute({ content }: { content: string }) {
    const { editor } = this;

    editor.model.change((writer) => {
      const {
        model: {
          document: { selection },
        },
      } = editor;
      let insertPosition = selection.getFirstPosition();

      if (!selection.isCollapsed) {
        insertPosition = selection.getLastPosition();
      }

      if (!insertPosition) {
        return;
      }

      const nodeBefore = insertPosition.nodeBefore;

      if (nodeBefore && nodeBefore.is('$text') && !nodeBefore.data.endsWith(' ')) {
        writer.insertText(' ', insertPosition);
        insertPosition = selection.getFirstPosition();
      }

      const viewFragment = editor.data.processor.toView(content);
      const modelFragment = editor.data.toModel(viewFragment);

      if (modelFragment.childCount > 0 && insertPosition) {
        editor.model.insertContent(modelFragment, insertPosition);

        const endPosition = insertPosition.getShiftedBy(modelFragment.childCount);
        writer.setSelection(endPosition);
      }
    });

    editor.editing.view.focus();
  }
}

class PasteAtTheBottomCommand extends BaseCommand {
  override execute({ content }: { content: string }) {
    const { editor } = this;

    editor.model.change((writer) => {
      const root = editor.model.document.getRoot();
      if (!root) return;

      const insertPosition = writer.createPositionAt(root, 'end');
      const viewFragment = editor.data.processor.toView(content);
      const modelFragment = editor.data.toModel(viewFragment);

      if (modelFragment.childCount > 0 && insertPosition) {
        editor.model.insertContent(modelFragment, insertPosition);

        const endPosition = insertPosition.getShiftedBy(modelFragment.childCount);
        writer.setSelection(endPosition);
      }
    });

    editor.editing.view.focus();
    this.scrollToEnd(editor);
  }
}

class PasteAtAfterTheHeadingCommand extends BaseCommand {
  override execute({ content }: { content: string }) {
    const { editor } = this;

    editor.model.change((writer) => {
      const root = editor.model.document.getRoot();
      if (!root) return;

      const firstHeading = root.getChild(0);

      if (!firstHeading || !firstHeading.is('element') || firstHeading.name !== 'title') {
        console.warn('The document does not start with an h1 heading');
        return;
      }

      const insertPosition = writer.createPositionAfter(firstHeading);
      const viewFragment = editor.data.processor.toView(content);
      const modelFragment = editor.data.toModel(viewFragment);

      if (modelFragment.childCount > 0 && insertPosition) {
        editor.model.insertContent(modelFragment, insertPosition);

        const endPosition = insertPosition.getShiftedBy(modelFragment.childCount);
        writer.setSelection(endPosition);
      }
    });

    editor.editing.view.focus();
    this.scrollToTop(editor);
  }
}

export default class PasteSelectionPlugin extends Plugin {
  static get requires() {
    return [PasteAtCursorCommand, PasteAtTheBottomCommand, PasteAtAfterTheHeadingCommand];
  }

  init() {
    const { editor } = this;

    editor.commands.add('pasteAt:cursor', new PasteAtCursorCommand(editor));
    editor.commands.add('pasteAt:theBottom', new PasteAtTheBottomCommand(editor));
    editor.commands.add('pasteAt:afterTheHeading', new PasteAtAfterTheHeadingCommand(editor));
  }
}
