import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import RoundButton from "../../../../components/roundButton";
import ConfirmationModal from "../../../../components/confirmationModal";
import SwotBox from "./swotBox";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  getSwotList,
  createSwot,
  updateSwot,
  deleteSwot,
  getSwotById,
} from "../../../../redux/swot/swotApi";
import WrapperLoader from "../../../../components/wrapperLoader";
import { getLastIdFromUrl, getQueryParams } from "../../../../utils/utilities";
import { setSelectedSwot } from "../../../../redux/swot/swotSlice";
import SwotForm from "../../../swot/CreationForm";
import CustomDrawer from "../../../../components/customDrawer";
import { swotAnalysisIcon } from "../../../../assets/icons";

interface TChartListProps {
  checkingDetailTabShow: Function;
}

const SwotList = ({ checkingDetailTabShow }: TChartListProps) => {
  const [open, setOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [allSwotLoading, setAllSwotLoading] = useState(false);
  const [swotItems, setSwotItems] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState<any>(null);
  const [formLoader, setFormLoader] = useState(false);
  const [swotByIdLoader, setSwotByIdLoader] = useState(false);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { swotsList, selectedSwot, swotByIdRes } =
    useAppSelector((state) => state.swot);

  useEffect(() => {
    const swotDataList: any = [...(swotsList || [])];
    setSwotItems(swotDataList || []);

    if (swotsList?.length > 0) {
      const queryStringObj = getQueryParams(window.location.href);

      const findItem = queryStringObj
        ? swotsList?.find((data) => data?.id === queryStringObj?.id)
        : null;

      dispatch(setSelectedSwot(findItem || swotsList[0]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swotsList]);

  useEffect(() => {
    checkingDetailTabShow();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swotItems]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setAllSwotLoading(true);
          await dispatch(
            getSwotList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setAllSwotLoading(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if (queryStringObj?.active === "swot") {
      navigate(
        `${window.location.pathname}?active=swot&id=${selectedSwot?.id || queryStringObj?.id}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSwot]);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if (
      swotItems?.length > 0 &&
      queryStringObj?.active === "swot"
    ) {
      const findSwot = swotItems.find(
        (data: any) => data?.id === queryStringObj.id
      );

      dispatch(setSelectedSwot(findSwot || swotItems[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swotItems]);

  const gettingSwotById = (id: string) => {
    const fetchData = async () => {
      setSwotByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getSwotById({
              accessToken,
              id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        setSwotByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setSwotByIdLoader(false);
      }
    };

    fetchData();
  };

  const onOpenModal = () => {
    setOpen(true);
    setIsEditMode(false);
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  const onOpenEditModal = (id: string) => {
    setIsEditMode(true);
    setOpen(true);
    gettingSwotById(id);
  };

  const deletingSwot = () => {
    const fetchData = async () => {
      setDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteSwot({
              accessToken,
              id: selectedData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setDeleteLoader(false);
          closeConfirmationModal();

          await dispatch(
            getSwotList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          )
            .then((res) => {
              if (res?.payload?.data?.length > 0) {
                dispatch(setSelectedSwot(res?.payload?.data[0]));
              }
            })
            .catch((err) => console.log("Props Cons", err));
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setDeleteLoader(false);
      }
    };

    fetchData();
  };

  const updatingSwot = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateSwot({
              body,
              accessToken,
              id: swotByIdRes?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setFormLoader(false);

          await dispatch(
            getSwotList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingSwot = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createSwot({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setFormLoader(false);

          await dispatch(
            getSwotList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitSwot = async (body: any) => {
    if (isEditMode) {
      updatingSwot(body);
    } else {
      creatingSwot(body);
    }
  };

  const deleteConfirmationModal = (data: any) => {
    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  const onSelectedSwot = async (data: any) => {
    dispatch(setSelectedSwot(data));
  };

  return (
    <>
      <WrapperLoader loading={allSwotLoading}>
        <div className="p-0">
          <div className="mb-3">
            <h3 className="h-toolbar text-md mb-2 border-b border-zinc-100 flex items-center">
              SWOT
            </h3>
            <div
              onClick={() => onOpenModal()}
              className="w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-blue-400"
            >
              <RoundButton onClickBtn={() => console.log("clicking...")} />
            </div>
          </div>

          <div
            className="overflow-y-hidden hover:overflow-y-auto transition-all"
            style={{ height: "calc(100vh - 169px)" }}
          >
            <ul className="mt-3 grid grid-cols-1">
              {swotItems.map((item: any, index: number) => (
                <div key={index}>
                  <SwotBox
                    totalLength={swotItems?.length}
                    key={index}
                    data={item}
                    onEdit={() => onOpenEditModal(item?.id)}
                    onDelete={() => deleteConfirmationModal(item)}
                    deleteLoader={false}
                    selectedData={selectedData}
                    onSelectSwot={() => onSelectedSwot(item)}
                    selectedSwot={selectedSwot}
                  />
                </div>
              ))}
            </ul>
          </div>
        </div>
      </WrapperLoader>

      <CustomDrawer
        open={open}
        onCloseModal={onCloseModal}
        title={
          <div className="flex items-center">
            <span className="mr-2">{swotAnalysisIcon}</span>
            <span>SWOT Analysis</span>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer"
              className="text-xs text-yellow-500 hover:text-yellow-800 flex items-center font-semibold ml-3"
            >
              <span className="mr-1">Learn More</span>
              <ArrowTopRightOnSquareIcon className="h-3" />
            </a>
          </div>
        }
        description={
          isEditMode
            ? "Update SWOT"
            : "Create new SWOT, provide title and options that you will try to analyze and compare. Options are mandatory fields."
        }
      >
        <WrapperLoader loading={swotByIdLoader}>
          <SwotForm
            onCloseModal={onCloseModal}
            editData={swotByIdRes}
            onSubmitForm={onSubmitSwot}
            loader={formLoader}
            editMode={isEditMode}
          />
        </WrapperLoader>
      </CustomDrawer>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingSwot}
        deleteLoader={deleteLoader}
        title="SWOT Delete Confirmation"
        description={
          <div>
            Are you sure you want to delete: <b>{selectedData?.title}</b>
          </div>
        }
      />
    </>
  );
};

export default SwotList;
