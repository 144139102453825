import React, { useState, useEffect, useContext } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import InputField from '../../../components/formFields/inputField';
import CustomButton from '../../../components/customButton';
import TextAreaField from '../../../components/formFields/textareaField';
import SelectField from '../../../components/formFields/selectField';
import { categoriesList, classNames, getCategoryTitle, getCategoryValue } from '../../../utils/utilities';
import { DRAWER_WIDTH } from 'src/config';
import KeyboardShortcut from 'src/components/KeyboardShortcut';
import RangeInput from 'src/components/rangeInput';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useId } from 'react';
import { Tooltip } from 'react-tooltip';
import { Category } from 'src/type';
import { DrawerContext } from 'src/contexts/drawer';

interface CreationFormProps {
  onCloseModal: () => void;
  editData: any;
  onSubmitForm: Function;
  loader: boolean;
  editMode: boolean;
  drawerWidth?: number;
  isPublishMode?: boolean;
}

function TooltipInfo({ lines }: Readonly<{ lines: string[] }>) {
  const id = useId();

  return (
    <>
      <InformationCircleIcon
        data-tooltip-id={id}
        className='w-5 text-zinc-400 hover:text-customDarkBlue'
      />
      <Tooltip
        id={id}
        place='left'
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {lines.map((line, i) => (
            <span key={i}>{line}</span>
          ))}
        </div>
      </Tooltip>
    </>
  );
}

export default function CreationForm({
  onCloseModal,
  editData,
  onSubmitForm,
  loader,
  editMode,
  drawerWidth = DRAWER_WIDTH,
  isPublishMode,
}: CreationFormProps) {
  const formRef = React.useRef<HTMLFormElement>(null);

  const [temperatureValue, setTemperatureValue] = useState('0.5');
  const [frequencyValue, setFrequencyValue] = useState('0.5');
  const [presenceValue, setPresenceValue] = useState('0.5');
  const [topPValue, setTopPValue] = useState('0.5');
  const [maxTokensValue, setMaxTokensValue] = useState('800');
  const [selectedCategory, setSelectedCategory] = useState('Cloud Architecture');

  const { insetBlock } = useContext(DrawerContext);

  const validationObject = {
    title: yup.string().required('Title is required'),
    details: yup.string().required('Details is required'),
    prompt: yup.string().required('Prompt is required'),
  };

  const validationSchema = yup.object().shape(validationObject);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: editMode ? editData?.title : '',
      details: editMode ? editData?.description : '',
      prompt: editMode ? editData?.configuration?.system_config : '',
    },
    validationSchema,
    onSubmit: (values) => {
      const payloadObj = {
        title: values?.title,
        description: values?.details,
        category: selectedCategory,
        ai_vendor: 'Azure',
        ai_type: 'Open AI GPT-4',
        configuration: {
          ai_temperature: parseFloat(temperatureValue),
          prompt_role: 'system',
          system_config: values?.prompt,
          top_p: parseFloat(topPValue),
          frequency_penalty: parseFloat(frequencyValue),
          presence_penalty: parseFloat(presenceValue),
          max_tokens: parseFloat(maxTokensValue),
          stop: 'End',
        },
        privacy: true,
        published_by: 'userTemplate',
      };

      onSubmitForm(payloadObj);
    },
  });

  useEffect(() => {
    if (editData && editMode) {
      setTemperatureValue(editData?.configuration?.ai_temperature);
      setFrequencyValue(editData?.configuration?.frequency_penalty);
      setPresenceValue(editData?.configuration?.presence_penalty);
      setTopPValue(editData?.configuration?.top_p);
      setMaxTokensValue(editData?.configuration?.max_tokens);
      setSelectedCategory(editData?.category || 'Cloud Architecture');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const eachFieldRow =
    'items-start space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 border-b border-gray-200';

  return (
    <form
      ref={formRef}
      className={classNames('flex flex-col bg-white shadow-xl', insetBlock > 0 && 'rounded-b-lg')}
      onSubmit={formik.handleSubmit}
    >
      {/* container */}
      <div
        className='overflow-y-scroll'
        style={{
          height: `calc(100vh - ${173 + 2 * insetBlock}px)`,
        }}
      >
        {/* Title */}
        <div className={eachFieldRow}>
          <div className='flex items-center'>
            <TooltipInfo
              lines={[
                'Make it meaningful so its self explanatory.',
                'This is just a simple tittle for your prompt configuration.',
              ]}
            />
            <label
              htmlFor='title'
              className='block ml-2 text-xs font-medium leading-6 text-customLightBlue'
            >
              Prompt Title
            </label>
          </div>
          <div className='sm:col-span-2'>
            <InputField
              type='text'
              id='title'
              {...formik.getFieldProps('title')}
              placeholder="For example: 'Azure Architect'"
            />

            {formik.touched.title && formik.errors.title ? (
              <div className='text-red-600 text-xs'>{formik.errors.title}</div>
            ) : null}
          </div>
        </div>

        {/* Category */}
        <div className={eachFieldRow}>
          <div className='flex items-center'>
            <TooltipInfo
              lines={['Select a category for this prompt.', 'You can always reach out to us and propose a new one.']}
            />
            <label
              htmlFor='category'
              className='block ml-2 text-xs font-medium leading-6 text-customLightBlue'
            >
              Category
            </label>
          </div>
          <div className='sm:col-span-2'>
            <SelectField
              options={categoriesList}
              value={getCategoryValue(selectedCategory)}
              onSelect={(categoryValue) => setSelectedCategory(getCategoryTitle(categoryValue as Category))}
            />
          </div>
        </div>

        {/* Details */}
        <div className={eachFieldRow}>
          <div className='flex items-center'>
            <TooltipInfo lines={['This is just a brief description', 'about the prompt configuration.']} />
            <label
              htmlFor='description'
              className='block ml-2 text-xs font-medium leading-6 text-customLightBlue'
            >
              Description
            </label>
          </div>
          <div className='sm:col-span-2'>
            <TextAreaField
              id='details'
              {...formik.getFieldProps('details')}
              placeholder='Provide a brief description about this prompt configuration.'
            />

            {formik.touched.details && formik.errors.details ? (
              <div className='text-red-600 text-xs'>{formik.errors.details}</div>
            ) : null}
          </div>
        </div>

        {/* Prompt */}
        <div className={eachFieldRow}>
          <div className='flex items-center'>
            <TooltipInfo
              lines={[
                'Prompt configuration will help the AI deliver the best possible completion.',
                'For example by setting a value in this field like: ',
                "'You always respond in direct and professional tone'",
                'Each time you receive a completion AI will generate it with profesional tone without you having',
                'to mention it in the prompt every time you send it.',
                'For more read this page.',
              ]}
            />
            <label
              htmlFor='description'
              className='block ml-2 text-xs font-medium leading-6 text-customLightBlue'
            >
              Prompt Configuration
            </label>
          </div>
          <div className='sm:col-span-2'>
            <TextAreaField
              id='prompt'
              {...formik.getFieldProps('prompt')}
              placeholder='Insert your prompt configuration. Check the tooltip for more information.'
            />

            {formik.touched.prompt && formik.errors.prompt ? (
              <div className='text-red-600 text-xs'>{formik.errors.prompt}</div>
            ) : null}
          </div>
        </div>

        {/* Temperature */}
        <div className={eachFieldRow}>
          <div className='flex items-center'>
            <TooltipInfo
              lines={[
                'A lower temperature results in more predictable and conservative outputs,',
                'while a higher temperature produces more varied and',
                'sometimes creative or unexpected responses.',
              ]}
            />
            <label
              htmlFor='title'
              className='block ml-2 text-xs font-medium leading-6 text-customLightBlue'
            >
              Temperature
            </label>
          </div>
          <RangeInput
            className='sm:col-span-2'
            min={0.1}
            max={1}
            step={0.1}
            value={temperatureValue}
            onValueChange={(value) => setTemperatureValue(value)}
          />
        </div>

        {/* Frequency */}
        <div className={eachFieldRow}>
          <div className='flex items-center'>
            <TooltipInfo
              lines={[
                'The "frequency penalty" in AI settings reduces repetition,',
                'encouraging diverse and natural language in responses.',
              ]}
            />
            <label
              htmlFor='title'
              className='block ml-2 text-xs font-medium leading-6 text-customLightBlue'
            >
              Frequency
            </label>
          </div>
          <RangeInput
            className='sm:col-span-2'
            min={0.1}
            max={1}
            step={0.1}
            value={frequencyValue}
            onValueChange={(value) => setFrequencyValue(value)}
          />
        </div>

        {/* Presence */}
        <div className={eachFieldRow}>
          <div className='flex items-center'>
            <TooltipInfo
              lines={[
                'Reduce the chance of repeating any token that has appeared in',
                'the text at all so far. This increases the likelihood of ',
                'introducing new topics in a response.',
              ]}
            />
            <label
              htmlFor='title'
              className='block ml-2 text-xs font-medium leading-6 text-customLightBlue'
            >
              Presence
            </label>
          </div>
          <RangeInput
            className='sm:col-span-2'
            min={0.1}
            max={1}
            step={0.1}
            value={presenceValue}
            onValueChange={(value) => setPresenceValue(value)}
          />
        </div>

        {/* Top P */}
        <div className={eachFieldRow}>
          <div className='flex items-center'>
            <TooltipInfo
              lines={[
                'Similar to temperature, this controls randomness but uses a different method. ',
                "Lowering Top P will narrow the model's token selection to likelier tokens. ",
                'Increasing Top P will let the model choose from tokens with both',
                'high and low likelihood. Try adjusting temperature or Top P but not both.',
              ]}
            />
            <label
              htmlFor='title'
              className='block ml-2 text-xs font-medium leading-6 text-customLightBlue'
            >
              TopP
            </label>
          </div>
          <RangeInput
            className='sm:col-span-2'
            min={0.1}
            max={1}
            step={0.1}
            value={topPValue}
            onValueChange={(value) => setTopPValue(value)}
          />
        </div>

        {/* Max Tokens */}
        <div className={eachFieldRow}>
          <div className='flex items-center'>
            <TooltipInfo
              lines={[
                'Set a limit on the number of tokens per model response. The API supports a maximum ',
                'of MaxTokensPlaceholderDoNotTranslate tokens shared between the prompt (including system message,',
                "examples, message history, and user query) and the model's response. One token is roughly 4 characters",
                'for typical English text.',
              ]}
            />
            <label
              htmlFor='title'
              className='block ml-2 text-xs font-medium leading-6 text-customLightBlue'
            >
              Tokens
            </label>
          </div>
          <div className='sm:col-span-2'>
            <div className='text-xs text-center font-semibold text-customDarkBlue'>
              <span>{maxTokensValue}</span>
            </div>

            <input
              type='range'
              min='800'
              max='4000'
              value={maxTokensValue}
              // step="800"
              className='w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700'
              onChange={(e: any) => setMaxTokensValue(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <div
        className={classNames(
          'bg-white border-t border-gray-200 px-4 py-5 sm:px-6',
          insetBlock > 0 ? 'rounded-b-lg' : 'fixed bottom-0'
        )}
        style={{ width: `${drawerWidth - 40}px` }}
      >
        <div className='flex justify-end space-x-3'>
          <button
            type='button'
            className='rounded-md bg-white px-3 py-2 text-xs font-semibold text-customLightBlue shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
            onClick={onCloseModal}
          >
            Cancel
          </button>
          <CustomButton
            type='submit'
            onClickBtn={() => console.log('Clicking...')}
            text={isPublishMode ? 'Publish' : 'Save'}
            beforeIcon={
              <KeyboardShortcut.ControlEnter
                className='mr-2'
                onTrigger={formik.handleSubmit}
              />
            }
            loading={loader}
            buttonType='primary'
          />
        </div>
      </div>
    </form>
  );
}
