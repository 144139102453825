import React from "react";
import Joyride, { CallBackProps, Step } from "react-joyride";

interface AppTourProps {
  steps: Step[];
  callback?: (data: CallBackProps) => void;
  continuous?: boolean;
  showSkipButton?: boolean;
  styles?: object;
}

const AppTour: React.FC<AppTourProps> = ({
  steps,
  callback,
  continuous = true,
  showSkipButton = true,
  styles = {},
}) => {
  return (
    <Joyride
      steps={steps}
      callback={callback}
      continuous={continuous}
      showSkipButton={showSkipButton}
      styles={{
        options: {
          zIndex: 1000,
          primaryColor: "#007aff",
          textColor: "#333",
          backgroundColor: "#fff",
          ...styles,
        },
      }}
    />
  );
};

export default AppTour;
