import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getPublicCommentsList,
  createPublicComment,
} from "../../redux/comments/commentsApi";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getLastIdFromUrl } from "../../utils/utilities";
import WrapperLoader from "../wrapperLoader";
import CustomButton from "../customButton";
import CommentBox from "./commentBox";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function Comments() {
  const [commentsListLoader, setCommentsListLoader] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [formLoader, setFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { getPublicCommentsListRes } = useAppSelector(
    (state) => state.comments
  );

  useEffect(() => {
    gettingCommentsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gettingCommentsList = () => {
    const fetchData = async () => {
      setCommentsListLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            getPublicCommentsList({
              accessToken,
              template_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          setCommentsListLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setCommentsListLoader(false);
      }
    };

    fetchData();
  }

  const onTypingComment = (e: any) => {
    const value = e.target.value;
    setCommentText(value);

    if (e.key === "Enter" && !e.shiftKey && value) {
      onSendComment();
    }
  };

  const onSendComment = () => {
    if (commentText) {
      const fetchData = async () => {
        setFormLoader(true);
        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken) {
            const payload = {
              comment: commentText,
            };

            await dispatch(
              createPublicComment({
                body: payload,
                accessToken,
                template_id: getLastIdFromUrl(window.location.pathname),
              })
            )
              .then((res) => {
                if (res) {
                  setFormLoader(false);
                  setCommentText("");
                  gettingCommentsList();
                }
              })
              .catch((err) => {
                setFormLoader(false);
              });
          }
        } catch (error) {
          console.error("Error getting access token:", error);
          setFormLoader(false);
        }
      };

      fetchData();
    }
  };

  return (
    <WrapperLoader loading={commentsListLoader}>
      {/* New comment form */}
      <div className="mt-6 flex gap-x-3 mb-6">
        <img
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
          className="h-6 w-6 flex-none rounded-full bg-gray-50"
        />
        <form action="#" className="relative flex-auto">
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={2}
              name="comment"
              id="comment"
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-customDarkBlue placeholder:text-gray-400 focus:ring-0 sm:text-xs sm:leading-6"
              placeholder="Add your comment..."
              onChange={onTypingComment}
              value={commentText}
              onKeyDown={onTypingComment}
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5" />
            <CustomButton
              text="Comment"
              type="button"
              onClickBtn={onSendComment}
              loading={formLoader}
              buttonType='primary'
            />
          </div>
        </form>
      </div>

      <ul className="space-y-6">
        {getPublicCommentsListRes?.length > 0 &&
          getPublicCommentsListRes.map((item: any, index: number) => (
            <li key={item.id} className="relative flex gap-x-4">
              <div
                className={classNames(
                  index === getPublicCommentsListRes.length - 1
                    ? "h-6"
                    : "-bottom-6",
                  "absolute left-0 top-0 flex w-6 justify-center"
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>
              <CommentBox item={item} />
            </li>
          ))}
      </ul>

    </WrapperLoader>
  );
}
