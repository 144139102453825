import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Analysis from "./Analysis";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getProsConsById } from "../../redux/prosCons/prosConsApi";
import { getLastIdFromUrl } from "../../utils/utilities";
import WrapperLoader from "../../components/wrapperLoader";
import { ProsConsToolbar } from "./Toobar";

export default function ProsConsDetails() {
  const [prosConsByIdLoader, setProsConsByIdLoader] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { prosConsByIdRes, selectedProsCons } = useAppSelector(
    (state) => state.prosCons
  );

  useEffect(() => {
    if (selectedProsCons?.id) {
      const fetchData = async () => {
        setProsConsByIdLoader(true);

        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken)
            await dispatch(
              getProsConsById({
                accessToken,
                id: selectedProsCons?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );
          setProsConsByIdLoader(false);
        } catch (error) {
          console.error("Error getting access token:", error);
          setProsConsByIdLoader(false);
        }
      };

      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProsCons]);

  return (
    <WrapperLoader loading={prosConsByIdLoader}>
      <ProsConsToolbar />
      <div className="p-6 pb-0">
        <div>
          <Analysis detailsData={prosConsByIdRes} />
        </div>
      </div>
    </WrapperLoader>
  );
}
