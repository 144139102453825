import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Analysis from "./Analysis";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getSwotById } from "../../redux/swot/swotApi";
import { getLastIdFromUrl } from "../../utils/utilities";
import WrapperLoader from "../../components/wrapperLoader";
import { SwotToolbar } from "./Toolbar";

export default function SWOTDetails() {
  const [swotByIdLoader, setSwotByIdLoader] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { swotByIdRes, selectedSwot } = useAppSelector((state) => state.swot);

  useEffect(() => {
    if (selectedSwot?.id) {
      const fetchData = async () => {
        setSwotByIdLoader(true);
        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken)
            await dispatch(
              getSwotById({
                accessToken,
                id: selectedSwot?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );
          setSwotByIdLoader(false);
        } catch (error) {
          console.error("Error getting access token:", error);
          setSwotByIdLoader(false);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSwot]);

  return (
    <WrapperLoader loading={swotByIdLoader}>
      <SwotToolbar />
      <div className="p-6">
        <div>
          <Analysis detailsData={swotByIdRes} />
        </div>
      </div>
    </WrapperLoader>
  );
}
