import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Analysis from "./Analysis";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getDecisionMatrixById } from "../../redux/decisionMatrix/decisionMatrixApi";
import { getLastIdFromUrl } from "../../utils/utilities";
import WrapperLoader from "../../components/wrapperLoader";
import { DecisionMatrixToolbar } from "./Toolbar";

export default function DecisionMatrixDetails() {
  const [decisionMatrixByIdLoader, setDecisionMatrixByIdLoader] =
    useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { decisionMatrixByIdRes, selectedDecisionMatrix } = useAppSelector(
    (state) => state.decisionMatrixs
  );

  useEffect(() => {
    if (selectedDecisionMatrix?.id) {
      const fetchData = async () => {
        setDecisionMatrixByIdLoader(true);
        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken)
            await dispatch(
              getDecisionMatrixById({
                accessToken,
                id: selectedDecisionMatrix?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );
          setDecisionMatrixByIdLoader(false);
        } catch (error) {
          console.error("Error getting access token:", error);
          setDecisionMatrixByIdLoader(false);
        }
      };

      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDecisionMatrix]);

  return (
    <WrapperLoader loading={decisionMatrixByIdLoader}>
      <DecisionMatrixToolbar />
      <div className="p-6">
        <div>
          <Analysis detailsData={decisionMatrixByIdRes} />
        </div>
      </div>
    </WrapperLoader>
  );
}
