import React, { useCallback, memo } from "react";
import {
  NodeResizer,
  type NodeProps,
  useStore,
  Handle,
  Position,
  useKeyPress,
  useReactFlow,
  NodeToolbar,
} from "@xyflow/react";
import { Tooltip } from "react-tooltip";
import Shape from "./shape";
import ColorToolbar from "./colorToolbar";
import { type ShapeNode } from "./shape/types";
import NodeLabel from "./label";
import Popover from "../../../../components/popover";
import { PaintBrushIcon, TrashIcon } from "@heroicons/react/24/outline";

// this will return the current dimensions of the node (measured internally by react flow)
function useNodeDimensions(id: string) {
  const node = useStore((state) => state.nodeLookup.get(id));
  return {
    width: node?.measured?.width || 0,
    height: node?.measured?.height || 0,
  };
}

function CustomShapeNode({ id, selected, data }: NodeProps<ShapeNode>) {
  const { color, type } = data;
  const { setNodes, deleteElements } = useReactFlow();

  const { width, height } = useNodeDimensions(id);
  const shiftKeyPressed = useKeyPress("Shift");

  const onDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
  }, [deleteElements, id]);

  const onColorChange = useCallback(
    (color: string) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === id) {
            return {
              ...node,
              data: {
                ...node.data,
                color,
              },
            };
          }
          return node;
        })
      );
    },
    [setNodes, id]
  );

  const handleNodeMouseEnter = useCallback(() => {
    // Clear selection of all nodes except the one being dragged
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        selected: false,
      }))
    );
  }, [setNodes]);

  const iconStyle = "h-4 m-1 cursor-pointer text-zinc-800";

  return (
    <>
      <NodeResizer
        color={color}
        keepAspectRatio={shiftKeyPressed}
        isVisible={selected}
      />
      <Shape
        type={type}
        width={width}
        height={height}
        fill={color}
        strokeWidth={4}
        stroke={"white"}
        fillOpacity={1}
        onMouseEnter={handleNodeMouseEnter}
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Top}
        id="top-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Bottom}
        id="bottom-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Left}
        id="left-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Right}
        id="right-target"
      />

      <Handle
        type="source"
        className="z-50"
        position={Position.Top}
        id="top-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Bottom}
        id="bottom-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Left}
        id="left-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Right}
        id="right-source"
      />

      <NodeToolbar
        style={{
          borderRadius: 7,
          boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
        }}
        className="nodrag absolute py-[3.2px] top-0 left-0 bg-white border border-zinc-300 px-2 flex items-center justify-center"
      >
        <Popover
          buttonContent={
            <PaintBrushIcon
              className={`${iconStyle} hover:text-green-600 h-[18px]`}
            />
          }
          popoverContent={
            <div className="flex flex-wrap">
              <ColorToolbar onColorChange={onColorChange} activeColor={color} />
            </div>
          }
          classes="w-[280px] bottom-[29px] left-[-100px]"
        />
        <TrashIcon
          data-tooltip-id="delete-node-tooltip"
          onClick={onDelete}
          className={`${iconStyle} hover:text-red-600 h-[18px]`}
        />
      </NodeToolbar>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="edit-node-tooltip" place="top">
        Edit Setting
      </Tooltip>

      <Tooltip className="z-[50]" id="detach-node-tooltip" place="top">
        Detach
      </Tooltip>
      <NodeLabel placeholder={data.type} />
    </>
  );
}

export default memo(CustomShapeNode);
