import { noop } from 'lodash';
import { useState } from 'react';
import type { Option } from 'src/components/dropdownSelectionOption';
import { ActionProps } from 'src/components/itemWithDropdown';
import Toolbar from 'src/components/toolbar';

export function SwotToolbar() {
  const [aiAssistantEnabled, setAiAssistantEnabled] = useState(false);
  const [selectedAiChat, setSelectedAiChat] = useState<string>()
  const [selectedDocs, setSelectedDocs] = useState<Option[]>([]);

  const validateList: ActionProps[] = [
    {
      id: 1,
      name: '/Write a summary',
      onActionClick: noop,
    },
  ];

  return (
    <Toolbar.Container>
      <Toolbar>
        <Toolbar.Left>
          <Toolbar.Switch
            enabled={aiAssistantEnabled}
            setEnabled={() => setAiAssistantEnabled(!aiAssistantEnabled)}
          >
            Assistant
          </Toolbar.Switch>
        </Toolbar.Left>
        <Toolbar.Right
          visibleItems={[
            new Toolbar.ChatSelectorItem({ id: 1, selected: selectedAiChat, setSelected: setSelectedAiChat }),
            new Toolbar.DocumentsSelectorItem({
              id: 2,
              multiple: true,
              selected: selectedDocs,
              setSelected: setSelectedDocs,
              dropdownPosition: 'left'
            }),
            new Toolbar.ValidateItem({ id: 3, actions: validateList }),
          ]}
          hiddenItems={[]}
        />
      </Toolbar>
    </Toolbar.Container>
  );
}