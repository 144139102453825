import { SparklesIcon } from '@heroicons/react/24/outline';
import { noop } from 'lodash';
import { memo, useState } from 'react';
import type { Option } from 'src/components/dropdownSelectionOption';
import { ActionProps } from 'src/components/itemWithDropdown';
import Toolbar from 'src/components/toolbar';

interface TChartBarProps {
  isTChartReady: boolean;
  generateArgumentsSuggestions: () => Promise<void>;
}

function TChartBar({ isTChartReady, generateArgumentsSuggestions }: TChartBarProps) {
  const [argumentsSuggestionsLoader, setArgumentsSuggestionsLoader] = useState(false);
  const [aiAssistantEnabled, setAiAssistantEnabled] = useState(false);
  const [selectedAiChat, setSelectedAiChat] = useState<string>();
  const [selectedDocs, setSelectedDocs] = useState<Option[]>([]);

  const validateList: ActionProps[] = [
    {
      id: 1,
      name: '/Write a summary',
      onActionClick: noop,
    },
  ];

  const handleGenerateArgumentsSuggestions = async () => {
    setArgumentsSuggestionsLoader(true);

    try {
      await generateArgumentsSuggestions();
    } finally {
      setArgumentsSuggestionsLoader(false);
    }
  };

  return (
    <Toolbar.Container>
      <Toolbar>
        {/* <Toolbar.Left>
          <Toolbar.Switch
            enabled={aiAssistantEnabled}
            setEnabled={() => setAiAssistantEnabled(!aiAssistantEnabled)}
          >
            Assistant
          </Toolbar.Switch>
        </Toolbar.Left> */}
        <Toolbar.Right
          visibleItems={[
            // new Toolbar.ChatSelectorItem({ id: 1, selected: selectedAiChat, setSelected: setSelectedAiChat }),
            // new Toolbar.DocumentsSelectorItem({
            //   id: 2,
            //   multiple: true,
            //   selected: selectedDocs,
            //   setSelected: setSelectedDocs,
            //   dropdownPosition: 'left',
            // }),
            new Toolbar.ValidateItem({ id: 3, actions: validateList }),
            new Toolbar.ButtonItem({
              id: 4,
              iconComponent: SparklesIcon,
              name: 'Generate with AI',
              width: 150,
              loading: argumentsSuggestionsLoader,
              disabled: !isTChartReady,
              onActionClick: handleGenerateArgumentsSuggestions,
            }),
          ]}
          hiddenItems={[]}
        />
      </Toolbar>
    </Toolbar.Container>
  );
}

export default memo(TChartBar);

TChartBar.displayName = 'TChartBar';
