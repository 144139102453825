import './texteditor.css';

import { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';

import AiSuggestionsPopover from 'src/components/documentPopover/aiSuggestions';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import LoaderPage from '../../../components/loaderPage';
import { getDocumentFavoriteComponents } from '../../../redux/documentComponents/documentComponentsApi';
import { getRawIconObjectByName } from '../../../utils/documentComponentsIcons';
import { useAiSuggestionsFetcher } from './useAiSuggestions';
import { useAuth0 } from '@auth0/auth0-react';
import { DetailsContext } from 'src/contexts/details/context';
import { SelectionPopover, useSelection } from 'src/components/documentPopover/selection';

const MyReactEditor = ({ setValue, value, ckeRef }) => {
  const [documentComponentsList, setDocumentComponentsList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { collapseSidebar } = useAppSelector((state) => state.profile);
  const { getDocumentFavoriteComponentsRes } = useAppSelector((state) => state.documentComponents);

  const fetchSuggestions = useAiSuggestionsFetcher();
  const [suggestionsAPI, setSuggestionsAPI] = useState(null);

  const { selectedDocument, setEditorRef } = useContext(DetailsContext);
  const aiSuggestionsEnabled = !!selectedDocument?.ai_suggestions;

  const [selection, setSelection] = useState(null);
  const [isSelectionPending, setIsSelectionPending] = useState(false);
  const { setContainerRef, containerRef } = useSelection(({ selection, isPending }) => {
    setIsSelectionPending(isPending);

    if (!isPending || !selection) {
      setSelection(selection);
    }
  });

  useEffect(() => {
    if (!suggestionsAPI) return;

    if (aiSuggestionsEnabled && !selection) {
      suggestionsAPI.fetcher = fetchSuggestions;
    }

    return () => {
      suggestionsAPI.fetcher = null;
    };
  }, [suggestionsAPI, aiSuggestionsEnabled, fetchSuggestions, selection]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(getDocumentFavoriteComponents({ accessToken }));
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getDocumentFavoriteComponentsRes?.length > 0) {
      const newList = [];

      getDocumentFavoriteComponentsRes.forEach((data) => {
        const iconObject = getRawIconObjectByName(data?.icon);

        newList.push({
          title: data?.title,
          icon: iconObject?.icon,
          data: () => data?.content,
          description: data?.short_description,
        });
      });

      setDocumentComponentsList(newList);
      setIsLoading(false);
    }

    if (getDocumentFavoriteComponentsRes === null) {
      setIsLoading(false);
    }
  }, [getDocumentFavoriteComponentsRes]);

  useEffect(() => {
    const project = document.getElementById('project-container');
    const toolkit = document.getElementsByClassName('ck-editor__top');
    if (toolkit[0] && project) {
      toolkit[0].style.width = `${project.clientWidth - 2}px`;

      if (collapseSidebar) {
        toolkit[0].style.left = `53px`;
      } else {
        toolkit[0].style.left = `257px`;
      }
    }
  }, [collapseSidebar, value, isLoading]);

  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <div className='relative'>
      {isLoading ? (
        <LoaderPage page='design-detail' />
      ) : (
        <>
          <Editor.ReactEditor
            editor={Editor}
            data={value || ''}
            onReady={(editor) => {
              ckeRef.current = editor;
              setEditorRef(editor);
              setSuggestionsAPI(new Editor.SuggestionsAPI(editor, { debounceTimeMs: 500 }));

              const editableElement = editor.ui.view.editable.element;

              setContainerRef(editableElement);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleChange(data);
            }}
            onBlur={(event, editor) => {
              console.log('Blur.', editor);
            }}
            onFocus={(event, editor) => {
              console.log('Focus.', editor);
            }}
            config={{
              placeholder: 'Type here...',
              template: {
                definitions: documentComponentsList,
              },
              isReadOnly: false,
            }}
          />
          <AiSuggestionsPopover suggestionsApi={suggestionsAPI} />
          {!isSelectionPending && (
            <SelectionPopover
              selection={selection}
              containerRef={containerRef}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MyReactEditor;
