// misc_nodes.js

export const misc_nodes = [
  {
    name: "Communications",
    items: [
      "browser",
      "chat",
      "cloud_computing",
      "computer",
      "connectivity",
      "connector",
      "customer_support",
      "dial_pad",
      "email",
      "fax",
      "flash_drive",
      "head",
      "headphone",
      "internet",
      "landline",
      "laptop",
      "link",
      "live_chat",
      "location",
      "mailbox",
      "megaphone",
      "microphone",
      "modem",
      "morse_code",
      "network",
      "pager",
      "radio_antenna",
      "radio",
      "satellite",
      "server",
      "share",
      "signal_tower",
      "sim_card",
      "smartphone",
      "speaker",
      "speech_bubble",
      "telephone_booth",
      "tin_can",
      "tv",
      "video_call",
      "walkie_talkie",
      "web_page",
      "webcam",
      "wifi"
    ],
  },
  {
    name: "Hardware",
    items: [
      "ram_memory",
      "battery",
      "bluetooth",
      "cable",
      "case",
      "cd",
      "chip",
      "computer_mouse",
      "computer",
      "cooling",
      "cpu",
      "desktop_computer",
      "diode",
      "download",
      "earphone",
      "external_hard_drive",
      "fan",
      "floppy_disk",
      "folder",
      "ftp",
      "gpu",
      "hard_disk_drive",
      "hdmi_port",
      "joystick",
      "lan",
      "laptop",
      "mainboard",
      "modem",
      "monitor",
      "network",
      "operating_system",
      "plug",
      "printer",
      "resistance",
      "router",
      "sd_card",
      "search",
      "server",
      "shield",
      "shutdown",
      "smartphone",
      "sound_system",
      "ssd",
      "turntable",
      "upload",
      "usb_connector",
      "vga",
      "webcam",
      "wireless_keyboard"
    ],
  },
  {
    name: "Security",
    items: [
      "gdpr",
      "account",
      "attention",
      "cctv",
      "cloud_computing",
      "computer",
      "cookies",
      "credit_card",
      "data_encryption",
      "data_protection",
      "data_security",
      "disable",
      "door_handle",
      "download",
      "error_404",
      "eye_scan",
      "find",
      "fingerprint_scanner",
      "firewall",
      "ftp",
      "hard_disk",
      "key",
      "laptop",
      "lock",
      "money",
      "network",
      "padlock",
      "password",
      "pattern",
      "phishing",
      "police_badge",
      "problem",
      "protection",
      "secure_payment",
      "setting",
      "shield",
      "spam",
      "spy",
      "transfer",
      "umbrella",
      "unlock",
      "user",
      "virus",
      "vpn",
      "warning",
      "web_security"
    ],
  }
];
