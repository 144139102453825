import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  PencilIcon,
  TrashIcon,
  ArrowTopRightOnSquareIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getSwotList,
  createSwot,
  updateSwot,
  getSwotById,
  deleteSwot,
} from "../../redux/swot/swotApi";
import CustomDrawer from "../../components/customDrawer";
import CreationForm from "./CreationForm";
import WrapperLoader from "../../components/wrapperLoader";
import ConfirmationModal from "../../components/confirmationModal";
import StatusLabel from "../../components/statusLabel";
import TableHeader from "../../components/tableHead";
import { swotAnalysisIcon } from "../../assets/icons";
import SearchField from "../../components/searchField";
import TableTitle from "../../components/tableTitle";
import Tooltip from "../../components/tooltipWrapper";
import CompleteEmptyPage from "../../components/completeEmptyPage";
import TableColumn from "../../components/tableColumn";
import { getLastIdFromUrl } from "../../utils/utilities";
import CustomButton from '../../components/customButton';

import swotSVG from "../../assets/images/Illustration-06.svg";

const SWOT = () => {
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState<any>(null);
  const [swotFormLoader, setSwotFormLoader] = useState(false);
  const [swotByIdLoader, setSwotByIdLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [swotCompleteList, setSwotCompleteList] = useState<any>([]);
  const [search, setSearch] = useState("");

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { swotsList, swotByIdRes, loadingSwot } = useAppSelector(
    (state) => state.swot
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getSwotList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch]);

  useEffect(() => {
    setSwotCompleteList(swotsList);
  }, [swotsList]);

  const onSearchingList = (value: string) => {
    setSearch(value);
    if (value) {
      const filteredResults = swotsList.filter((item: any) =>
        item?.title.toLowerCase().includes(value.toLowerCase())
      );

      setSwotCompleteList(filteredResults);
    } else {
      setSwotCompleteList(swotsList);
    }
  };
  
  const onOpenModal = () => {
    setOpen(true);
    setIsEditMode(false);
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  const onOpenEditModal = (id: string) => {
    setIsEditMode(true);
    setOpen(true);
    gettingSwotById(id);
  };

  const onOpenCreateModal = () => {
    setOpen(true);
  };

  const updatingSwot = (body: any) => {
    const fetchData = async () => {
      setSwotFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateSwot({
              body,
              accessToken,
              id: swotByIdRes?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setSwotFormLoader(false);

          await dispatch(
            getSwotList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setSwotFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingSwot = (body: any) => {
    const fetchData = async () => {
      setSwotFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createSwot({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setSwotFormLoader(false);

          await dispatch(
            getSwotList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setSwotFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitSwot = async (body: any) => {
    if (isEditMode) {
      updatingSwot(body);
    } else {
      creatingSwot(body);
    }
  };

  const gettingSwotById = (id: string) => {
    const fetchData = async () => {
      setSwotByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getSwotById({
              accessToken,
              id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        setSwotByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setSwotByIdLoader(false);
      }
    };

    fetchData();
  };

  const deletingSwot = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteSwot({
              accessToken,
              id: selectedId,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setConfirmationLoader(false);
          deletingModalClose();

          await dispatch(
            getSwotList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const deletingModalOpen = (id: string) => {
    setDeleteConfirm(true);
    setSelectedId(id);
  };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedId(null);
  };

  return (
    <>
      {swotsList?.length > 0 ? (
        <WrapperLoader loading={loadingSwot}>
          <div className="mb-5 pb-5 pt-5 border-b border-gray-200 text-right">
            <div className="px-6 flex items-center justify-between">
              <div className="w-80">
                <SearchField
                  onSearchField={onSearchingList}
                  searchValue={search}
                />
              </div>

              <CustomButton
                text="New SWOT"
                type="button"
                onClickBtn={() => onOpenModal()}
                beforeIcon={<PlusIcon className="h-4 mr-1" />}
                buttonType="primary"
              />
            </div>
          </div>

          <div className="px-6 pb-6">
            {swotCompleteList?.length > 0 && (
              <div className="flow-root">
                <div className="sm:-mx-6">
                  <div className="inline-block min-w-full align-middle sm:px-6 h-[calc(100vh_-_150px)] overflow-y-auto">
                    <table className="min-w-full divide-y divide-gray-300 bg-white rounded-xl shadow-md border border-zinc-200 overflow-hidden">
                      <thead>
                        <tr>
                          <TableHeader name="Title" />
                          <TableHeader name="Description" />
                          {/* <TableHeader name="Decision ID" /> */}
                          <TableHeader name="Status" />
                          <TableHeader name="Category" />
                          <TableHeader name="Actions" />
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {swotCompleteList?.map((item: any) => (
                          <tr
                            key={item?.id}
                            className="cursor-pointer hover:bg-zinc-100"
                            onClick={() =>
                              navigate(
                                `${window.location.pathname}?active=swot&id=${item?.id}`
                              )
                            }
                          >
                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                <TableTitle
                                  title={item?.title}
                                  status={item?.swot_status}
                                  created_at={item?.created_at}
                                  updated_at={item?.updated_at}
                                />
                              </div>
                            </TableColumn>
                            <TableColumn>
                              <div className="text-customDarkBlue text-xs">
                                <Tooltip
                                  text={item?.swot_description}
                                  position="top"
                                >
                                  <div className="line-clamp-2">
                                    {item?.swot_description}
                                  </div>
                                </Tooltip>
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                <StatusLabel status={item?.swot_status} />
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                {item?.category}
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="relative text-right text-xs font-medium">
                                <div className="flex-shrink-0 pr-4 flex items-center">
                                  <PencilIcon
                                    className="h-5 w-5 mr-1 cursor-pointer text-slate-400 hover:text-blue-900"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onOpenEditModal(item?.id);
                                    }}
                                  />
                                  <TrashIcon
                                    className="h-5 w-5 cursor-pointer text-slate-400 hover:text-red-900"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      deletingModalOpen(item?.id);
                                    }}
                                  />
                                </div>
                              </div>
                            </TableColumn>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </WrapperLoader>
      ) : (
        <div
          className="flex items-center justify-center"
          style={{ height: "calc(100vh - 140px)" }}
        >
          <CompleteEmptyPage
            title="You don't have any SWOT"
            subTitle="Get started by creating a new SWOT"
            btnText="Create SWOT"
            onClickBtn={onOpenCreateModal}
            icon={swotSVG}
          />
        </div>
      )}

      <CustomDrawer
        open={open}
        onCloseModal={onCloseModal}
        title={
          <div className="flex items-center">
            <span className="mr-2">{swotAnalysisIcon}</span>
            <span>SWOT Analysis</span>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer"
              className="text-xs text-yellow-500 hover:text-yellow-800 flex items-center font-semibold ml-3"
            >
              <span className="mr-1">Learn More</span>
              <ArrowTopRightOnSquareIcon className="h-3" />
            </a>
          </div>
        }
        description={
          isEditMode
            ? "Update SWOT"
            : "Create new SWOT, provide title and options that you will try to analyze and compare. Options are mandatory fields."
        }
      >
        <WrapperLoader loading={swotByIdLoader}>
          <CreationForm
            onCloseModal={onCloseModal}
            editData={swotByIdRes}
            onSubmitForm={onSubmitSwot}
            loader={swotFormLoader}
            editMode={isEditMode}
          />
        </WrapperLoader>
      </CustomDrawer>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingSwot}
        deleteLoader={confirmationLoader}
      />
    </>
  );
};

export default SWOT;
