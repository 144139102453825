import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
  ArrowTopRightOnSquareIcon,
  PlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import WrapperLoader from "../../components/wrapperLoader";
import SearchField from "../../components/searchField";

import TemplateBox from "../../components/templateBox";
import AIChatTempDetail from "../../components/aiChatTempDetail";
import { createConversation } from "../../redux/chatGPT/chatGPTApi";
import CustomButton from "../../components/customButton";
import CustomModal from "../../components/customModal";
import ProjectsSelection from "./ProjectsSelectionForm";
import {
  getUserAllAiAssistants,
  createUserAiAssistant,
  updateUserAiAssistant,
  deleteUserAiAssistant,
  // getUserAiAssistant,
  publishUserAiAssistant,
  unPublishUserAiAssistant,
} from "../../redux/userAiAssistants/userAiAssistantsApi";
import { getAllPublishedAiAssistants } from "../../redux/chatGPT/chatGPTApi";
import CompleteEmptyPage from "../../components/completeEmptyPage";
import CustomDrawer from "../../components/customDrawer";
import ConfirmationModal from "../../components/confirmationModal";
import CreationForm from "./CreationForm";
import PublishConfirmation from "../../components/publishConfirmation";
import { getLastIdFromUrl } from "src/utils/utilities";
import { CategoriesLeftSidebar } from "../../components/categoriesLeftSidebar";

import aiSVG from "../../assets/images/Illustration-09.svg";
import chatGPTIcon from "../../assets/icons/chat-gpt-icon.svg";
import metaIcon from "../../assets/icons/meta-icon.svg";
import { useAppSearchParams } from "src/hooks/useAppSearchParams";
import { pipe } from "src/utils/pipe";
import { Category } from "src/type";
import { filter } from "src/utils/filter";
import SelectField from "src/components/formFields/selectField";

interface AiAssistantsDataProps {
  isModal: boolean;
  onUseWithModal?: Function;
  tempType: string;
  selectedCategory?: Category;
}

const vendorFiltersList = [
  { value: "all", title: "Clear", icon: XCircleIcon },
  {
    value: "chat-gpt",
    title: "Chat GPT",
    icon: () => (
      <img src={chatGPTIcon} className="size-4 mr-2" alt="Chat GPT" />
    ),
  },
  {
    value: "meta",
    title: "Meta",
    icon: () => <img src={metaIcon} className="size-4 mr-2" alt="Meta icon" />,
  },
];

const AiAssistantsData = ({
  isModal,
  onUseWithModal,
  tempType,
  selectedCategory,
}: AiAssistantsDataProps) => {
  const [showTempDetail, setShowTempDetail] = useState<any>(null);
  const [allTemplatesLoader, setAllTemplatesLoader] = useState(false);
  const [selectProjectLoader, setSelectProjectLoader] = useState(false);
  const [projectSelectionModal, setProjectSelectionModal] = useState(false);
  const [selectedAIChatId, setSelectedAIChatId] = useState("");

  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState<any>(null);
  const [formLoader, setFormLoader] = useState(false);
  const [assistantByIdLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [publishingLoader, setPublishingLoader] = useState(false);
  const [publishConfirmation, setPublishConfirmation] = useState(false);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { getUserAllAiAssistantsRes } = useAppSelector(
    (state) => state.userAiAssistants
  );
  const { getUserAiAssistantRes } = useAppSelector(
    (state) => state.userAiAssistants
  );
  const { getAllPublishedAiAssistantsRes } = useAppSelector(
    (state) => state.chatGPT
  );

  const [{ search, category, vendor }, searchParamsSetter] =
    useAppSearchParams<{
      search: string;
      category: Category;
      vendor: string;
    }>({
      search: "",
      category: "all",
      vendor: "all",
    });

  const checkAiAssistantsList =
    (() => {
      if (tempType === "my") {
        return getUserAllAiAssistantsRes;
      }

      if (tempType === "community") {
        return getAllPublishedAiAssistantsRes;
      }

      return [];
    })() || [];

  const aiAssistantsByCategory = filter.groupByCategory(checkAiAssistantsList);

  // FILTERING AI ASSISTANTS
  const aiAssistantsCompleteList = pipe(
    () => aiAssistantsByCategory[selectedCategory || category] || [],
    filter.byTitle(search),
    (templates) => {
      // TODO: Implement vendor filter
      return templates;
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAllTemplatesLoader(true);

          if (tempType === "my") {
            await dispatch(
              getUserAllAiAssistants({
                accessToken,
              })
            );
          }

          if (tempType === "community") {
            await dispatch(
              getAllPublishedAiAssistants({
                accessToken,
                query: "",
              })
            );
          }

          setAllTemplatesLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempType]);

  const onOpeningProjectSelection = (id: string) => {
    setSelectedAIChatId(id);
    setProjectSelectionModal(true);
  };

  const onClosingProjectSelection = () => {
    setSelectedAIChatId("");
    setProjectSelectionModal(false);
  };

  const creatingAIChat = (project_id: string, templateType: string) => {
    const fetchData = async () => {
      try {
        setSelectProjectLoader(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id,
              conversation_configuration_id: selectedAIChatId,
              template_type: templateType,
            })
          )
            .then((res) => {
              if (res?.payload) {
                navigate(`/projects/${project_id}`);
                onClosingProjectSelection();
              }
              setSelectProjectLoader(false);
            })
            .catch(() => {
              setSelectProjectLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
  };

  const onOpenFormModal = () => {
    setOpen(true);
    setIsEditMode(false);
  };

  // const onOpenEditModal = (id: string) => {
  //   setIsEditMode(true);
  //   setOpen(true);
  //   gettingSingleAiAssistant(id);
  // };

  const onCloseModal = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  const updatingAIAssistant = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateUserAiAssistant({
              body,
              accessToken,
              resource_id: showTempDetail?.id,
            })
          )
            .then((res) => {
              onCloseModal();
              setFormLoader(false);
              setShowTempDetail(res?.payload?.data);
            })
            .catch(() => {
              setFormLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingAIAssistant = (body: any) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setFormLoader(true);

          await dispatch(createUserAiAssistant({ body, accessToken }))
            .then((response) => {
              onCloseModal();
              setFormLoader(false);

              if (isModal) {
                onUseWithModal?.(response?.payload?.data?.id);
              }
            })
            .catch(() => {
              setFormLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const onSubmitAiAssistant = async (body: any) => {
    if (isEditMode) {
      updatingAIAssistant(body);
    } else {
      creatingAIAssistant(body);
    }
  };

  // const gettingSingleAiAssistant = (id: string) => {
  //   const fetchData = async () => {
  //     setAssistantByIdLoader(true);
  //     try {
  //       const accessToken = await getAccessTokenSilently();
  //       if (accessToken)
  //         await dispatch(getUserAiAssistant({ accessToken, resource_id: id }));
  //       setAssistantByIdLoader(false);
  //     } catch (error) {
  //       console.error("Error getting access token:", error);
  //       setAssistantByIdLoader(false);
  //     }
  //   };

  //   fetchData();
  // };

  const deletingAIAssistant = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteUserAiAssistant({ accessToken, resource_id: selectedId })
          )
            .then(() => {
              setConfirmationLoader(false);
              deletingModalClose();
              setShowTempDetail(null);
            })
            .catch(() => {
              setConfirmationLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  // const deletingModalOpen = (id: string) => {
  //   setDeleteConfirm(true);
  //   setSelectedId(id);
  // };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedId(null);
  };

  const publishingAIAssistant = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            publishUserAiAssistant({
              accessToken,
              resource_id: showTempDetail
                ? showTempDetail?.id
                : getLastIdFromUrl(window.location.pathname),
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: false,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const unPublishingAIAssistant = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            unPublishUserAiAssistant({
              accessToken,
              resource_id: showTempDetail
                ? showTempDetail?.id
                : getLastIdFromUrl(window.location.pathname),
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: true,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  return (
    <>
      <WrapperLoader loading={allTemplatesLoader}>
        <div className="flex">
          <div className="w-full">
            {checkAiAssistantsList?.length > 0 ? (
              <div>
                {/* {showTempDetail && (
                  <div className="pb-4 mt-4 border-b border-gray-200 text-right">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center justify-between w-full">
                        {!isModal && (
                          <>
                            <CustomButton
                              text="Back"
                              type="button"
                              onClickBtn={() => setShowTempDetail(null)}
                              outlined
                              btnStyle="border-gray-300 text-customLightBlue px-6 text-lg mr-4 hover:border-gray-600 hover:text-customDarkBlue"
                            />

                            <div className="flex">
                              <CustomButton
                                text="Delete"
                                type="button"
                                onClickBtn={() =>
                                  deletingModalOpen(showTempDetail?.id)
                                }
                                beforeIcon={<TrashIcon className="h-4 mr-1" />}
                                loading={false}
                                outlined
                                btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                              />

                              <CustomButton
                                text={
                                  showTempDetail?.privacy === true
                                    ? "Publish"
                                    : "Unpublish"
                                }
                                type="button"
                                onClickBtn={() => setPublishConfirmation(true)}
                                beforeIcon={
                                  <ArrowUpOnSquareStackIcon className="h-4 mr-1" />
                                }
                                loading={publishingLoader}
                                outlined
                                btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                              />

                              <CustomButton
                                text="Publish on LinkedIn"
                                type="button"
                                onClickBtn={() => console.log("hello...")}
                                beforeIcon={
                                  <ArrowDownCircleIcon className="h-4 mr-1" />
                                }
                                loading={false}
                                outlined
                                btnStyle="whitespace-nowrap text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue"
                              />

                              <CustomButton
                                text="Edit"
                                type="button"
                                onClickBtn={() =>
                                  onOpenEditModal(showTempDetail?.id)
                                }
                                beforeIcon={<PencilIcon className="h-4 mr-1" />}
                                loading={false}
                                outlined
                                btnStyle="text-customLightBlue border-0 shadow-none hover:bg-zinc-100 hover:text-customLightBlue ml-3"
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )} */}

                <div className={isModal ? "pb-0" : 'pb-6'}>
                  <div>
                    {showTempDetail ? (
                      <AIChatTempDetail
                        onCancel={() => setShowTempDetail(null)}
                        onUse={
                          isModal && onUseWithModal
                            ? onUseWithModal
                            : onOpeningProjectSelection
                        }
                        isModal={isModal}
                        tempType={tempType}
                        showTempDetail={showTempDetail}
                      />
                    ) : (
                      <div className="flex">
                        {!isModal && tempType === "community" && (
                          <div>
                            {checkAiAssistantsList.length === 0 ? (
                              ""
                            ) : (
                              <div>
                                <div className="w-[270px]">
                                  <div className="bg-white p-4 border-r border-gray-200 h-[calc(100vh_-_49px)]">
                                    <CategoriesLeftSidebar
                                      setSelectedCategory={(newCategory) =>
                                        searchParamsSetter.set(
                                          "category",
                                          newCategory
                                        )
                                      }
                                      selectedCategory={category}
                                      groupedItems={aiAssistantsByCategory}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}{" "}
                        <div
                          className="w-full overflow-y-auto px-4 mt-4"
                          style={{
                            height: isModal
                              ? "calc(100vh - 290px)"
                              : "calc(100vh - 132px)",
                          }}
                        >
                          {!showTempDetail && (
                            <>
                              {category === "all" &&
                              (checkAiAssistantsList?.length === 0 ||
                                !checkAiAssistantsList) ? (
                                ""
                              ) : (
                                <div className="flex items-center justify-between w-full pb-4 mb-2 border-b border-zinc-100">
                                  <div className="flex items-center">
                                    <div className="w-80 mr-3">
                                      <SearchField
                                        onSearchField={(value: string) =>
                                          searchParamsSetter.set(
                                            "search",
                                            value
                                          )
                                        }
                                        searchValue={search}
                                      />
                                    </div>

                                    <div className="mr-3">
                                      <SelectField
                                        options={vendorFiltersList}
                                        onSelect={(value: string) =>
                                          searchParamsSetter.set(
                                            "vendor",
                                            value
                                          )
                                        }
                                        value={vendor}
                                        label="Vendor"
                                        classes="py-[6px] w-fit"
                                        containerClasses="w-fit"
                                      />
                                    </div>
                                  </div>

                                  {tempType !== "community" && (
                                    <CustomButton
                                      text="New Assistant"
                                      type="button"
                                      onClickBtn={onOpenFormModal}
                                      beforeIcon={
                                        <PlusIcon className="h-4 mr-1" />
                                      }
                                      buttonType='primary'
                                    />
                                  )}
                                </div>
                              )}
                            </>
                          )}

                          {aiAssistantsCompleteList?.length > 0 && (
                            <div className="ml-[-4px] mr-[-4px]">
                              <div
                                className={`grid grid-cols-2 ${isModal ? "[@media(min-width:1250px)]:grid-cols-2 [@media(min-width:1500px)]:grid-cols-3 [@media(min-width:2000px)]:grid-cols-3 gap-2" : "[@media(min-width:1250px)]:grid-cols-3 [@media(min-width:1500px)]:grid-cols-4 [@media(min-width:2000px)]:grid-cols-5 gap-4"}`}
                              >
                                {aiAssistantsCompleteList?.map(
                                  (data: any, index: number) => {
                                    return (
                                      <TemplateBox
                                        key={index}
                                        data={data}
                                        onShow={() => {
                                          isModal
                                            ? setShowTempDetail(data)
                                            : (tempType === "my" &&
                                                navigate(
                                                  `/ai-assistant/${data?.id}`
                                                )) ||
                                              (tempType === "community" &&
                                                navigate(
                                                  `/community-ai-assistant/${data?.id}`
                                                ));
                                        }}
                                        onUse={
                                          isModal && onUseWithModal
                                            ? onUseWithModal
                                            : onOpeningProjectSelection
                                        }
                                        tempType={tempType}
                                      />
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="flex items-center justify-center"
                style={{ height: "calc(100vh - 72px)" }}
              >
                <CompleteEmptyPage
                  title="You don't have any Assistant"
                  subTitle="Get started by creating a new AI Assistant"
                  btnText="New Assistant"
                  onClickBtn={onOpenFormModal}
                  icon={aiSVG}
                />
              </div>
            )}
          </div>
        </div>
      </WrapperLoader>

      <CustomModal
        open={projectSelectionModal}
        onCloseModal={() => onClosingProjectSelection()}
        title={
          <div>
            <h1 className="font-semibold text-lg mb-1">Create a new Chat</h1>
            <p className="text-xs font-normal">
              Select project and start a new chat
            </p>
          </div>
        }
        size="max-w-lg"
      >
        <ProjectsSelection
          onCloseModal={() => onClosingProjectSelection()}
          loading={selectProjectLoader}
          onSubmitData={creatingAIChat}
          templateType="private"
        />
      </CustomModal>

      <CustomDrawer
        open={open}
        onCloseModal={onCloseModal}
        title={
          <div className="flex items-center">
            <span>{isEditMode ? "Update" : "Create"} AI Assistant</span>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer"
              className="text-xs text-yellow-500 hover:text-yellow-800 flex items-center font-semibold ml-3"
            >
              <span className="mr-1">Learn More</span>
              <ArrowTopRightOnSquareIcon className="h-3" />
            </a>
          </div>
        }
        description={
          isEditMode
            ? "Update Your AI Assistant."
            : "Create new AI Assistant, provide all the data during create your Assistant."
        }
        distanced
      >
        <WrapperLoader loading={assistantByIdLoader}>
          <CreationForm
            onCloseModal={onCloseModal}
            editData={getUserAiAssistantRes}
            onSubmitForm={onSubmitAiAssistant}
            loader={formLoader}
            editMode={isEditMode}
          />
        </WrapperLoader>
      </CustomDrawer>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingAIAssistant}
        deleteLoader={confirmationLoader}
      />

      <PublishConfirmation
        open={publishConfirmation}
        closingModal={() => setPublishConfirmation(false)}
        onClickYes={
          showTempDetail?.privacy === true
            ? () => publishingAIAssistant()
            : () => unPublishingAIAssistant()
        }
        loading={publishingLoader}
        privacy={showTempDetail?.privacy}
      />
    </>
  );
};

export default AiAssistantsData;
