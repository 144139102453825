import React, { useContext } from "react";
import { BoltIcon, ArrowDownIcon, CircleStackIcon, BugAntIcon, ChevronDownIcon, PhotoIcon } from "@heroicons/react/24/outline";
import { useAppDispatch } from "../../../../../hooks";
import { setSelectedDocument } from "../../../../../redux/projects/projectsSlice";
// import Toolbar, { ToolbarItemType } from "../../../../../components/toolbar";
import { loadingIcon } from "../../../../../assets/icons";
import DropdownSelectionOption from "../../../../../components/dropdownSelectionOption";
import { DetailsContext } from "src/contexts/details/context";
import ItemWithDropdown from "../../../../../components/itemWithDropdown";
import {useDownloadGraphImage} from '../../../../flowchart/flow/DownloadGraphImage';

interface DiagramActionsProps {
  setOpenGenerateModal: Function;
  diagramSaveLoading: boolean;
  updatingProjectDiagram: Function;
  generateDocumentFromDiagram: Function;
}

export default function DiagramActions({
  setOpenGenerateModal,
  diagramSaveLoading,
  updatingProjectDiagram,
  generateDocumentFromDiagram,
}: DiagramActionsProps) {
const { allDocuments, selectedDocument } =
  useContext(DetailsContext);
  const dispatch = useAppDispatch();

  const onSelectingDocument = (value: any) => {
    dispatch(setSelectedDocument(value));
  };
  const downloadGraphImage = useDownloadGraphImage();

  const validateList = [
    {
      id: 1,
      name: "Create Document from Diagram",
      onActionClick: () => generateDocumentFromDiagram(),
    },
    {
      id: 1,
      name: "/Check for limitations",
      onActionClick: () => console.log("..."),
    },
    {
      id: 2,
      name: "/Validate Trafic Flow",
      onActionClick: () => console.log("..."),
    },
  ];

  const buttonLabelText = (text: string) => {
    return <span className="text-gray-700 ml-2 text-[14px]">{text}</span>;
  }

  const iconWrapper =
    "cursor-pointer px-[14px] py-[3.15px]";

  return (
    <div className="absolute right-[8px] top-[8px] z-10">
      <div className="flex items-center justify-center h-[38px]">
        <div className="group h-full flex bg-white border rounded-[10px] shadow-md border-[#e5e7eb] hover:border-[#2d61d2] hover py-[2.5px] mr-[8px]">
          <div className={`flex items-center h-full ${iconWrapper}`}>
            <DropdownSelectionOption
              optionsList={allDocuments}
              selected={selectedDocument}
              setSelected={onSelectingDocument}
              btnText="Add Document"
              buttonClassName="text-xs font-medium text-customLightBlue hover:text-gray-700"
              className="flex"
              dropdownClassName="top-full h-full"
              mainIcon={CircleStackIcon}
              fromPage="diagram"
              dropdownPosition="left"
            />
          </div>
        </div>

        <div className="flex items-center bg-white h-full mr-[8px] rounded-[10px] shadow-md">
          <div className="group relative h-full flex rounded-l-[10px] border border-[#e5e7eb] hover:border-[#2d61d2] hover">
            <ItemWithDropdown
              title="Validate"
              actionsData={validateList}
              buttonClassName="h-full"
            >
              <div className={`flex items-center h-full ${iconWrapper}`}>
                <BugAntIcon className="h-4 text-zinc-800" />
                {buttonLabelText("Generate")}

                <ChevronDownIcon className="h-4 ml-2 stroke-gray-400" />
              </div>
            </ItemWithDropdown>
          </div>

          <div className="group h-full flex rounded-r-[10px] border border-[#e5e7eb] hover:border-[#2d61d2] hover">
            <div
              className={`flex items-center ${iconWrapper}`}
              onClick={() => setOpenGenerateModal()}
            >
              <BoltIcon className="h-4 text-zinc-800" />

              {buttonLabelText("Generate")}
            </div>
          </div>
        </div>

        <div className="flex items-center bg-white h-full rounded-[10px] shadow-md">
          <div className="group h-full flex border border-[#e5e7eb] hover:border-[#2d61d2] rounded-l-[10px]">
            <div
              className={`flex items-center ${iconWrapper}`}
              onClick={() => downloadGraphImage()}
            >
              <PhotoIcon className="h-[18px] text-zinc-800" />

              {buttonLabelText("Image")}
            </div>
          </div>

          <div className="group h-full flex border border-[#e5e7eb] hover:border-[#2d61d2] hover rounded-r-[10px]">
            <div
              className={`flex items-center ${iconWrapper}`}
              onClick={() => updatingProjectDiagram()}
            >
              {diagramSaveLoading ? (
                loadingIcon
              ) : (
                <ArrowDownIcon className="h-4 text-zinc-800" />
              )}
              {buttonLabelText("Save")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
