import React, {
  DragEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
} from "react";
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  ChevronDownIcon,
  ArrowUpRightIcon,
  ArrowUturnDownIcon,
  ArrowTrendingDownIcon,
} from "@heroicons/react/24/outline";
import {
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  Edge,
  MarkerType,
  Node,
  NodeChange,
  OnEdgesDelete,
  OnNodeDrag,
  OnNodesChange,
  OnNodesDelete,
  Panel,
  ProOptions,
  ReactFlow,
  SelectionDragHandler,
  addEdge,
  applyNodeChanges,
  reconnectEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import {
  CircleAnimatedEdge,
  ImageOnEdge,
} from "./customeAnimatedNode/AnimatedEdge";
import { Menu, MenuButton, MenuItems } from "@headlessui/react";
import { debounce } from "lodash";
import {
  colors,
  edgeStyles,
  edgeTypesList,
  getId,
  getNodePositionInsideParent,
  sortNodes,
} from "./utils";
import {
  edges as initialEdges,
  nodes as initialNodes,
} from "./initial-elements";
import {
  setOpenNodeSetting,
  setSelectedNodeSetting,
} from "../../../redux/diagrams/diagramsSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks";

import CustomDrawer from "../../../components/customDrawer";
import EdgeIconsDropdown from "./EdgeIconsDropdown";
import GroupNode from "./GroupNode";
import HelperLines from "./HelperLines";
import SelectedNodesToolbar from "./SelectedNodesToolbar";
import Sidebar from "./Sidebar";
import SimpleNode from "./SimpleNode";
import { getHelperLines } from "./utils";
import styles from "./style.module.css";
import SwitchSlide from "../../../components/switchSlide";
import useCopyPaste from "./useCopyPaste";
import useUndoRedo from "./useUndoRedo";
import CustomButton from "../../../components/customButton";
import { ShapeNode, ShapeType } from "./shapeNode/shape/types";
import ShapeNodeComponent from "./shapeNode";
import TextNode from "./TextNode";

import "@xyflow/react/dist/style.css";
import "@reactflow/node-resizer/dist/style.css";

const proOptions: ProOptions = { account: "paid-pro", hideAttribution: true };

const onDragOver = (event: DragEvent) => {
  event.preventDefault();
  event.dataTransfer.dropEffect = "move";
};

const nodeTypes = {
  node: SimpleNode,
  group: GroupNode,
  shape: ShapeNodeComponent,
  text_node: TextNode,
};

const defaultEdgeOptions = {
  style: {
    strokeWidth: 2,
  },
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
};

// Animated Edge Types
const edgeTypes = {
  circleAnimatedEdge: CircleAnimatedEdge,
  imageOnEdge: ImageOnEdge,
};

interface FlowChartProps {
  diagram: string;
}

const DynamicGrouping = memo(function DynamicGrouping({
  diagram,
}: FlowChartProps) {
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const [helperLineHorizontal, setHelperLineHorizontal] = useState<
    number | undefined
  >(undefined);

  const [helperLineVertical, setHelperLineVertical] = useState<
    number | undefined
  >(undefined);
  const [selectedEdge, setSelectedEdge] = useState<Edge | null>(null);
  const [selectedEdgeColor, setSelectedEdgeColor] = useState(colors[0]);
  const [selectedEdgeBorder, setSelectedEdgeBorder] = useState("2");
  const [selectedEdgeType, setSelectedEdgeType] = useState("bezier");
  const [isEdgeAnimated, setIsEdgeAnimated] = useState(true);
  const [animatedCircleColor, setAnimatedCircleColor] = useState("");
  const [edgeImage, setEdgeImage] = useState("");
  const [connectorLayout, setConnectorLayout] = useState("");

  const { selectedDiagram, selectedNodeSetting, openNodeSetting } =
    useAppSelector((state) => state.diagrams);
  const { screenToFlowPosition, getIntersectingNodes, getNodes, fitView } =
    useReactFlow();
  const dispatch = useAppDispatch();
  useCopyPaste();
  const { undo, redo, canUndo, canRedo, takeSnapshot } = useUndoRedo();

  const edgeReconnectSuccessful = useRef(true);

  // Declare onResetFormattingState before its usage
  const onResetFormattingState = useCallback(() => {
    setSelectedEdge(null);
    setEdgeImage("");
    setAnimatedCircleColor("");
    setSelectedEdgeColor(colors[0]);
    setSelectedEdgeBorder("2");
    setSelectedEdgeType("bezier");
    setIsEdgeAnimated(true);
    setConnectorLayout("");
  }, []);

  useEffect(() => {
    const { nodes, edges } = selectedDiagram?.design
      ? JSON.parse(selectedDiagram?.design)
      : { nodes: null, edges: null };
    setNodes(nodes || []);
    setEdges(edges || []);

    // Run fitView after nodes and edges are set
    setTimeout(() => {
      fitView({ padding: 0.1, duration: 800 });
    }, 850);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDiagram]);

  useEffect(() => {
    if (diagram) {
      try {
        // Regular expressions to extract initialNodes and initialEdges
        const nodesRegex = /const\s+initialNodes\s*=\s*(\[[\s\S]*?\]);/;
        const edgesRegex = /const\s+initialEdges\s*=\s*(\[[\s\S]*?\]);/;

        // Function to convert JS object notation to JSON-compliant string
        const toJSONCompliantString = (str: any) => {
          return (
            str
              // Add quotes around keys
              .replace(/([{,]\s*)(\w+)(\s*:)/g, '$1"$2"$3')
              // Replace single quotes with double quotes
              .replace(/'/g, '"')
          );
        };

        // Extract and convert initialNodes to JSON-compliant string
        const initialNodesMatch = diagram.match(nodesRegex);
        const initialNodesString = initialNodesMatch
          ? toJSONCompliantString(initialNodesMatch[1])
          : "[]";

        // Extract and convert initialEdges to JSON-compliant string
        const initialEdgesMatch = diagram.match(edgesRegex);
        const initialEdgesString = initialEdgesMatch
          ? toJSONCompliantString(initialEdgesMatch[1])
          : "[]";

        // Parse the strings into JavaScript objects
        const initialNodes = JSON.parse(initialNodesString);
        const initialEdges = JSON.parse(initialEdgesString);

        // Set nodes and edges states
        setNodes(initialNodes);
        setEdges(initialEdges);

        // Run fitView after nodes and edges are set
        setTimeout(() => {
          fitView({ padding: 0.1, duration: 800 });
        }, 0);
      } catch (error) {
        console.error("Error parsing nodes and edges:", error);
      }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagram]);

  useEffect(() => {
    if (selectedEdge) {
      if (selectedEdge?.style) {
        setSelectedEdgeColor(`${selectedEdge?.style?.stroke}`);
        setSelectedEdgeBorder(`${selectedEdge?.style?.strokeWidth}`);
        setConnectorLayout(`${selectedEdge?.style?.strokeDasharray}`);
      }

      if (
        selectedEdge?.type !== "circleAnimatedEdge" &&
        selectedEdge?.type !== "imageOnEdge"
      ) {
        setSelectedEdgeType(`${selectedEdge?.type}`);
      }

      if (selectedEdge?.type !== "circleAnimatedEdge") {
        setAnimatedCircleColor("");
      }

      if (selectedEdge?.type !== "imageOnEdge") {
        setEdgeImage("");
      }

      if (selectedEdge?.type === "circleAnimatedEdge" && selectedEdge?.data) {
        setEdgeImage("");
        setAnimatedCircleColor(`${selectedEdge?.data?.color}`);
        setSelectedEdgeType(`${selectedEdge?.data?.edgeType}`);
      }

      if (selectedEdge?.type === "imageOnEdge" && selectedEdge?.data) {
        setAnimatedCircleColor("");
        setEdgeImage(`${selectedEdge?.data?.imgUrl}`);
        setSelectedEdgeType(`${selectedEdge?.data?.edgeType}`);
      }

      setIsEdgeAnimated(selectedEdge?.animated || false);
    }
  }, [selectedEdge]);

  // Function to convert JavaScript-like object strings to JSON strings
  const toJsonString = (jsObjectString: any) => {
    return jsObjectString
      .replace(/([{,]\s*)(\w+)\s*:/g, '$1"$2":') // Add quotes around property names
      .replace(/'/g, '"'); // Replace single quotes with double quotes
  };

  const onConnect = useCallback(
    (edge: Edge | Connection) => {
      takeSnapshot();
      const newEdge = {
        ...edge,
        animated: true,
        type: "bezier",
        style: { strokeWidth: 2, strokeDasharray: "5,5", stroke: "#b1b1b7" },
      };
      setEdges((eds) => addEdge(newEdge, eds));
    },
    [setEdges, takeSnapshot]
  );

  const onDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();

      const nodeType = event.dataTransfer.getData("application/nodeType");

      if (nodeType === "shape") {
        const type = event.dataTransfer.getData(
          "application/reactflow"
        ) as ShapeType;

        const position = screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });

        const newNode: ShapeNode = {
          id: getId(),
          type: "shape",
          position,
          style: { width: 150, height: 150 },
          data: {
            type,
            color: "#3F8AE2",
          },
          selected: true,
        };

        setNodes((nodes) =>
          (nodes.map((n) => ({ ...n, selected: false })) as ShapeNode[]).concat(
            [newNode]
          )
        );
      } else {
        const type = event.dataTransfer.getData("application/reactflow");
        const iconUrl = event.dataTransfer.getData("iconUrl/reactflow");
        const name = event.dataTransfer.getData("name/reactflow");

        // Style for the group
        const style = event.dataTransfer.getData("style/reactflow");
        const styleParse = style ? JSON.parse(style) : null;

        const position = screenToFlowPosition({
          x: event.clientX - 20,
          y: event.clientY - 20,
        });
        const nodeDimensions =
          type === "group" ? { width: 400, height: 400 } : { width: 120 };

        const intersections = getIntersectingNodes({
          x: position.x,
          y: position.y,
          width: 40,
          height: 40,
        }).filter((n) => n.type === "group");
        const groupNode =
          Array.isArray(intersections) && intersections?.length > 0
            ? intersections[intersections?.length - 1]
            : null;

        const newNode: Node = {
          id: getId(),
          type,
          position,
          data: {
            label: `${name}`,
            icon_url: iconUrl,
            border_style:
              type === "group" ? styleParse?.group_border_style || "" : "",
            color:
              type === "group" ? styleParse?.group_icon_bg_color || "" : "",
            background:
              type === "group" ? styleParse?.group_bg_color || "" : "",
          },
          ...nodeDimensions,
        };

        if (groupNode) {
          // if we drop a node on a group node, we want to position the node inside the group
          newNode.position = getNodePositionInsideParent(
            {
              position,
              width: 40,
              height: 40,
            },
            groupNode
          ) ?? { x: 0, y: 0 };
          newNode.parentId = groupNode?.id;
          newNode.expandParent = false;
        }

        // we need to make sure that the parents are sorted before the children
        // to make sure that the children are rendered on top of the parents
        const sortedNodes = getNodes().concat(newNode).sort(sortNodes);
        setNodes(sortedNodes);
      }
    },
    [screenToFlowPosition, getIntersectingNodes, getNodes, setNodes]
  );

  const onNodeDragStop = useCallback(
    (_: MouseEvent, node: Node) => {
      // make dragging a node undoable
      takeSnapshot();

      if (node.type !== "node" && !node.parentId) {
        return;
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === "group"
      );

      const groupNode =
        Array.isArray(intersections) && intersections?.length > 0
          ? intersections[intersections?.length - 1]
          : null;

      // when there is an intersection on drag stop, we want to attach the node to its new parent
      if (
        intersections.length &&
        groupNode &&
        node.parentId !== groupNode?.id
      ) {
        const nextNodes: Node[] = getNodes()
          .map((n) => {
            if (n.id === groupNode.id) {
              return {
                ...n,
                className: "",
              };
            }
            // else if (n.id === node.id) {
            //   const position = getNodePositionInsideParent(n, groupNode) ?? {
            //     x: 0,
            //     y: 0,
            //   };

            //   return {
            //     ...n,
            //     position,
            //     parentId: groupNode.id,
            //     extent: "parent",
            //   } as Node;
            // }

            return n;
          })
          .sort(sortNodes);

        setNodes(nextNodes);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getIntersectingNodes, getNodes, setNodes]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetNodes = useCallback(debounce(setNodes, 100), [setNodes]);

  const onNodeDrag = useCallback(
    (_: MouseEvent, node: Node) => {
      if (node.type !== "node" && !node.parentId) {
        return;
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === "group"
      );
      const groupClassName =
        intersections.length &&
        node.parentId !== intersections[intersections?.length - 1]?.id
          ? "active"
          : "";

      // Only update nodes if necessary
      debouncedSetNodes((nds) => {
        let updated = false;
        const newNodes = nds.map((n) => {
          if (n.id === node.id && n.selected) {
            // Update position only if the node is selected
            if (n.position !== node.position) {
              updated = true;
              return {
                ...n,
                position: node.position,
              };
            }
          } else if (n.type === "group" && n.className !== groupClassName) {
            updated = true;
            return {
              ...n,
              className: groupClassName,
            };
          }
          return n;
        });
        return updated ? newNodes : nds;
      });
    },
    [getIntersectingNodes, debouncedSetNodes]
  );

  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const onReconnect = useCallback((oldEdge: any, newConnection: any) => {
    edgeReconnectSuccessful.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onReconnectEnd = useCallback((_: any, edge: any) => {
    if (!edgeReconnectSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeReconnectSuccessful.current = true;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customApplyNodeChanges = useCallback(
    (changes: NodeChange[], nodes: Node[]): Node[] => {
      // reset the helper lines (clear existing lines, if any)
      setHelperLineHorizontal(undefined);
      setHelperLineVertical(undefined);

      // this will be true if it's a single node being dragged
      // inside we calculate the helper lines and snap position for the position where the node is being moved to
      if (
        changes.length === 1 &&
        changes[0].type === "position" &&
        changes[0].dragging &&
        changes[0].position
      ) {
        const helperLines = getHelperLines(changes[0], nodes);

        // if we have a helper line, we snap the node to the helper line position
        // this is being done by manipulating the node position inside the change object
        changes[0].position.x =
          helperLines.snapPosition.x ?? changes[0].position.x;
        changes[0].position.y =
          helperLines.snapPosition.y ?? changes[0].position.y;

        // if helper lines are returned, we set them so that they can be displayed
        setHelperLineHorizontal(helperLines.horizontal);
        setHelperLineVertical(helperLines.vertical);
      }

      return applyNodeChanges(changes, nodes);
    },
    []
  );

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      setNodes((nodes) => customApplyNodeChanges(changes, nodes));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes, customApplyNodeChanges]
  );

  const onCloseNodeEditSetting = () => {
    dispatch(setOpenNodeSetting(false));
    dispatch(setSelectedNodeSetting(null));
  };

  const onNodeDragStart: OnNodeDrag = useCallback(() => {
    // make dragging a node undoable
    takeSnapshot();
  }, [takeSnapshot]);

  const onSelectionDragStart: SelectionDragHandler = useCallback(() => {
    // make dragging a selection undoable
    takeSnapshot();
  }, [takeSnapshot]);

  const onNodesDelete: OnNodesDelete = useCallback(() => {
    // make deleting nodes undoable
    takeSnapshot();
  }, [takeSnapshot]);

  const onEdgesDelete: OnEdgesDelete = useCallback(() => {
    // make deleting edges undoable
    takeSnapshot();

    onResetFormattingState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takeSnapshot]);

  // Select an edge when clicked
  const onEdgeClick = useCallback((event: React.MouseEvent, edge: Edge) => {
    event.stopPropagation();
    setSelectedEdge(edge);
  }, []);

  // Select an node when clicked
  const onNodeClick = useCallback(
    (event: React.MouseEvent, node: Node) => {
      event.stopPropagation();
      onResetFormattingState();

      // Deselect all nodes and select only the clicked node
      setNodes((nds) =>
        nds.map((n) => ({
          ...n,
          selected: n.id === node.id,
        }))
      );
    },
    [onResetFormattingState, setNodes]
  );

  // Handle edge style change
  const handleStyleChange = useCallback(
    (style: React.CSSProperties) => {
      if (selectedEdge) {
        setConnectorLayout(`${style.strokeDasharray}`);
        setEdges((edges) =>
          edges.map((edge) =>
            edge.id === selectedEdge.id
              ? {
                  ...edge,
                  style: {
                    ...edge.style,
                    strokeDasharray: style?.strokeDasharray,
                  },
                }
              : edge
          )
        );
      }
    },
    [selectedEdge, setEdges]
  );

  // Handle edge type change
  const onChangeEdgeType = useCallback(
    (edgeObj: any) => {
      setSelectedEdgeType(`${edgeObj.type}`);
      if (!selectedEdge) return;
      setEdges((edges) =>
        edges.map((edge) => {
          // Check if the current edge is the selected one
          if (edge.id === selectedEdge.id) {
            // Check if the selected edge is animated
            if (
              selectedEdge.type === "circleAnimatedEdge" ||
              selectedEdge.type === "imageOnEdge"
            ) {
              return {
                ...edge,
                data: { ...edge.data, edgeType: edgeObj.type }, // Set edgeType in data for animated edges
              };
            }

            // For non-animated edges, update the type directly
            return {
              ...edge,
              type: edgeObj.type,
            };
          }

          // Return edge unchanged if it's not the selected one
          return edge;
        })
      );
    },
    [selectedEdge, setEdges]
  );

  // Handle edge animation change with color
  const addObjectChange = useCallback(
    (edgeObj: any) => {
      if (selectedEdge) {
        setEdgeImage("");
        setAnimatedCircleColor(`${edgeObj?.color}`);
        const getEdgeItem = edges?.find((data) => data.id === selectedEdge.id);

        setEdges((edges) =>
          edges.map((edge) =>
            edge.id === selectedEdge.id
              ? {
                  ...edge,
                  type: "circleAnimatedEdge",
                  data: {
                    ...edge.data,
                    edgeType: selectedEdgeType,
                    color: edgeObj?.color,
                  },
                } // Apply new type to the selected edge
              : edge
          )
        );

        if (getEdgeItem) {
          setSelectedEdge({
            ...getEdgeItem,
            type: "circleAnimatedEdge",
            data: {
              ...getEdgeItem.data,
              edgeType: selectedEdgeType,
              color: edgeObj?.color,
            },
          });
        }
      }
    },
    [selectedEdge, edges, selectedEdgeType, setEdges]
  );

  // Handle edge image change
  const onChangeEdgeImage = useCallback(
    (edgeObj: any) => {
      if (selectedEdge) {
        setAnimatedCircleColor("");
        setEdgeImage(`${edgeObj?.url}`);

        const getEdgeItem = edges?.find((data) => data.id === selectedEdge.id);

        setEdges((edges) =>
          edges.map((edge) =>
            edge.id === selectedEdge.id
              ? {
                  ...edge,
                  type: "imageOnEdge",
                  data: {
                    ...edge.data,
                    edgeType: selectedEdgeType,
                    imgUrl: edgeObj?.url,
                    label: edgeObj?.name,
                  },
                } // Apply new type to the selected edge
              : edge
          )
        );

        if (getEdgeItem) {
          setSelectedEdge({
            ...getEdgeItem,
            type: "imageOnEdge",
            data: {
              ...getEdgeItem.data,
              edgeType: selectedEdgeType,
              imgUrl: edgeObj?.url,
              label: edgeObj?.name,
            },
          });
        }
      }
    },
    [selectedEdge, edges, selectedEdgeType, setEdges]
  );

  // Function to change stroke width
  const changeEdgeStrokeWidth = useCallback(
    (newStrokeWidth: number, selectedEdge: Edge | null) => {
      setSelectedEdgeBorder(`${newStrokeWidth}`);

      if (!selectedEdge) return; // Ensure there's a selected edge

      setEdges((edges) =>
        edges.map((edge) =>
          edge.id === selectedEdge.id
            ? { ...edge, style: { ...edge.style, strokeWidth: newStrokeWidth } }
            : edge
        )
      );
    },
    [setEdges]
  );

  // Function to change edge color
  const changeEdgeColor = useCallback(
    (
      newColor: string,
      selectedEdge: Edge | null,
      setEdges: React.Dispatch<React.SetStateAction<Edge[]>>
    ) => {
      if (!selectedEdge) return; // Ensure there's a selected edge

      setEdges((edges) =>
        edges.map((edge) =>
          edge.id === selectedEdge.id
            ? {
                ...edge,
                style: { ...edge.style, stroke: newColor },
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                  color: newColor,
                },
              }
            : edge
        )
      );
    },
    []
  ); // Add dependencies if necessary

  // on Enable Edge Animation
  const onChangeEdgeAnimation = useCallback(
    (isAnimated: any) => {
      setIsEdgeAnimated(isAnimated);
      if (!selectedEdge) return; // Ensure there's a selected edge

      setEdges((edges) =>
        edges.map((edge) =>
          edge.id === selectedEdge.id ? { ...edge, animated: isAnimated } : edge
        )
      );
    },
    [selectedEdge, setEdges]
  );

  const handleStrokeWidthChange = useCallback(
    (width: string) => {
      const numberWidth = width ? parseInt(width) : 2;
      changeEdgeStrokeWidth(numberWidth, selectedEdge);
    },
    [selectedEdge, changeEdgeStrokeWidth]
  );

  const handleColorChange = useCallback(
    (color: string) => {
      setSelectedEdgeColor(color);
      changeEdgeColor(color, selectedEdge, setEdges);
    },
    [changeEdgeColor, selectedEdge, setEdges]
  );

  // Clear Connection All Formatting
  const onClearAllFormatting = useCallback(() => {
    if (selectedEdge) {
      setEdgeImage("");
      setAnimatedCircleColor("");

      setEdges((edges) =>
        edges.map((edge) =>
          edge.id === selectedEdge.id
            ? {
                ...edge,
                style: {
                  strokeWidth: 2,
                  strokeDasharray: "5,5",
                  stroke: "#b1b1b7",
                },
                type: "bezier",
                animated: true,
              }
            : edge
        )
      );
    }
  }, [selectedEdge, setEdges]);

  const dropdownHeading = (text: string) => {
    return <div className="text-xs my-2 text-[#87888a] uppercase">{text}</div>;
  };

  // Panel Button style
  const panelBtnStyle =
    "py-[10px] h-full px-[14px] text-[#82838d] border border-[#e5e7eb] bg-white hover:border-[#2d61d2]";
  const panelIconStyle = "h-[15px] text-zinc-800";

  return (
    <div className={styles.wrapper}>
      <Sidebar />
      <div className={styles.rfWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onEdgesChange={onEdgesChange}
          onNodesChange={onNodesChange}
          onConnect={onConnect}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          onDrop={onDrop}
          onDragOver={onDragOver}
          proOptions={proOptions}
          selectNodesOnDrag={false}
          nodeTypes={nodeTypes}
          defaultEdgeOptions={defaultEdgeOptions}
          onReconnect={onReconnect}
          onReconnectStart={onReconnectStart}
          onReconnectEnd={onReconnectEnd}
          onNodeDragStart={onNodeDragStart}
          onSelectionDragStart={onSelectionDragStart}
          onNodesDelete={onNodesDelete}
          onEdgesDelete={onEdgesDelete}
          onEdgeClick={onEdgeClick}
          onNodeClick={onNodeClick}
          edgeTypes={edgeTypes}
        >
          <Controls position="bottom-right" className="flow-bottom-controls" />
          <Panel className="top-0 left-0 m-[8px] p-0">
            <div className="flex items-center">
              <div className="bg-white shadow-md rounded-[10px]">
                <CustomButton
                  text=""
                  onClickBtn={undo}
                  type={"button"}
                  beforeIcon={<ArrowUturnLeftIcon className={panelIconStyle} />}
                  btnStyle="rounded-none rounded-l-[10px] hover:border-[#2d61d2] hover:bg-white h-[38px]"
                  buttonType="secondary"
                  disabled={canUndo}
                />

                <CustomButton
                  text=""
                  onClickBtn={redo}
                  type={"button"}
                  beforeIcon={
                    <ArrowUturnRightIcon className={panelIconStyle} />
                  }
                  btnStyle="rounded-none rounded-r-[10px] hover:border-[#2d61d2] hover:bg-white h-[38px]"
                  buttonType="secondary"
                  disabled={canRedo}
                />
              </div>
              {selectedEdge && (
                <div className="flex items-stretch bg-white shadow-md rounded-[10px] ml-[8px]">
                  <Menu as="div" className="relative">
                    <div className="h-full">
                      <MenuButton
                        disabled={!selectedEdge}
                        className={`flex items-center text-xs rounded-l-[10px] ${panelBtnStyle} ${!selectedEdge ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                      >
                        <div className="bg-white py-[2px] px-[4px] rounded-[4px] border border-[#e5e7eb]">
                          <svg height="10" width="50">
                            <line
                              x1="0"
                              y1="5"
                              x2="50"
                              y2="5"
                              stroke="#858790"
                              strokeWidth={2}
                              strokeDasharray={connectorLayout || "none"}
                            />
                          </svg>
                        </div>
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="ml-2 h-4 w-4 text-gray-600"
                        />
                      </MenuButton>
                    </div>

                    <MenuItems className="absolute left-0 z-10 mt-2 px-2 py-1 w-56 origin-top-right divide-y divide-gray-100 rounded-[10px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                      {/* UI for the list of edge styles */}

                      <div>
                        {dropdownHeading("Connector Line")}
                        <div className="grid grid-flow-row gap-1 mb-2">
                          {edgeStyles.map((edgeStyle, index) => (
                            <button
                              key={index}
                              title={edgeStyle.label}
                              className={`p-2 flex bg-[#f5f6f7] border border-transparent hover:border-[#2d61d2] ${connectorLayout === edgeStyle.style.strokeDasharray ? "border-[#2d61d2]" : ""}  rounded-[4px] transition text-xs`}
                              onClick={() => handleStyleChange(edgeStyle.style)}
                            >
                              {/* Show a visual representation of the edge style */}
                              <div className="flex items-center">
                                <div className="bg-white p-[4px] rounded-[4px] border border-[#e5e7eb]">
                                  <svg height="10" width="50">
                                    <line
                                      x1="0"
                                      y1="5"
                                      x2="50"
                                      y2="5"
                                      stroke={edgeStyle.style.stroke}
                                      strokeWidth={edgeStyle.style.strokeWidth}
                                      strokeDasharray={
                                        edgeStyle.style.strokeDasharray ||
                                        "none"
                                      }
                                    />
                                  </svg>
                                </div>
                                <span className="ml-2">{edgeStyle.label}</span>
                              </div>
                            </button>
                          ))}
                        </div>

                        <div className="flex items-center my-[15px]">
                          <span className="text-zinc-500 text-xs mr-2">
                            Animated
                          </span>
                          <SwitchSlide
                            setEnabled={() =>
                              onChangeEdgeAnimation(!isEdgeAnimated)
                            }
                            enabled={isEdgeAnimated}
                          />
                        </div>
                      </div>
                    </MenuItems>
                  </Menu>

                  <Menu as="div" className="relative">
                    <div className="h-full">
                      <MenuButton
                        className={`flex items-center text-[14px] ${panelBtnStyle} py-[3.5px_!important] ${!selectedEdge ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                        disabled={!selectedEdge}
                      >
                        <div className="text-[14px] text-[#4e4e4e] flex">
                          <div className="bg-white px-[4px] py-[2px] rounded-[4px] border border-[#e5e7eb] mr-2">
                            {selectedEdgeType === "straight" && (
                              <ArrowUpRightIcon className="h-4" />
                            )}
                            {selectedEdgeType === "smoothstep" && (
                              <ArrowUturnRightIcon className="h-4" />
                            )}
                            {selectedEdgeType === "step" && (
                              <ArrowUturnDownIcon className="h-4" />
                            )}
                            {selectedEdgeType === "bezier" && (
                              <ArrowTrendingDownIcon className="h-4" />
                            )}
                          </div>
                          <span className="capitalize">{selectedEdgeType}</span>
                        </div>
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="ml-2 h-4 w-4 text-gray-600"
                        />
                      </MenuButton>
                    </div>

                    <MenuItems className="absolute left-0 z-10 mt-2 px-2 py-1 w-56 origin-top-right divide-y divide-gray-100 rounded-[10px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                      <div className="mb-2">
                        {dropdownHeading("Connector Style")}
                        {edgeTypesList.map((edgeType, index) => (
                          <button
                            key={index}
                            title={edgeType.label}
                            className={`p-2 w-full rounded-[5px] bg-[#f5f6f7] border border-transparent hover:border-[#2d61d2] ${selectedEdgeType === edgeType.type ? "border-[#2d61d2]" : ""} transition text-xs mb-1 w-full`}
                            onClick={() => onChangeEdgeType(edgeType)} // Handle edge type selection
                          >
                            <div className=" text-[14px] text-[#4e4e4e] flex items-center">
                              <div className="bg-white p-[4px] rounded-[4px] border border-[#e5e7eb] mr-2">
                                {edgeType?.type === "straight" && (
                                  <ArrowUpRightIcon className="h-3" />
                                )}
                                {edgeType?.type === "smoothstep" && (
                                  <ArrowUturnRightIcon className="h-3" />
                                )}
                                {edgeType?.type === "step" && (
                                  <ArrowUturnDownIcon className="h-3" />
                                )}
                                {edgeType?.type === "bezier" && (
                                  <ArrowTrendingDownIcon className="h-3" />
                                )}
                              </div>
                              <span>{edgeType?.label}</span>
                            </div>
                          </button>
                        ))}
                      </div>
                    </MenuItems>
                  </Menu>

                  <Menu as="div" className="relative">
                    <div className="h-full">
                      <MenuButton
                        className={`flex items-center text-[14px] ${panelBtnStyle} py-[3.5px_!important] ${!selectedEdge ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                        disabled={!selectedEdge}
                      >
                        {edgeImage && (
                          <img
                            className="h-[17px]"
                            src={edgeImage}
                            alt="Selected Icon"
                          />
                        )}
                        {animatedCircleColor && (
                          <button
                            className={`w-[13px] h-[13px] rounded-full p-1 scale-125 border-2 border-zinc-100 hover:scale-125`}
                            style={{
                              background: animatedCircleColor,
                              outline: `solid ${animatedCircleColor} 1px`,
                            }}
                          />
                        )}

                        {!edgeImage && !animatedCircleColor && "Icon"}
                        <ChevronDownIcon
                          aria-hidden="true"
                          className="ml-2 h-4 w-4 text-gray-600"
                        />
                      </MenuButton>
                    </div>

                    <MenuItems className="absolute left-[-100px] z-10 mt-2 p-2 origin-top-right divide-y divide-gray-100 rounded-[10px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                      <EdgeIconsDropdown
                        onChangeEdgeImage={onChangeEdgeImage}
                        addObjectChange={addObjectChange}
                        animatedCircleColor={animatedCircleColor}
                        edgeImage={edgeImage}
                      />
                    </MenuItems>
                  </Menu>

                  <Menu as="div" className="relative">
                    <div className="h-full">
                      <MenuButton
                        className={`flex items-center ${panelBtnStyle} ${!selectedEdge ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                        disabled={!selectedEdge}
                      >
                        <div
                          style={{ backgroundColor: selectedEdgeColor }}
                          className="w-4 h-4 rounded-sm"
                        />

                        <ChevronDownIcon
                          aria-hidden="true"
                          className="ml-2 h-4 w-4 text-gray-600"
                        />
                      </MenuButton>
                    </div>

                    <MenuItems className="absolute left-0 z-10 mt-2 p-2 w-56 origin-top-right rounded-[10px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
                      {dropdownHeading("Connector Colors")}
                      <div className="grid grid-cols-6 gap-2">
                        {colors.map((color) => (
                          <button
                            key={color}
                            style={{ backgroundColor: color }}
                            className="w-6 h-6 rounded-[5px] transition-all hover:scale-125"
                            onClick={() => handleColorChange(color)}
                          />
                        ))}
                      </div>
                    </MenuItems>
                  </Menu>

                  <div className="inline-block">
                    <select
                      id="stroke-width"
                      name="stroke-width"
                      value={selectedEdgeBorder}
                      onChange={(e) => handleStrokeWidthChange(e.target.value)}
                      className={`border border-[#e5e7eb] bg-white h-full hover:border-[#2d61d2] py-0 text-[14px] ${!selectedEdge ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
                      style={{ boxShadow: "none" }}
                      disabled={!selectedEdge}
                    >
                      <option value="1">1 px</option>
                      <option value="2">2 px</option>
                      <option value="3">3 px</option>
                      <option value="4">4 px</option>
                      <option value="5">5 px</option>
                      <option value="6">6 px</option>
                      <option value="7">7 px</option>
                      <option value="8">8 px</option>
                      <option value="9">9 px</option>
                      <option value="10">10 px</option>
                    </select>
                  </div>

                  {selectedEdge && (
                    <div className="inline-block h-full">
                      <CustomButton
                        text="Clear Formatting"
                        onClickBtn={() => onClearAllFormatting()}
                        type={"button"}
                        btnStyle="text-red-500 hover:border-[#2d61d2] hover:bg-white rounded-none rounded-r-[10px] h-[38px]"
                        buttonType="secondary"
                      />
                    </div>
                  )}
                </div>
              )}

              {/* {edgeStyles?.map((edgeStyle, index) => {
                return (
                  <button
                    key={index}
                    className={panelBtnStyle}
                    onClick={() => handleStyleChange(edgeStyle.style)}
                    title={edgeStyle.label}
                  >
                    <ArrowRightIcon className={panelIconStyle} />
                  </button>
                );
              })} */}
            </div>
          </Panel>

          {/* Dots color for the background of the flowchart */}
          <Background
            // color="#C0C0C0"
            color="#A9A9A9"
            gap={50}
            size={4}
            variant={BackgroundVariant.Dots}
          />
          <SelectedNodesToolbar />
          <HelperLines
            horizontal={helperLineHorizontal}
            vertical={helperLineVertical}
          />
        </ReactFlow>
      </div>

      <CustomDrawer
        open={openNodeSetting}
        onCloseModal={onCloseNodeEditSetting}
        title="Node Setting"
        description="Here is your node setting"
      >
        <div className="h-screen bg-white p-6">
          <h3 className="font-semibold text-customDarkBlue">
            {selectedNodeSetting?.data?.label || ""}
          </h3>
        </div>
      </CustomDrawer>
    </div>
  );
});

const Flow = memo(function Flow({ diagram }: FlowChartProps) {
  return <DynamicGrouping diagram={diagram} />;
});

export default Flow;
