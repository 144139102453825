import { DetailsContext, TabType } from './context';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  createTemplateDocument,
  deleteTemplateDocument,
  getTemplateById,
  updateTemplateDocument,
} from 'src/redux/templates/templatesApi';
import { setTemplateChangedDocument, setTemplateSelectedDocument } from 'src/redux/templates/templatesSlice';
import { useMultiSelectedOption } from 'src/hooks/useMultiSelectedOption';

const SUPPORTED_TABS: TabType[] = ['documents'];

function TemplateDetailsProvider({ templateId, children }: PropsWithChildren<{ templateId: string; }>) {
  const editorRef = useRef<any | null>(null);
  const [hasTextEditor, setHasTextEditor] = useState(false);
  const [templateByIdLoader, setTemplateByIdLoader] = useState(false);

  const {
    templateByIdRes: template,
    templateSelectedDocument: selectedDocument,
    deleteTemplateDocumentRes: deleteRes,
  } = useAppSelector((state) => state.templates);
  const allDocuments = template?.document_templates ?? [];

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const [multiSelectedOption, switchMultiSelectedOption] = useMultiSelectedOption(SUPPORTED_TABS);

  useEffect(() => {
    const fetchData = async () => {
      setTemplateByIdLoader(true);

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token found');
        }

        await dispatch(
          getTemplateById({
            accessToken,
            id: templateId,
          })
        );
      } catch (error) {
        console.error('Error getting project template:', error);
      } finally {
        setTemplateByIdLoader(false);
      }
    };

    const isTemplateLoaded = template?.project_template?.id === templateId;

    if (!isTemplateLoaded && !templateByIdLoader) {
      fetchData();
    }
  }, [getAccessTokenSilently, dispatch, templateId, template, templateByIdLoader]);

  const selectDocument = (document: any) => {
    dispatch(setTemplateSelectedDocument(document));
  };

  const createDocument = async (body: any) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token found');
      }

      await dispatch(
        createTemplateDocument({
          body,
          accessToken,
          template_id: templateId,
        })
      );
    } catch (error) {
      console.error('Error creating document: ', error);

      throw error;
    }
  };

  const updateDocument = async (body: any, selectedDocument: any) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token found');
      }

      await dispatch(
        updateTemplateDocument({
          body,
          accessToken,
          template_id: templateId,
          document_id: selectedDocument?.id || body?.id,
        })
      );
    } catch (error) {
      console.error('Error updating document: ', error);

      throw error;
    }
  };

  const deleteDocument = async (selectedDocument: any) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token found');
      }

      await dispatch(
        deleteTemplateDocument({
          accessToken,
          template_id: templateId,
          document_id: selectedDocument?.id,
        })
      );
    } catch (error) {
      console.error('Error deleting document:', error);

      throw error;
    }
  };

  const setDocumentHtml = (documentValue: string) => {
    dispatch(setTemplateChangedDocument(documentValue));
  };

  const setEditorRef = (editor: any | null) => {
    editorRef.current = editor;
    setHasTextEditor(!!editor);
  };

  return (
    <DetailsContext.Provider
      value={{
        type: 'template',
        tabs: SUPPORTED_TABS,
        loader: templateByIdLoader,
        template,
        allDocuments,
        selectedDocument,
        deleteRes,
        autosave: false,
        multiSelectedOption,
        editorRef,
        hasTextEditor,
        selectDocument,
        createDocument,
        updateDocument,
        deleteDocument,
        toggleAutoSave: async () => {},
        setDocumentHtml,
        switchMultiSelectedOption,
        setEditorRef,
      }}
    >
      {children}
    </DetailsContext.Provider>
  );
}

export default TemplateDetailsProvider;
