import "./style.css";

import {
  MutableRefObject,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  createConversation,
  getAllMessages,
  promptStreamMessage,
} from "../../../../redux/chatGPT/chatGPTApi";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import {
  PaintBrushIcon,
  Cog6ToothIcon,
  ChartBarIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import {
  classNames,
  timeAgoOrFormattedDate,
  copyToClipboard,
  getLastIdFromUrl,
} from "../../../../utils/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import { useResizeObserver } from "src/hooks/useResizeObserver";

import ChatGPTInput, {
  type InputRef,
} from "../../../../components/chatGPTInput";
import WrapperLoader from "src/components/wrapperLoader";
import MarkdownRenderer from "src/components/formatter/markdown";
import AdvancedCopyAndPaste, {
  OptionName,
} from "src/components/advancedCopyAndPaste";
import { ConversationContext, SelectedConversation } from "src/type";
import { useFetch } from "src/hooks/useFetch";
import UserMessage from "./userMessage";
import { useMessageStreamer } from "../../../../hooks/useMessageStreamer";
import {
  ArrowUpIcon as AddToTopIcon,
  ArrowDownIcon as AddToBottomIcon,
  CursorArrowRippleIcon as InsertAtCursorIcon,
  ClipboardIcon as CopyIcon,
  ChatBubbleLeftRightIcon as AddToChatIcon,
} from "@heroicons/react/24/outline";
import { DetailsContext } from "src/contexts/details/context";

import illustrationSrc from "../../../../assets/images/mascot.svg";
import linkedInIcon from "../../../../assets/images/linkedin-logo.png";

interface AiMessageMarkdownProps {
  content?: string;
  addItemToTextEditor: Function;
  markdownElRef?: MutableRefObject<HTMLDivElement | null>;
  isStreamTarget: boolean;
}

interface ChatGPTProps {
  addItemToTextEditor: Function;
  selectedConversation: SelectedConversation;
  setSelectedConversation: (conversation: SelectedConversation | null) => void;
}

interface Suggestion {
  icon: "design" | "operate" | "optimize" | "troubleshoot";
  title: string;
  description: string;
}

interface ChatSuggestionsProps {
  onSuggestionSelected: (description: string) => void;
}

interface ConversationHeaderProps {
  chatTitle: string;
  className?: string;
  style?: React.CSSProperties;
  onSuggestionSelected: (suggestion: string) => void;
}

const ChatSuggestions: React.FC<ChatSuggestionsProps> = ({
  onSuggestionSelected,
}) => {
  const suggestions: Suggestion[] = [
    {
      icon: "design",
      title: "Executive Summary",
      description:
        "Generate an executive summary document for IT Solution Design.",
    },
    {
      icon: "operate",
      title: "Requirements",
      description:
        "Write a list of requirements for IT Solution Design, during a discovery call with the client.",
    },
    {
      icon: "optimize",
      title: "Solution Design",
      description: "How should I approach documenting design for IT Solution?",
    },
    {
      icon: "troubleshoot",
      title: "Plan and Strategize",
      description:
        "What kind of documentation should my cloud solution design project include?",
    },
  ];

  return (
    <div className="bg-white p-4 rounded-lg shadow-[0_0_2px_rgba(0,0,0,0.12),_0_2px_4px_rgba(0,0,0,0.14)]">
      <p className="mb-2 text-sm text-customDarkBlue">
        You can use prompt examples below as a starting point or click the link
        to explore more options for optimal results. Feel free to reach out to
        me directly on LinkedIn if you need further assistance or visit our
        page.
      </p>
      <div className="flex gap-4 mb-4">
        <a
          href="https://solutionpilot.ai/templates"
          className="text-blue-600 text-sm hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
        <a
          href="https://www.linkedin.com/in/sebastiancwiek"
          className="text-blue-600 text-sm hover:underline flex items-center gap-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedInIcon} alt="LinkedIn" className="w-4 h-4" />
          Sebastian Cwiek
        </a>
        <a
          href="https://www.linkedin.com/company/solution-pilot"
          className="text-blue-600 text-sm hover:underline flex items-center gap-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedInIcon} alt="LinkedIn" className="w-4 h-4" />
          Solution Pilot
        </a>
      </div>
      <br />
      <p className="mb-2 text-sm font-medium text-customDarkBlue">
        Select one of the suggestions below to get started.
      </p>
      <div className="space-y-2">
        {suggestions.map(({ icon, title, description }, index) => (
          <button
            key={index}
            type="button"
            className="p-[2px] bg-gradient-to-r hover:from-blue-400 hover:via-purple-300 hover:to-purple-500 rounded-lg transition duration-300 ease-in-out w-full"
            onClick={() => onSuggestionSelected(description)}
          >
            <div className="w-full text-left p-3 bg-white rounded-md border border-gray-200 group">
              <div className="flex items-start">
                <span className="mr-3">
                  {icon === "design" && (
                    <PaintBrushIcon className="w-5 h-5 text-gray-400 group-hover:text-[#2D61D2] transition-colors" />
                  )}
                  {icon === "operate" && (
                    <Cog6ToothIcon className="w-5 h-5 text-gray-400 group-hover:text-[#2D61D2] transition-colors" />
                  )}
                  {icon === "optimize" && (
                    <ChartBarIcon className="w-5 h-5 text-gray-400 group-hover:text-[#2D61D2] transition-colors" />
                  )}
                  {icon === "troubleshoot" && (
                    <WrenchIcon className="w-5 h-5 text-gray-400 group-hover:text-[#2D61D2] transition-colors" />
                  )}
                </span>
                <div>
                  <h3 className="text-sm font-medium text-gray-900 group-hover:text-[#2D61D2] transition-colors">
                    {title}
                  </h3>
                  <p className="text-sm text-gray-500">{description}</p>
                </div>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

const ConversationHeader: React.FC<ConversationHeaderProps> = ({
  chatTitle,
  className,
  style,
  onSuggestionSelected,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center gap-6",
        className
      )}
      style={style}
    >
      <div className="flex flex-col items-center">
        <img
          src={illustrationSrc}
          alt="Logo"
          aria-hidden
          width={140}
          height={96}
        />
        <h3 className="mt-3 text-base font-semibold text-center text-customDarkBlue">
          {chatTitle}
        </h3>
        <p className="mt-2 text-sm text-gray-500 text-center">
          Assistant can make mistakes, always review the accuracy of responses.
          Use it wisely but remember you are the brain behind the wheel.
        </p>
      </div>
      <ChatSuggestions onSuggestionSelected={onSuggestionSelected} />
    </div>
  );
};

function AiMessageMarkdown({
  content: rootContent,
  addItemToTextEditor,
  markdownElRef,
  isStreamTarget,
}: Readonly<AiMessageMarkdownProps>) {
  const [content, setContent] = useState(rootContent);

  useEffect(() => {
    setContent(rootContent);
  }, [rootContent]);

  useEffect(() => {
    if (!isStreamTarget) return;

    const listener = (event: CustomEvent<string>) => {
      if (typeof event.detail === "string") {
        setContent(event.detail);
      }
    };

    document.addEventListener("message-stream", listener as EventListener);

    return () => {
      document.removeEventListener("message-stream", listener as EventListener);
    };
  }, [isStreamTarget]);

  const isLoading = content === "q_loading";

  return (
    <AdvancedCopyAndPaste.Selectable
      tag="div"
      className="text-sm leading-6 text-gray-600 py-2 messages-response break-words max-w-full overflow-hidden"
    >
      {isLoading ? (
        <div className="flex space-x-2 items-center">
          <span className="sr-only">Loading...</span>
          <div className="h-2 w-2 bg-blue-300 rounded-full animate-bounce [animation-delay:-0.2s]"></div>
          <div className="h-2 w-2 bg-blue-300 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-2 w-2 bg-blue-300 rounded-full animate-bounce"></div>
        </div>
      ) : (
        <div ref={markdownElRef}>
          <MarkdownRenderer
            content={content?.replaceAll("&nbsp;", " ")}
            addItemToTextEditor={addItemToTextEditor}
          />
        </div>
      )}
    </AdvancedCopyAndPaste.Selectable>
  );
}

function Message({
  data,
  addItemToTextEditor,
  inputRef,
  isStreamTarget,
}: Readonly<{
  data: any;
  addItemToTextEditor: Function;
  inputRef: MutableRefObject<InputRef | null>;
  isStreamTarget: boolean;
}>) {
  const markdownElRef = useRef<HTMLDivElement | null>(null);

  const { user } = useAuth0();
  const { profileData } = useAppSelector<any>((state: any) => state.profile);
  const { hasTextEditor, editorRef } = useContext(DetailsContext);

  const copyOptions = useMemo(() => {
    if (data.role !== "assistant") return null;

    const options = [
      {
        name: OptionName.COPY,
        icon: CopyIcon,
        label: "Copy",
      },
      {
        name: OptionName.ADD_TO_CHAT,
        icon: AddToChatIcon,
        label: "Add to Chat",
      },
    ];

    if (hasTextEditor) {
      options.unshift(
        {
          name: OptionName.ADD_TO_TOP,
          icon: AddToTopIcon,
          label: "Add to Top",
        },
        {
          name: OptionName.ADD_TO_BOTTOM,
          icon: AddToBottomIcon,
          label: "Add to Bottom",
        },
        {
          name: OptionName.INSERT_AT_CURSOR,
          icon: InsertAtCursorIcon,
          label: "Insert at Cursor",
        }
      );
    }

    return options;
  }, [data.role, hasTextEditor]);

  const handleCopyOption = async (option: OptionName) => {
    switch (option) {
      case OptionName.COPY:
        if (markdownElRef.current?.textContent) {
          await copyToClipboard(markdownElRef.current.textContent);
        }

        break;
      case OptionName.ADD_TO_CHAT:
        if (markdownElRef.current?.textContent) {
          inputRef.current?.write(markdownElRef.current.textContent);
          inputRef.current?.moveCursorToEnd();
        }

        break;
      case OptionName.ADD_TO_TOP:
        editorRef.current?.execute("pasteAt:afterTheHeading", {
          content: markdownElRef.current?.innerHTML,
        });
        break;
      case OptionName.ADD_TO_BOTTOM:
        editorRef.current?.execute("pasteAt:theBottom", {
          content: markdownElRef.current?.innerHTML,
        });
        break;
      case OptionName.INSERT_AT_CURSOR:
        editorRef.current?.execute("pasteAt:cursor", {
          content: markdownElRef.current?.innerHTML,
        });
        break;
    }
  };

  return (
    <div
      className={classNames(
        `relative flex gap-x-4 rounded-lg max-w-full overflow-auto`,
        data.role === "user" ? "w-fit self-end bg-[#ebf3fc]" : "w-full"
      )}
      style={{
        boxShadow: "0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)",
      }}
    >
      <div className={classNames("w-full flex items-start py-2 px-4")}>
        <div className="flex-grow" style={{ width: "calc(100% - 40px)" }}>
          <div
            className={classNames(
              "mb-2 py-2 border-b flex items-center",
              data.role === "user" ? "border-[#9B59B6]" : "border-[#13B48B]"
            )}
          >
            <div className="mr-2">
              <img
                src={`${
                  data.role === "user"
                    ? user?.picture
                    : "https://static.vecteezy.com/system/resources/previews/021/059/825/original/chatgpt-logo-chat-gpt-icon-on-green-background-free-vector.jpg"
                }`}
                alt=""
                className="relative h-6 w-6 flex-none rounded-full bg-gray-50"
              />
            </div>
            <div className="mr-4 py-0.5 text-sm text-customLightBlue font-semibold">
              {data.role === "user"
                ? profileData?.first_name
                  ? `${profileData?.first_name} ${profileData?.last_name}`
                  : profileData?.email
                : "Chat GPT"}
            </div>
            {/* <time
              dateTime='2023-01-24T09:20'
              className='ml-auto min-w-20 py-0.5 text-right flex-none text-[12px]/[1] text-gray-500 italic'
            >
              {timeAgoOrFormattedDate(data?.created_at)}
            </time> */}
            {copyOptions && (
              <div className="ml-auto flex items-center justify-end p-1 rounded-[7px]">
                {copyOptions.map(({ name, icon: Icon, label }) => (
                  <button
                    key={name}
                    type="button"
                    className="cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]"
                    title={label}
                    onClick={() => handleCopyOption(name)}
                  >
                    <Icon className="h-[16px] text-[#6f717c]" />
                  </button>
                ))}
              </div>
            )}
          </div>

          {data.role === "user" ? (
            <UserMessage content={data.content} context={data.special_type} />
          ) : (
            <AiMessageMarkdown
              content={data?.content}
              addItemToTextEditor={addItemToTextEditor}
              markdownElRef={markdownElRef}
              isStreamTarget={isStreamTarget}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function useAutoScroll(
  distanceFromBottomRef: MutableRefObject<number>,
  flushChunks: Function
) {
  const isAtBottomRef = useRef(true);
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);

  const messageRefs = useRef<{
    threshold: number;
    lastUserMessage: HTMLLIElement | null;
    lastAssistantMessage: HTMLLIElement | null;
  }>({
    threshold: 0,
    lastUserMessage: null,
    lastAssistantMessage: null,
  });

  const scrollToBottom = useCallback(() => {
    scrollableContainerRef.current?.scrollTo({
      top: scrollableContainerRef.current.scrollHeight,
    });
  }, []);

  const setListRef = useResizeObserver(
    useCallback(() => {
      const {
        current: { threshold, lastUserMessage, lastAssistantMessage },
      } = messageRefs;

      const containerHeight =
        (scrollableContainerRef.current?.clientHeight ?? 0) -
        distanceFromBottomRef.current;
      const lastUserMessageHeight = lastUserMessage?.offsetHeight ?? 0;
      const lastAssistantMessageHeight =
        lastAssistantMessage?.offsetHeight ?? 0;

      const lastMessagesHeight =
        lastUserMessageHeight + lastAssistantMessageHeight;

      if (!isAtBottomRef.current) {
        flushChunks();
      }

      if (lastMessagesHeight - threshold > containerHeight) {
        if (isAtBottomRef.current) {
          flushChunks();
          isAtBottomRef.current = false;
        }

        messageRefs.current.threshold = lastMessagesHeight;
      } else if (isAtBottomRef.current) {
        scrollToBottom();
      }
    }, [distanceFromBottomRef, flushChunks, scrollToBottom])
  );

  const handleScroll = useCallback(() => {
    if (scrollableContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollableContainerRef.current;
      isAtBottomRef.current = scrollHeight - scrollTop <= clientHeight + 5;
    }
  }, []);

  const setUserMessageRef = useCallback((node: HTMLLIElement | null) => {
    if (node && node !== messageRefs.current.lastUserMessage) {
      messageRefs.current.threshold = 0;
    }

    messageRefs.current.lastUserMessage = node;
  }, []);

  const setAssistantMessageRef = useCallback((node: HTMLLIElement | null) => {
    if (node && node !== messageRefs.current.lastAssistantMessage) {
      messageRefs.current.threshold = 0;
    }

    messageRefs.current.lastAssistantMessage = node;
  }, []);

  const setScrollableContainerRef = useCallback(
    (node: HTMLDivElement | null) => {
      scrollableContainerRef.current = node;
    },
    []
  );

  return {
    refs: {
      setScrollableContainer: setScrollableContainerRef,
      setList: setListRef,
      setUserMessage: setUserMessageRef,
      setAssistantMessage: setAssistantMessageRef,
    },
    onScroll: handleScroll,
    scrollToBottom,
    isAtBottomRef,
  };
}

const ChatGPT = ({
  addItemToTextEditor,
  selectedConversation,
  setSelectedConversation,
}: ChatGPTProps) => {
  const [messages, setMessages] = useState<any>([]);
  const [messageLoader, setMessageLoader] = useState(false);
  const [allMessagesLoader, setAllMessagesLoader] = useState(false);

  const distanceFromBottomRef = useRef(129);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<InputRef | null>(null);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { messageStream, flushChunks, stopStream } = useMessageStreamer();

  const { refs, onScroll, scrollToBottom, isAtBottomRef } = useAutoScroll(
    distanceFromBottomRef,
    flushChunks
  );

  // const scrollToBottom = useCallback(({ behavior = 'auto', force = false }: ScrollToBottomOptions = {}) => {
  //   const { current: messagesContainer } = messagesContainerRef;

  //   if (!messagesContainer) return;

  //   if (force || isAtBottomRef.current) {
  //     messagesContainer.scrollTo({ top: messagesContainer.scrollHeight, behavior });
  //   }
  // }, []);

  const observedElementRef = useResizeObserver(
    ([
      {
        contentRect: { height: inputHeight },
      },
    ]) => {
      const prevDistanceFromBottom = distanceFromBottomRef.current;
      distanceFromBottomRef.current = inputHeight + 16 * 2;

      messagesContainerRef.current?.style.setProperty(
        "padding-bottom",
        `${distanceFromBottomRef.current}px`
      );

      if (isAtBottomRef.current && messages.length > 0) {
        messagesContainerRef.current?.scrollTo({
          top: messagesContainerRef.current?.scrollHeight,
        });
      } else {
        messagesContainerRef.current?.scrollBy({
          top: distanceFromBottomRef.current - prevDistanceFromBottom,
        });
      }
    }
  );

  useEffect(() => {
    const fetchMessages = async () => {
      setMessages([]);
      setAllMessagesLoader(true);

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error("No access token");
        }

        if (!selectedConversation) {
          throw new Error("No selected conversation");
        }

        const res = await dispatch(
          getAllMessages({
            accessToken,
            project_id: selectedConversation.project_id,
            conversation_id: selectedConversation.id,
          })
        );

        const messages = res?.payload?.data;

        setMessages(Array.isArray(messages) ? messages : []);
      } catch (error) {
        console.error("Error getting access token:", error);
      } finally {
        setAllMessagesLoader(false);
        scrollToBottom();
      }
    };

    // no history for special chat
    if (!("context" in selectedConversation)) {
      fetchMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversation]);

  const sendMessage = useCallback(
    async (suggestionMessage?: string, context?: ConversationContext) => {
      const { current: input } = inputRef;
      const message = suggestionMessage || input?.textareaEl?.value.trim();

      if (!message) return;

      // scrollToBottom({ force: true, behavior: 'auto' });
      setMessageLoader(true);
      setMessages((prev: any) => [
        ...(prev || []),
        {
          content: message,
          created_at: new Date(),
          role: "user",
          special_type: context,
        },
        {
          content: "q_loading",
          created_at: new Date(),
          role: "assistant",
        },
      ]);

      if (!suggestionMessage) {
        input?.clear();
      }

      const getConversationId = async (accessToken: string) => {
        if (selectedConversation.id) return selectedConversation.id;

        // If not active conversation, create a new one on the fly
        const res = await dispatch(
          createConversation({
            body: {},
            accessToken,
            project_id: getLastIdFromUrl(window.location.pathname),
            conversation_configuration_id:
              selectedConversation.conversation_configuration_id,
            template_type: "community",
          })
        );

        const conversationData = res?.payload?.data;

        if (!conversationData) return null;

        setSelectedConversation({
          ...selectedConversation,
          ...conversationData,
        });

        return conversationData.id;
      };

      let lastMessageContent = "";

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error("No access token");
        }

        const conversationId = await getConversationId(accessToken);

        if (!conversationId) {
          throw new Error("No conversation id");
        }

        // POST to start the generation
        await dispatch(
          promptStreamMessage({
            body: {
              messages: [
                {
                  role: "user",
                  content: message,
                  special_type: context,
                },
              ],
            },
            accessToken,
            project_id: selectedConversation?.project_id,
            conversation_id: conversationId,
          })
        );

        for await (const chunk of messageStream(conversationId)) {
          lastMessageContent += chunk.replaceAll("&nbsp;", " ");
          document.dispatchEvent(
            new CustomEvent("message-stream", { detail: lastMessageContent })
          );
        }
      } catch (error) {
        console.error("Error sending message: ", error);
      } finally {
        setMessages((prev: any) => {
          const lastMessageIndex = prev.length - 1;
          const updatedMessages = [...prev];
          updatedMessages[lastMessageIndex] = {
            ...updatedMessages[lastMessageIndex],
            content: lastMessageContent,
          };

          return updatedMessages;
        });

        setMessageLoader(false);
      }
    },
    [
      dispatch,
      getAccessTokenSilently,
      selectedConversation,
      setSelectedConversation,
      messageStream,
    ]
  );

  useFetch({
    dependencies: { sendMessage, messageLoader },
    triggers: [selectedConversation],
    callback: ({ sendMessage, messageLoader }) => {
      if (selectedConversation.id || messageLoader) return;

      if ("context" in selectedConversation) {
        setMessages([]);
        sendMessage(
          selectedConversation.rootMessage,
          selectedConversation.context
        );
      }
    },
  });

  return (
    <WrapperLoader loading={allMessagesLoader}>
      <div className="relative h-full bg-white">
        <div
          ref={(node) => {
            messagesContainerRef.current = node;
            refs.setScrollableContainer(node);
          }}
          onScroll={onScroll}
          className="h-full px-3 overflow-y-auto overflow-x-hidden"
          style={{
            height: "calc(100vh - 100px)",
            paddingBottom: `${distanceFromBottomRef.current}px`,
          }}
        >
          <div className="w-full min-h-full max-w-2xl mx-auto">
            <ConversationHeader
              chatTitle={selectedConversation?.title || ""}
              onSuggestionSelected={(message) => sendMessage(message)}
              className="mt-8"
            />
          </div>

          {messages.length > 0 && (
            <AdvancedCopyAndPaste inputRef={inputRef}>
              <ul ref={refs.setList} className="pt-4 flex flex-col gap-y-5">
                {messages?.map((data: any, dataIdx: number) => (
                  <li
                    key={dataIdx}
                    ref={(node) => {
                      if (
                        data.role === "user" &&
                        dataIdx === messages.length - 2
                      ) {
                        refs.setUserMessage(node);
                      } else if (
                        data.role === "assistant" &&
                        dataIdx === messages.length - 1
                      ) {
                        refs.setAssistantMessage(node);
                      }
                    }}
                    id={`${messages?.length === dataIdx + 1 ? "show-first" : "dont-show"}`}
                  >
                    <Message
                      data={data}
                      addItemToTextEditor={addItemToTextEditor}
                      inputRef={inputRef}
                      isStreamTarget={
                        dataIdx === messages.length - 1 &&
                        data.role === "assistant" &&
                        messageLoader
                      }
                    />
                  </li>
                ))}
              </ul>
            </AdvancedCopyAndPaste>
          )}
        </div>

        <div className="absolute bottom-0 left-0 right-[7px] bg-white flex items-center">
          <div ref={observedElementRef} className="chat-form w-full pb-4 px-3">
            <ChatGPTInput
              inputRef={inputRef}
              onSubmit={sendMessage}
              messageLoader={messageLoader}
              sendMessage={sendMessage}
              onStop={stopStream}
            />
          </div>
        </div>
      </div>
    </WrapperLoader>
  );
};

export default memo(ChatGPT);
