import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CustomModal from "../../../components/customModal";
import CriteriaForm from "./criteriaForm";
import OptionForm from "./optionForm";
import {
  // Criterias
  getDecisionMatrixCriteriasList,
  createDecisionMatrixCriteria,
  updateDecisionMatrixCriteria,
  deleteDecisionMatrixCriteria,

  // Options
  getDecisionMatrixOptionsList,
  createDecisionMatrixOption,
  updateDecisionMatrixOption,
  deleteDecisionMatrixOption,

  // Options Ratings
  updateOptionRating,
} from "../../../redux/decisionMatrixAnalysis/decisionMatrixAnalysisApi";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getLastIdFromUrl } from "../../../utils/utilities";
import RoundButton from "src/components/roundButton";
import LabelWithActions from "src/components/labelWithActions";
import ConfirmationModal from "../../../components/confirmationModal";

const optionsList = [
  { label: "(0) Average", value: 0 },
  { label: "(1) Better Than Average", value: 1 },
  { label: "(-1) Worse Than Average", value: -1 },
];

interface AnalysisProps {
  detailsData: any;
}

export default function Analysis({ detailsData }: AnalysisProps) {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [formType, setFormType] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [confirmationLoader, setConfirmationLoader] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { decisionMatrixCriteriasList, decisionMatrixOptionsList } =
    useAppSelector((state) => state.decisionMatrixAnalysis);

  const { selectedDecisionMatrix } = useAppSelector(
    (state) => state.decisionMatrixs
  );

  useEffect(() => {
    if (selectedDecisionMatrix?.id) {
      const fetchData = async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken) {
            await dispatch(
              getDecisionMatrixCriteriasList({
                accessToken,
                matrix_id: selectedDecisionMatrix?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );

            await dispatch(
              getDecisionMatrixOptionsList({
                accessToken,
                matrix_id: selectedDecisionMatrix?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );
          }
        } catch (error) {
          console.error("Error getting access token:", error);
        }
      };

      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDecisionMatrix]);

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedData(null);
  };

  const onOpenModal = (type: string) => {
    setFormType(type);
    setOpenFormModal(true);
  };

  const onOpenEditModal = (data: any, type: string) => {
    setFormType(type);
    setSelectedData(data);
    setOpenFormModal(true);
  };

  const updatingRequest = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          if (formType === "criteria") {
            await dispatch(
              updateDecisionMatrixCriteria({
                body,
                accessToken,
                matrix_id: selectedData?.matrix_id,
                criteria_id: selectedData?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );
            // await dispatch(
            //   getDecisionMatrixCriteriasList({
            //     accessToken,
            //     matrix_id: detailsData?.id,
            //   })
            // );
          }

          if (formType === "option") {
            await dispatch(
              updateDecisionMatrixOption({
                body,
                accessToken,
                option_id: selectedData?.id,
                matrix_id: detailsData?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );

            // await dispatch(
            //   getDecisionMatrixOptionsList({
            //     accessToken,
            //     matrix_id: detailsData?.id,
            //   })
            // );
          }

          onCloseModal();
          setFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingRequest = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          if (formType === "criteria") {
            await dispatch(
              createDecisionMatrixCriteria({
                body,
                accessToken,
                matrix_id: detailsData?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );

            // await dispatch(
            //   getDecisionMatrixCriteriasList({
            //     accessToken,
            //     matrix_id: detailsData?.id,
            //   })
            // );
          }

          if (formType === "option") {
            await dispatch(
              createDecisionMatrixOption({
                body,
                accessToken,
                matrix_id: detailsData?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );

            // await dispatch(
            //   getDecisionMatrixOptionsList({
            //     accessToken,
            //     matrix_id: detailsData?.id,
            //   })
            // );
          }

          onCloseModal();
          setFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const deletingRequest = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          if (formType === "criteria") {
            await dispatch(
              deleteDecisionMatrixCriteria({
                accessToken,
                matrix_id: selectedData?.matrix_id,
                criteria_id: selectedData?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );
          }

          if (formType === "option") {
            await dispatch(
              deleteDecisionMatrixOption({
                accessToken,
                option_id: selectedData?.id,
                matrix_id: detailsData?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );
          }

          deletingModalClose();
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setSelectedData(null);
      }
    };

    fetchData();
  };

  const updatingUserRatingRequest = (
    value: string,
    optionId: string,
    criteriaId: string
  ) => {
    const fetchData = async () => {
      const payload = {
        concept_id: optionId,
        user_rating: parseInt(value),
      };

      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateOptionRating({
              body: payload,
              accessToken,
              matrix_id: detailsData?.id,
              criteria_id: criteriaId,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          setFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitForm = async (body: any) => {
    if (selectedData) {
      updatingRequest(body);
    } else {
      creatingRequest(body);
    }
  };

  const deletingModalOpen = (data: any, type: string) => {
    setSelectedData(data);
    setFormType(type);
    setDeleteConfirm(true);
  };

  const deletingModalClose = () => {
    setConfirmationLoader(false);
    setDeleteConfirm(false);
    setSelectedData(null);
    setFormType("");
  };

  const onChangeOptionRating = (
    e: any,
    optionId: string,
    criteriaId: string
  ) => {
    const value = e.target.value;
    updatingUserRatingRequest(value, optionId, criteriaId);
  };

  const gettingOptionValue = (option_concepts: any, optionId: string) => {
    if (option_concepts?.length > 0) {
      const findObject = option_concepts.find(
        (data: any) => data.id === optionId
      );

      return `${findObject?.user_rating}` || "null";
    }
  };

  const calculatingEachOption = (conceptId: string) => {
    if (decisionMatrixCriteriasList?.length > 0) {
      let countOption = 0;

      decisionMatrixCriteriasList.forEach((data) => {
        if (data?.concepts?.length > 0) {
          data?.concepts.forEach(
            (concept: { user_rating: number; id: string }) => {
              if (concept.id === conceptId) {
                countOption =
                  countOption +
                  data.criteria.criteria_multiplier * concept.user_rating;
              }
            }
          );
        }
      });

      return countOption;
    }
  };

  return (
    <>
      <div className="-my-6">
        <table className=" divide-y divide-gray-200 w-full">
          <thead>
            <tr className="divide-x divide-gray-200">
              <th
                scope="col"
                className="py-3.5 pl-4 pr-4 text-left text-xs font-semibold text-customDarkBlue"
              ></th>
              <th
                scope="col"
                colSpan={3}
                className="px-4 py-3.5 text-xs font-semibold text-customDarkBlue text-center"
              >
                <div className="flex items-center justify-center">
                  <div className="mr-3">
                    <RoundButton onClickBtn={() => onOpenModal("option")} />
                  </div>
                  <span>Add an option</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200 hover:bg-zinc-100">
              <td
                style={{
                  width:
                    decisionMatrixOptionsList?.length > 0 ? "auto" : "200px",
                }}
                className="p-4 text-xs font-medium text-customDarkBlue flex"
              >
                <RoundButton onClickBtn={() => onOpenModal("criteria")} />
                <span className="ml-2">Criteria</span>
              </td>

              {decisionMatrixOptionsList?.length > 0 ? (
                decisionMatrixOptionsList.map((option) => {
                  return (
                    <td
                      key={option.id}
                      className="p-4 text-xs bg-gray-100 font-semibold text-customDarkBlue w-auto text-center"
                    >
                      <LabelWithActions
                        label={option.title}
                        onEdit={() => onOpenEditModal(option, "option")}
                        onDelete={() => deletingModalOpen(option, "option")}
                      />
                    </td>
                  );
                })
              ) : (
                <td className="p-4 bg-gray-100 w-full" />
              )}
            </tr>

            {decisionMatrixCriteriasList?.length > 0 &&
              decisionMatrixCriteriasList?.map((criteria) => {
                return (
                  <tr
                    key={criteria?.criteria?.id}
                    className="divide-x divide-gray-200"
                  >
                    <td className="p-4 text-xs font-medium text-gray-700 w-60">
                      <LabelWithActions
                        label={
                          <div>
                            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mr-2">
                              {criteria?.criteria?.criteria_multiplier}
                            </span>
                            {criteria?.criteria?.title}
                          </div>
                        }
                        onEdit={() =>
                          onOpenEditModal(criteria?.criteria, "criteria")
                        }
                        onDelete={() =>
                          deletingModalOpen(criteria?.criteria, "criteria")
                        }
                      />
                    </td>

                    {decisionMatrixOptionsList?.length > 0 &&
                      decisionMatrixOptionsList.map((option) => {
                        return (
                          <td
                            key={option.id}
                            className="p-4 text-xs text-customDarkBlue w-auto text-center"
                          >
                            <select
                              value={gettingOptionValue(
                                criteria?.concepts,
                                option.id
                              )}
                              id="category"
                              className="block w-full rounded-md border-0 py-1.5 text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-xs sm:leading-6"
                              onChange={(e) =>
                                onChangeOptionRating(
                                  e,
                                  option?.id,
                                  criteria?.criteria?.id
                                )
                              }
                            >
                              <option
                                value="null"
                                style={{
                                  background: "#d4d4d4",
                                  color: "#737373",
                                }}
                              >
                                Please select
                              </option>

                              {optionsList?.map((optItem, index) => {
                                return (
                                  <option key={index} value={optItem.value}>
                                    {optItem.label}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                        );
                      })}
                  </tr>
                );
              })}

            <tr
              className="divide-x divide-gray-200"
              style={{ height: "150px" }}
            >
              <td />
              {decisionMatrixOptionsList?.length > 0 &&
                decisionMatrixOptionsList.map((option) => {
                  return <td key={option.id} />;
                })}
            </tr>

            <tr className="divide-x divide-gray-200">
              <td className="p-4 text-xs font-medium text-customDarkBlue w-auto">
                Result
              </td>
              {decisionMatrixOptionsList?.length > 0 &&
                decisionMatrixOptionsList.map((option) => {
                  return (
                    <td
                      key={option.id}
                      className="p-4 text-xs text-customDarkBlue w-auto text-center font-bold"
                    >
                      {calculatingEachOption(option.id)}
                    </td>
                  );
                })}
            </tr>
          </tbody>
        </table>
      </div>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title={`${selectedData ? "Update" : "Add"} ${
          (formType === "criteria" && "Criteria") ||
          (formType === "option" && "Option") ||
          ""
        }`}
        size="max-w-lg"
      >
        {formType === "criteria" && (
          <CriteriaForm
            onCloseModal={() => onCloseModal()}
            loading={formLoader}
            onSubmitData={onSubmitForm}
            editData={selectedData}
          />
        )}

        {formType === "option" && (
          <OptionForm
            onCloseModal={() => onCloseModal()}
            loading={formLoader}
            onSubmitData={onSubmitForm}
            editData={selectedData}
          />
        )}
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingRequest}
        deleteLoader={confirmationLoader}
      />
    </>
  );
}
