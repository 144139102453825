import React, { memo } from 'react';
import { ShapeComponents, type ShapeComponentProps } from './types';

function Shape({ type, width, height, ...svgAttributes }: ShapeComponentProps) {
  const ShapeComponent = ShapeComponents[type];

  if (!ShapeComponent || !width || !height) {
    return null;
  }

  const strokeWidth = svgAttributes.strokeWidth ? +svgAttributes.strokeWidth : 0;

  // Calculate inner dimensions to prevent shape cutoff
  const innerWidth = width - 2 * strokeWidth;
  const innerHeight = height - 2 * strokeWidth;

  return (
    <svg width={width} height={height} className='shape-svg shadow-xl rounded-xl bg-white'>
      <g
        transform={`translate(${strokeWidth}, ${strokeWidth})`}
      >
        <ShapeComponent
          width={innerWidth}
          height={innerHeight}
          {...svgAttributes}
        />
      </g>
    </svg>
  );
}

export default memo(Shape);
