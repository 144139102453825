import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { useAppSelector, useAppDispatch } from "../../../hooks";
import CustomButton from "../../../components/customButton";
import { getProjectsList } from "../../../redux/projects/projectsApi";
import WrapperLoader from "../../../components/wrapperLoader";
import CustomDrawer from "../../../components/customDrawer";
import CreateProjectForm from "../../projects/CreationForm";
import { createProject } from "../../../redux/projects/projectsApi";

interface ProjectsSelectionProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
  templateType: string;
}

export default function ProjectsSelection({
  onCloseModal,
  loading,
  onSubmitData,
  templateType,
}: ProjectsSelectionProps) {
  const [selectProjectId, setSelectProjectId] = useState("");
  const [projectsListLoader, setProjectsListLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { projectsList } = useAppSelector((state) => state.projects);

  useEffect(() => {
    onGettingProjectsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGettingProjectsList = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setProjectsListLoader(true);

          await dispatch(getProjectsList(accessToken));

          setProjectsListLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    if (selectProjectId) {
      onSubmitData(selectProjectId, templateType);
    } else {
      setShowError(true);
    }
  };

  const creatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProject({ body, accessToken })).then((res) => {
            if (res) {
              setOpenProject(false);
              setFormLoader(false);
              onGettingProjectsList();
            }
          });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  return (
    <WrapperLoader loading={projectsListLoader}>
      <form className="flex h-full flex-col" onSubmit={onSubmitForm}>
        <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
          <div>
            <label
              htmlFor="projects-list"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Projects
            </label>
          </div>
          <div className="sm:col-span-2">
            {projectsList?.length > 0 ? (
              <select
                onChange={(e) => {
                  setSelectProjectId(e.target.value);
                  setShowError(false);
                }}
                className={`block w-full bg-gray-100 rounded-md py-1.5 text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-xs sm:leading-6 ${
                  showError ? "border border-red-600" : "border-0"
                } `}
              >
                <option selected disabled>
                  Select Project
                </option>
                {projectsList?.map((data: any, index: number) => (
                  <option key={index} value={data.id}>
                    {data.title}
                  </option>
                ))}
              </select>
            ) : (
              <CustomButton
                text="Create Project"
                type="button"
                onClickBtn={() => setOpenProject(true)}
                beforeIcon={<PlusIcon className="h-4 mr-1" />}
                buttonType="secondary"
              />
            )}
          </div>
        </div>

        {/* Action buttons */}
        <div className="w-full text-right mt-5">
          <CustomButton
            text="Cancel"
            type="button"
            onClickBtn={onCloseModal}
            btnStyle="mr-2"
            buttonType="secondary"
          />

          {projectsList?.length > 0 && (
            <CustomButton
              text="Save"
              type="submit"
              onClickBtn={() => console.log()}
              loading={loading}
              buttonType="primary"
            />
          )}
        </div>
      </form>

      <CustomDrawer
        open={openProject}
        onCloseModal={() => setOpenProject(false)}
        title="Create Project"
        description="Create new Project, provide Title, Status and Category. Title is mandatory field."
        distanced
      >
        <CreateProjectForm
          onCloseModal={() => setOpenProject(false)}
          editData={null}
          onSubmitForm={creatingProject}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer>
    </WrapperLoader>
  );
}
