import {
  type Node,
  type NodeOrigin,
  type Rect,
  Box,
  NodePositionChange,
  XYPosition,
} from "@xyflow/react";
import {
  boxToRect,
  getNodePositionWithOrigin,
  rectToBox,
} from "@xyflow/system";

import { azure_nodes, azure_edges_icons } from "./assets/azure/azure_nodes.js";
import { azure_groups } from "./assets/azure/azure_groups.js";

import { aws_nodes } from "./assets/aws/aws_nodes.js";
import { aws_groups } from "./assets/aws/aws_groups.js";

import { gcp_nodes } from "./assets/gcp/gcp_nodes.js";
// import { gcp_groups } from "./assets/gcp/gcp_groups.js";

import { kubernetes_nodes } from "./assets/kubernetes/kubernetes_nodes.js";
import { kubernetes_groups } from "./assets/kubernetes/kubernetes_groups.js";

import { devops_nodes } from "./assets/devops/devops_nodes.js";
// import { devops_groups } from "./assets/devops/devops_groups.js";

import { software_nodes } from "./assets/software/software_nodes.js";
// import { software_groups } from "./assets/software/software_groups.js";

import { vendor_nodes } from "./assets/vendor/vendor_nodes.js";
// import { vendor_groups } from "./assets/vendor/vendor_groups.js";

import { misc_nodes } from "./assets/misc/misc_nodes.js";

type GetHelperLinesResult = {
  horizontal?: number;
  vertical?: number;
  snapPosition: Partial<XYPosition>;
};

const iconBaseUrl = "https://spnodedata.blob.core.windows.net/nodes";

interface Item {
  id: number;
  name: string;
  icon: string;
}

interface Category {
  name: string;
  items: string[];
  categoryIcon?: string;
}

interface FinalGroupsArr {
  name: string;
  icon: string;
  style: any;
  subdirectory: string;
}

interface CategoryData {
  name: string;
  categoryIcon?: string;
  data: Item[];
}

export const sortNodes = (a: Node, b: Node): number => {
  if (a.type === b.type) {
    return 0;
  }
  return a.type === "group" && b.type !== "group" ? -1 : 1;
};

export const getId = (prefix = "node") => `${prefix}_${Math.random() * 10000}`;

export const getNodePositionInsideParent = (
  node: Partial<Node>,
  groupNode: Node
) => {
  const position = node.position ?? { x: 0, y: 0 };
  const nodeWidth = node.measured?.width ?? 0;
  const nodeHeight = node.measured?.height ?? 0;
  const groupWidth = groupNode.measured?.width ?? 0;
  const groupHeight = groupNode.measured?.height ?? 0;

  if (position.x < groupNode.position.x) {
    position.x = 0;
  } else if (position.x + nodeWidth > groupNode.position.x + groupWidth) {
    position.x = groupWidth - nodeWidth;
  } else {
    position.x = position.x - groupNode.position.x;
  }

  if (position.y < groupNode.position.y) {
    position.y = 0;
  } else if (position.y + nodeHeight > groupNode.position.y + groupHeight) {
    position.y = groupHeight - nodeHeight;
  } else {
    position.y = position.y - groupNode.position.y;
  }

  return position;
};

export const getBoundsOfBoxes = (box1: Box, box2: Box): Box => ({
  x: Math.min(box1.x, box2.x),
  y: Math.min(box1.y, box2.y),
  x2: Math.max(box1.x2, box2.x2),
  y2: Math.max(box1.y2, box2.y2),
});

export const getRelativeNodesBounds = (
  nodes: Node[],
  nodeOrigin: NodeOrigin = [0, 0]
): Rect => {
  if (nodes.length === 0) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }

  const box = nodes.reduce(
    (currBox, node) => {
      const { x, y } = getNodePositionWithOrigin(node, nodeOrigin);
      return getBoundsOfBoxes(
        currBox,
        rectToBox({
          x,
          y,
          width: node.width || 0,
          height: node.height || 0,
        })
      );
    },
    { x: Infinity, y: Infinity, x2: -Infinity, y2: -Infinity }
  );

  return boxToRect(box);
};

export const iconsList = (type: string): CategoryData[] => {
  const categoriesDataList: Category[] | undefined =
    type === "azure"
      ? azure_nodes
      : type === "aws"
        ? aws_nodes 
        : type === "gcp"
          ? gcp_nodes
        : type === "kubernetes"
          ? kubernetes_nodes
          : type === "devops"
            ? devops_nodes
            : type === "software"
              ? software_nodes
              : type === "vendor"
                ? vendor_nodes
                : type === "misc"
                  ? misc_nodes
                  : undefined;

  if (!categoriesDataList) {
    return [];
  }

  return categoriesDataList.map((category, categoryIndex) => ({
    name: category.name,
    categoryIcon: category?.categoryIcon,
    data: category.items.map((item, itemIndex) => ({
      id: categoryIndex * 100 + itemIndex + 1,
      name: item
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
      icon: `${iconBaseUrl}/${type}_clean/${category.name
        .toLowerCase()
        .replace(/\s+/g, "_")}/${item}.svg`,
    })),
  }));
};

export const iconsListForEdge = (type: string): CategoryData[] => {
  const categoriesDataList: Category[] | undefined =
    type === "azure"
      ? azure_edges_icons
      : type === "kubernetes"
        ? kubernetes_nodes
        : type === "devops"
          ? devops_nodes
          : type === "misc"
            ? misc_nodes
            : undefined;

  if (!categoriesDataList) {
    return [];
  }

  return categoriesDataList.map((category, categoryIndex) => ({
    name: category.name,
    data: category.items.map((item, itemIndex) => ({
      id: categoryIndex * 100 + itemIndex + 1,
      name: item
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
      icon: `${iconBaseUrl}/${type}_clean/${category.name
        .toLowerCase()
        .replace(/\s+/g, "_")}/${item}.svg`,
    })),
  }));
};

export const getNodeGroupsList = (type: string): FinalGroupsArr[] => {
  const categoriesDataList: FinalGroupsArr[] | undefined =
    type === "azure"
      ? azure_groups
      : type === "aws"
        ? aws_groups
        : // type === "gcp" ? gcp_groups :
          type === "kubernetes"
          ? kubernetes_groups
          : // type === "devops" ? devops_groups :
            // type === "software" ? software_groups :
            // type === "vendor" ? vendor_groups :
            undefined;

  if (!categoriesDataList) {
    return [];
  }

  return categoriesDataList.map((data, groupIndex) => ({
    id: groupIndex * 100 + 1,
    name: data.name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "),
    icon: `${iconBaseUrl}/${type}_clean/${data.subdirectory}/${data.icon}.svg`,
    style: data?.style,
    subdirectory: data.subdirectory,
  }));
};

// this utility function can be called with a position change (inside onNodesChange)
// it checks all other nodes and calculated the helper line positions and the position where the current node should snap to
export function getHelperLines(
  change: NodePositionChange,
  nodes: Node[],
  distance = 5
): GetHelperLinesResult {
  const defaultResult = {
    horizontal: undefined,
    vertical: undefined,
    snapPosition: { x: undefined, y: undefined },
  };
  const nodeA = nodes.find((node) => node.id === change.id);

  if (!nodeA || !change.position) {
    return defaultResult;
  }

  const nodeABounds = {
    left: change.position.x,
    right: change.position.x + (nodeA.measured?.width ?? 0),
    top: change.position.y,
    bottom: change.position.y + (nodeA.measured?.height ?? 0),
    width: nodeA.measured?.width ?? 0,
    height: nodeA.measured?.height ?? 0,
  };

  let horizontalDistance = distance;
  let verticalDistance = distance;

  return nodes
    .filter((node) => node.id !== nodeA.id)
    .reduce<GetHelperLinesResult>((result, nodeB) => {
      const nodeBBounds = {
        left: nodeB.position.x,
        right: nodeB.position.x + (nodeB.measured?.width ?? 0),
        top: nodeB.position.y,
        bottom: nodeB.position.y + (nodeB.measured?.height ?? 0),
        width: nodeB.measured?.width ?? 0,
        height: nodeB.measured?.height ?? 0,
      };

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //  |
      //  |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftLeft = Math.abs(nodeABounds.left - nodeBBounds.left);

      if (distanceLeftLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceLeftLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceRightRight = Math.abs(
        nodeABounds.right - nodeBBounds.right
      );

      if (distanceRightRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right - nodeABounds.width;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceRightRight;
      }

      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     A     |
      //              |___________|
      //              |
      //              |
      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     B     |
      //  |___________|
      const distanceLeftRight = Math.abs(nodeABounds.left - nodeBBounds.right);

      if (distanceLeftRight < verticalDistance) {
        result.snapPosition.x = nodeBBounds.right;
        result.vertical = nodeBBounds.right;
        verticalDistance = distanceLeftRight;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|
      //              |
      //              |
      //              |‾‾‾‾‾‾‾‾‾‾‾|
      //              |     B     |
      //              |___________|
      const distanceRightLeft = Math.abs(nodeABounds.right - nodeBBounds.left);

      if (distanceRightLeft < verticalDistance) {
        result.snapPosition.x = nodeBBounds.left - nodeABounds.width;
        result.vertical = nodeBBounds.left;
        verticalDistance = distanceRightLeft;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|     |___________|
      const distanceTopTop = Math.abs(nodeABounds.top - nodeBBounds.top);

      if (distanceTopTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceTopTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |
      //  |___________|_________________
      //                    |           |
      //                    |     B     |
      //                    |___________|
      const distanceBottomTop = Math.abs(nodeABounds.bottom - nodeBBounds.top);

      if (distanceBottomTop < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.top - nodeABounds.height;
        result.horizontal = nodeBBounds.top;
        horizontalDistance = distanceBottomTop;
      }

      //  |‾‾‾‾‾‾‾‾‾‾‾|     |‾‾‾‾‾‾‾‾‾‾‾|
      //  |     A     |     |     B     |
      //  |___________|_____|___________|
      const distanceBottomBottom = Math.abs(
        nodeABounds.bottom - nodeBBounds.bottom
      );

      if (distanceBottomBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom - nodeABounds.height;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceBottomBottom;
      }

      //                    |‾‾‾‾‾‾‾‾‾‾‾|
      //                    |     B     |
      //                    |           |
      //  |‾‾‾‾‾‾‾‾‾‾‾|‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
      //  |     A     |
      //  |___________|
      const distanceTopBottom = Math.abs(nodeABounds.top - nodeBBounds.bottom);

      if (distanceTopBottom < horizontalDistance) {
        result.snapPosition.y = nodeBBounds.bottom;
        result.horizontal = nodeBBounds.bottom;
        horizontalDistance = distanceTopBottom;
      }

      return result;
    }, defaultResult);
}

// Edges toolbar data

export const colors = [
  "#94a3b8", // Slate 400
  "#9ca3af", // Gray 400
  "#a1a1aa", // Zinc 400
  "#a3a3a3", // Neutral 400
  "#a8a29e", // Stone 400
  "#f87171", // Red 400
  "#fb923c", // Orange 400
  "#fbbf24", // Amber 400
  "#facc15", // Yellow 400
  "#a3e635", // Lime 400
  "#4ade80", // Green 400
  "#34d399", // Emerald 400
  "#2dd4bf", // Teal 400
  "#22d3ee", // Cyan 400
  "#38bdf8", // Sky 400
  "#60a5fa", // Blue 400
  "#818cf8", // Indigo 400
  "#a78bfa", // Violet 400
  "#c084fc", // Purple 400
  "#e879f9", // Fuchsia 400
  "#f472b6", // Pink 400
  "#fb7185", // Rose 400
];


export const edgeStyles = [
  {
    label: "Solid",
    style: { stroke: "#858790", strokeWidth: 2, strokeDasharray: "none" },
  },

  {
    label: "Dashe",
    style: { stroke: "#858790", strokeWidth: 2, strokeDasharray: "10,10" },
  },
  {
    label: "Fine Dashed",
    style: { stroke: "#858790", strokeWidth: 2, strokeDasharray: "5,5" },
  },
];

export const objectAnimatedArray = [
  { color: "#94a3b8" }, // Slate 400
  { color: "#9ca3af" }, // Gray 400
  { color: "#a1a1aa" }, // Zinc 400
  { color: "#a3a3a3" }, // Neutral 400
  { color: "#a8a29e" }, // Stone 400
  { color: "#f87171" }, // Red 400
  { color: "#fb923c" }, // Orange 400
  { color: "#fbbf24" }, // Amber 400
  { color: "#facc15" }, // Yellow 400
  { color: "#4ade80" }, // Green 400
  { color: "#34d399" }, // Emerald 400
  { color: "#2dd4bf" }, // Teal 400
  { color: "#22d3ee" }, // Cyan 400
  { color: "#38bdf8" }, // Sky 400
  { color: "#60a5fa" }, // Blue 400
  { color: "#818cf8" }, // Indigo 400
  { color: "#a78bfa" }, // Violet 400
  { color: "#c084fc" }, // Purple 400
  { color: "#e879f9" }, // Fuchsia 400
  { color: "#f472b6" }, // Pink 400
  { color: "#fb7185" }, // Rose 400
];


export const edgeTypesList = [
  {
    type: "straight",
    label: "Straight",
  },
  {
    type: "step",
    label: "Step",
  },
  {
    type: "smoothstep",
    label: "Smoothstep",
  },
  {
    type: "bezier",
    label: "Bezier",
  },
];
