import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import RawText from 'src/components/formatter/rawText';
import { DetailsContext } from 'src/contexts/details/context';
import { ConversationContext } from 'src/type';

function DocumentValidationMessage({ context }: Readonly<{ context: ConversationContext }>) {
  const [areDocumentsLoading, setAreDocumentsLoading] = useState(false);
  const { allDocuments, getAllDocuments } = useContext(DetailsContext);

  useEffect(() => {
    if (!allDocuments) {
      setAreDocumentsLoading(true);
      getAllDocuments?.()?.then(() => setAreDocumentsLoading(false));
    }
  }, [allDocuments, getAllDocuments]);

  const text = {
    'document:limitations': `Check for technology and service offerings limitations in:`,
    'document:improvements': `Explore potential improvements in:`,
  }[context.type];

  if (!text) return null;

  const documentTitle = allDocuments?.find((doc: any) => doc.id === context.document_id)?.title;

  return (
    <p className='text-sm text-gray-600 break-words py-1'>
      <span>{text}</span>
      {areDocumentsLoading ? (
        <div className='h-[38px] w-full mt-2 bg-blue-200 animate-pulse rounded-md' />
      ) : (
        <div className='flex items-center mt-2 p-3 border border-gray-300 rounded-lg shadow-sm bg-white'>
          <DocumentTextIcon className='h-6 w-6 text-blue-500 mr-3' />
          <span className='text-sm font-semibold text-gray-700'>
            {documentTitle || 'Document Not Found'}
          </span>
        </div>
      )}
    </p>
  );
}

function UserMessage({ content, context }: Readonly<{ content: string; context?: ConversationContext }>) {
  if (!context) {
    return (
      <p className='text-base text-gray-600 break-words py-1'>
        <RawText content={content} />
      </p>
    );
  }

  return <DocumentValidationMessage context={context} />;
}

export default UserMessage;
