import {
  useState,
  useEffect,
  useRef,
  useCallback,
  MutableRefObject,
  useLayoutEffect,
  useContext,
  ComponentPropsWithoutRef,
  KeyboardEvent as ReactKeyboardEvent,
  FormEvent,
  useMemo,
  CSSProperties,
} from 'react';
import DocumentPopover, { Kbd } from './layout';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
import { DetailsContext } from 'src/contexts/details/context';
import { classNames, retry } from 'src/utils/utilities';
import { XMarkIcon, PaperAirplaneIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { StopCircleIcon } from '@heroicons/react/24/solid';
import CommandsDropdown from '../commandsDropdown';
import { promptMessage } from 'src/redux/chatGPT/chatGPTApi';
import { useAppDispatch } from 'src/hooks/store';
import { useAuth0 } from '@auth0/auth0-react';
import { GradientProgressLoader } from '../loader';
import { useResizeObserver } from 'src/hooks/useResizeObserver';
import { useDebouncedCallback } from 'use-debounce';
import CustomButton from '../customButton';
import DOMPurify from 'dompurify';
import { VirtualElement, useFloating, offset, flip, autoUpdate } from '@floating-ui/react-dom';

type Size = {
  width: number;
  height: number;
};

type SelectionCallback = (args: { selection: Selection | null; isPending: boolean }) => void;

export function useSelection(onSelectionChange: SelectionCallback) {
  const selectionRef = useRef<Selection | null>(null);
  const containerRef = useRef<HTMLElement | null>(null);
  const onSelectionChangeRef = useRef<SelectionCallback>(onSelectionChange);

  const debouncedOnSelectionChange = useDebouncedCallback((selection: Selection | null) => {
    onSelectionChangeRef.current({ selection, isPending: false });
  }, 500);

  const handleSelectionChange = useCallback(() => {
    const currentSelection = document.getSelection();

    try {
      const hasDocumentSelection = currentSelection && currentSelection.rangeCount > 0;

      if (!hasDocumentSelection) {
        throw new Error('No document selection');
      }

      const isSelectionLongEnough = currentSelection.toString().length >= 5;

      if (!isSelectionLongEnough) {
        throw new Error('Selection is not long enough');
      }

      const range = currentSelection.getRangeAt(0);
      const { startContainer, endContainer } = range;

      const isSelectionWithinContainer =
        containerRef.current &&
        containerRef.current.contains(startContainer) &&
        containerRef.current.contains(endContainer);

      if (!isSelectionWithinContainer) {
        throw new Error('Selection is not within container');
      }

      selectionRef.current = currentSelection;
      onSelectionChangeRef.current({ selection: currentSelection, isPending: true });
      debouncedOnSelectionChange(currentSelection);
    } catch (error) {
      debouncedOnSelectionChange.cancel();
      onSelectionChangeRef.current({ selection: null, isPending: false });
    }
  }, [debouncedOnSelectionChange]);

  const handleMouseUp = useCallback(() => {
    debouncedOnSelectionChange.flush();
  }, [debouncedOnSelectionChange]);

  const setContainerRef = useCallback(
    (node: HTMLElement | null) => {
      if (containerRef.current) {
        document.removeEventListener('selectionchange', handleSelectionChange);
        containerRef.current.removeEventListener('mouseup', handleMouseUp);
      }

      if (node) {
        document.addEventListener('selectionchange', handleSelectionChange);
        node.addEventListener('mouseup', handleMouseUp);
      }

      containerRef.current = node;
    },
    [handleSelectionChange, handleMouseUp]
  );

  useEffect(() => {
    onSelectionChangeRef.current = onSelectionChange;
  }, [onSelectionChange]);

  useEffect(() => {
    return () => {
      debouncedOnSelectionChange.cancel();
    };
  }, [debouncedOnSelectionChange]);

  return { setContainerRef, containerRef };
}

function useFloatingAroundSelection({
  selection,
  containerRef,
  offsetX = 16,
  offsetY = 16,
  minSpace = 0,
}: {
  selection: Selection | null;
  containerRef: MutableRefObject<HTMLElement | null>;
  offsetX?: number;
  offsetY?: number;
  minSpace?: number;
}) {
  const [strategy, setStrategy] = useState<'top' | 'bottom' | 'fallback' | null>(null);
  const [virtualElement, setVirtualElement] = useState<VirtualElement | null>(null);
  const prevSizeRef = useRef<Size>({ width: 0, height: 0 });

  const { refs, floatingStyles: defaultFloatingStyles } = useFloating({
    placement: strategy === 'top' ? 'top-start' : 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: offsetY,
        crossAxis: offsetX,
      }),
      flip({
        fallbackAxisSideDirection: 'end',
        padding: offsetX,
        mainAxis: false,
        crossAxis: true,
      }),
    ],
  });

  useLayoutEffect(() => {
    const containerRect = containerRef.current?.getBoundingClientRect();
    const selectionRect = selection?.getRangeAt(0)?.getBoundingClientRect();

    if (!containerRect || !selectionRect) return;

    const freeSpace = {
      top: selectionRect.top - containerRect.top,
      bottom: containerRect.bottom - selectionRect.bottom,
    };

    if (freeSpace.top < minSpace + offsetY && freeSpace.bottom < minSpace + offsetY) {
      setStrategy('fallback');
    } else if (freeSpace.top > freeSpace.bottom) {
      setStrategy('top');
    } else {
      setStrategy('bottom');
    }
  }, [selection, containerRef, minSpace, offsetY]);

  useEffect(() => {
    if (selection) {
      const range = selection.getRangeAt(0);
      const boundingClientRect = range?.getBoundingClientRect();

      if (!boundingClientRect) {
        return setVirtualElement(null);
      }

      let initialScrollTop: number;

      // Create virtual element with fixed position relative to container
      const virtualElement: VirtualElement = {
        getBoundingClientRect: () => {
          const scrollTop = containerRef.current?.scrollTop || 0;
          const range = selection.getRangeAt(0);
          const boundingClientRect = range?.getBoundingClientRect();

          if (initialScrollTop && boundingClientRect) {
            return {
              ...boundingClientRect,
              top: initialScrollTop - scrollTop,
            };
          } else {
            initialScrollTop = scrollTop;
          }

          return boundingClientRect;
        },
      };

      setVirtualElement(virtualElement);
    } else {
      retry(() => {
        const elements = document.querySelectorAll('.highlighted-selection');

        if (elements.length === 0) {
          throw new Error('No highlighted elements found');
        }

        return elements;
      }).then((highlightedElements) => {
        if (!strategy || !highlightedElements || highlightedElements.length === 0) {
          return setVirtualElement(null);
        }

        const element =
          strategy === 'top' ? highlightedElements[0] : highlightedElements[highlightedElements.length - 1];

        if (!element) {
          return setVirtualElement(null);
        }

        const virtualElement: VirtualElement = {
          getBoundingClientRect: () => element.getBoundingClientRect(),
          contextElement: element,
        };

        setVirtualElement(virtualElement);
      });
    }
  }, [selection, strategy, containerRef]);

  useEffect(() => {
    if (strategy === 'fallback') {
      refs.setReference(null);
    } else {
      refs.setReference(virtualElement);
    }
  }, [virtualElement, refs, strategy]);

  useEffect(() => {
    const container = containerRef.current;

    return () => {
      // Clean up padding when component unmounts
      if (container) {
        container.style.paddingTop = '0px';
        container.style.paddingBottom = '0px';
      }
    };
  }, [containerRef]);

  const handleOverflow = useCallback(
    (size: Size) => {
      if (!containerRef.current || !strategy) return;

      const sizeDifference = size.height - (minSpace + offsetY);

      if (strategy === 'top') {
        containerRef.current.style.paddingTop = `${sizeDifference > 0 ? sizeDifference : 0}px`;
      } else if (strategy === 'bottom') {
        containerRef.current.style.paddingBottom = `${sizeDifference > 0 ? 16 : 0}px`;
      }
    },
    [containerRef, strategy, minSpace, offsetY]
  );

  const setFloating = useResizeObserver(
    useCallback(
      ([
        {
          contentRect: { width, height },
        },
      ]) => {
        if (width === prevSizeRef.current.width && height === prevSizeRef.current.height) {
          return;
        }

        prevSizeRef.current = { width, height };
        handleOverflow({ width, height });
      },
      [handleOverflow]
    )
  );

  // Combine the refs
  const setFloatingElement = useCallback(
    (node: HTMLElement | null) => {
      refs.setFloating(node);
      setFloating(node);
    },
    [refs, setFloating]
  );

  const floatingStyles = useMemo((): CSSProperties | null => {
    if (!strategy || !containerRef.current) return null;

    if (strategy === 'fallback') {
      return {
        position: 'absolute',
        top: 16 + containerRef.current.scrollTop,
        right: 16,
      };
    }

    return defaultFloatingStyles;
  }, [defaultFloatingStyles, strategy, containerRef]);

  return {
    setFloating: setFloatingElement,
    floatingStyles,
  };
}

function AutoResizeTextarea({
  textareaRef,
  placeholder,
  className,
  ...props
}: {
  textareaRef?: (node: HTMLTextAreaElement | null) => void;
  placeholder: string;
} & ComponentPropsWithoutRef<'textarea'>) {
  const ref = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    const textarea = ref.current;

    if (textarea) {
      const resizeTextarea = () => {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      resizeTextarea();

      textarea.addEventListener('input', resizeTextarea);

      return () => {
        textarea.removeEventListener('input', resizeTextarea);
      };
    }
  }, []);

  return (
    <textarea
      ref={(node) => {
        textareaRef?.(node);
        ref.current = node;
      }}
      className={classNames(
        'w-full h-full p-0 bg-transparent border-none border-0',
        '!outline-none !shadow-none !ring-0 resize-none',
        'text-[0.875rem] leading-[1.25rem]',
        'disabled:text-gray-500 disabled:cursor-not-allowed',
        className
      )}
      placeholder={placeholder}
      rows={1}
      {...props}
    />
  );
}

export function SelectionPopoverComponent({
  selection,
  containerRef,
  popoverRef,
  closePopover,
  ...props
}: {
  selection: Selection | null;
  containerRef: MutableRefObject<HTMLElement | null>;
  popoverRef: MutableRefObject<HTMLDivElement | null>;
  closePopover: () => void;
} & ComponentPropsWithoutRef<typeof DocumentPopover>) {
  const scrollableElementRef = useRef(containerRef.current?.parentElement || null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const abortController = useRef<AbortController | null>(null);
  const submittedInstructionsRef = useRef<string | null>(null);

  const [inputValue, setInputValue] = useState('');
  const [selectedHtml, setSelectedHtml] = useState<string | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { setFloating, floatingStyles } = useFloatingAroundSelection({
    selection,
    containerRef: scrollableElementRef,
    offsetX: 0,
    offsetY: 16,
    minSpace: 224,
  });

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { editorRef } = useContext(DetailsContext);

  const isSubmitDisabled = inputValue.trim() === '' || inputValue === '/';

  useEffect(() => {
    if (!selection) return;

    const range = selection.getRangeAt(0);
    const container = document.createElement('div');
    container.appendChild(range.cloneContents());

    setSelectedHtml(DOMPurify.sanitize(container.innerHTML));
  }, [selection]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!selection) return;

      if (e.key === 'Escape') {
        closePopover();
      }

      if (e.key === 'Tab') {
        e.preventDefault();
        textareaRef.current?.focus();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closePopover, selection]);

  useEffect(() => {
    if (isExpanded) {
      const popover = popoverRef.current;
      const container = scrollableElementRef.current;

      if (!popover || !container) return;

      const popoverRect = popover.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      const isFullyVisible = popoverRect.top >= containerRect.top && popoverRect.bottom <= containerRect.bottom;

      if (!isFullyVisible) {
        const scrollOffset =
          popoverRect.top < containerRect.top
            ? popoverRect.top - containerRect.top
            : popoverRect.bottom - containerRect.bottom;

        container.scrollBy({
          top: scrollOffset,
          behavior: 'smooth',
        });
      }
    }
  }, [isExpanded, popoverRef]);

  const submitInstructions = async (instructions = inputValue) => {
    if (!selectedHtml || isLoading) return;

    // setSelectedHtml(HTML_PLACEHOLDER);
    // setIsExpanded(true);
    // setInputValue('');
    // submittedInstructionsRef.current = instructions;

    // return;

    setIsLoading(true);

    abortController.current = new AbortController();
    const { signal } = abortController.current;

    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token');
      }

      const fullInstructions =
        submittedInstructionsRef.current === instructions
          ? `${instructions} (same as before, please rewrite)`
          : instructions;

      const res = await dispatch(
        promptMessage({
          body: {
            prompt: `
                  HTML: ${selectedHtml}
                  Instructions: ${fullInstructions}
                `,
          },
          accessToken,
          type: 'documents-integrated-assistant',
          signal,
        })
      );

      const requestStatus = res?.payload?.requestStatus;
      const newHtml = res?.payload?.data?.message;

      if (requestStatus === 'rejected') {
        throw new Error('Request rejected');
      }

      if (typeof newHtml !== 'string') {
        throw new Error('Invalid response from AI');
      }

      setSelectedHtml(DOMPurify.sanitize(newHtml));
      setIsExpanded(true);
      setInputValue('');
      submittedInstructionsRef.current = instructions;
    } catch (error) {
      if (!signal.aborted) {
        console.error('Error while generating rewritten HTML', error);
      }
    } finally {
      setIsLoading(false);
      abortController.current = null;
    }
  };

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isSubmitDisabled) return;

    submitInstructions();
  };

  const handleStop = () => {
    abortController.current?.abort();
  };

  const handleTryAgain = () => {
    if (!submittedInstructionsRef.current) return;

    setInputValue(submittedInstructionsRef.current);
    submitInstructions(`${submittedInstructionsRef.current}`);
  };

  const handleTextareaKeyDown = (e: ReactKeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitInstructions();
    }
  };

  if (!scrollableElementRef.current) return null;

  return createPortal(
    <Transition
      show={!!floatingStyles}
      enter='transition-opacity duration-300'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-200'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <DocumentPopover
        className={classNames('absolute', isExpanded && '!max-w-[600px]')}
        style={floatingStyles || {}}
        ref={(node) => {
          setFloating(node);
          popoverRef.current = node;
        }}
        {...props}
      >
        <DocumentPopover.Header
          title={
            <div className='flex items-center gap-2'>
              <SparklesIcon className='size-5 text-blue-500' />
              <span>AI Assistant</span>
            </div>
          }
        >
          <button
            type='button'
            className='p-1 rounded-md'
            onClick={closePopover}
          >
            <XMarkIcon className='size-5' />
          </button>
        </DocumentPopover.Header>

        <DocumentPopover.Body className='relative !p-0 !gap-0'>
          {isExpanded && selectedHtml && (
            <>
              <div
                className='ck-content max-h-52 !overflow-y-auto p-4 border-bottom bg-gray-50'
                style={{ fontSize: '14px' }}
                dangerouslySetInnerHTML={{ __html: selectedHtml }}
              />
              <div className='mx-4 flex items-center gap-2 py-2'>
                <CustomButton
                  type='button'
                  btnStyle='bg-blue-500 text-white text-sm px-3 py-1.5 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:bg-gray-300'
                  onClickBtn={() => {
                    editorRef.current?.execute('selection:replace', { html: selectedHtml });
                    closePopover();
                  }}
                  text='Replace'
                  disabled={isLoading}
                />

                <CustomButton
                  type='button'
                  text='Insert above'
                  onClickBtn={() => {
                    editorRef.current?.execute('selection:insertAbove', { html: selectedHtml });
                    closePopover();
                  }}
                  buttonType='secondary'
                  disabled={isLoading}
                />

                <CustomButton
                  type='button'
                  text='Insert below'
                  onClickBtn={() => {
                    editorRef.current?.execute('selection:insertBelow', { html: selectedHtml });
                    closePopover();
                  }}
                  buttonType='secondary'
                  disabled={isLoading}
                />

                <CustomButton
                  type='button'
                  text='Try again'
                  onClickBtn={handleTryAgain}
                  buttonType='secondary'
                  btnStyle='ml-auto'
                  disabled={isLoading}
                />
              </div>
            </>
          )}

          <form
            onSubmit={handleFormSubmit}
            className={classNames(
              'flex items-end justify-between gap-2 divide-x-[1px]',
              'max-h-[8.5rem] overflow-y-auto',
              isExpanded && 'mt-2 mb-4 mx-4 border border-gray-200 rounded-md'
            )}
          >
            <CommandsDropdown
              show={inputValue === '/'}
              inputValue={inputValue}
              onSelect={(option, textareaElement) => {
                if (!option) {
                  setInputValue('');
                  textareaElement?.focus();
                }
              }}
            >
              {(setReference) => (
                <AutoResizeTextarea
                  textareaRef={(node) => {
                    setReference(node);
                    textareaRef.current = node;
                  }}
                  className={classNames('p-4')}
                  placeholder='Start typing or press / for commands'
                  value={inputValue}
                  onChange={(event) => {
                    setInputValue(event.target.value);
                  }}
                  onKeyDown={handleTextareaKeyDown}
                  disabled={isLoading}
                />
              )}
            </CommandsDropdown>

            {isLoading ? (
              <button
                type='button'
                className={classNames('my-2 py-2 px-3 transition-colors disabled:text-[#a9a9a9]')}
                onClick={(e) => {
                  e.preventDefault(); // Prevent form submission
                  handleStop();
                }}
              >
                <StopCircleIcon className='size-5' />
              </button>
            ) : (
              <button
                type='submit'
                disabled={isSubmitDisabled}
                className={classNames('my-2 py-2 px-3 transition-colors enabled:hover:text-blue-500')}
              >
                <PaperAirplaneIcon className='size-5' />
              </button>
            )}
          </form>

          {isLoading && <GradientProgressLoader className='absolute bottom-0 translate-y-1/2 inset-x-0' />}
        </DocumentPopover.Body>

        <DocumentPopover.Footer>
          Hit <Kbd>Tab</Kbd> to focus, <Kbd>Esc</Kbd> to close
        </DocumentPopover.Footer>
      </DocumentPopover>
    </Transition>,
    scrollableElementRef.current
  );
}

export function SelectionPopover({
  selection,
  containerRef,
}: {
  selection: Selection | null;
  containerRef: MutableRefObject<HTMLElement | null>;
}) {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const isSelectionHighlighted = useRef(false);
  const wasClicked = useRef(false);

  const [forceShow, setForceShow] = useState(!!selection);
  const { editorRef } = useContext(DetailsContext);

  const setHighlight = useCallback(
    (shouldHighlight: boolean) => {
      const editor = editorRef.current;

      if (!editor) return;

      if (shouldHighlight && isSelectionHighlighted.current) {
        editor.execute('selection:unhighlight');
      }

      if (shouldHighlight && !isSelectionHighlighted.current) {
        editor.execute('selection:highlight');
      }

      if (!shouldHighlight && isSelectionHighlighted.current) {
        editor.execute('selection:unhighlight');
      }

      isSelectionHighlighted.current = shouldHighlight;
    },
    [editorRef]
  );

  useEffect(() => {
    if (selection) {
      setForceShow(true);

      return;
    }

    const timer = setTimeout(() => {
      const popover = popoverRef.current;
      const isFocusWithin = !!popover?.matches(':focus-within');

      setForceShow(isFocusWithin || wasClicked.current);

      wasClicked.current = false;
    }, 100); // Defer execution to allow click handler to run

    return () => clearTimeout(timer);
  }, [selection]);

  // Handle removing the popover on click outside
  useEffect(() => {
    const popover = popoverRef.current;

    if (selection) return;

    const clickHandler = (event: MouseEvent) => {
      const { target } = event;

      // Check if the target is still in the document
      if (!(target instanceof Node) || !document.contains(target)) {
        return;
      }

      const isClickInside = !!popover?.contains(target);

      if (!isClickInside) {
        setForceShow(false);
      }
    };

    document.addEventListener('click', clickHandler);

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, [selection]);

  useEffect(() => {
    setHighlight(!selection && forceShow);

    return () => {
      setHighlight(false);
    };
  }, [selection, forceShow, setHighlight]);

  const closePopover = useCallback(() => {
    setForceShow(false);
  }, [setForceShow]);

  const handleClick = useCallback(() => {
    wasClicked.current = true;
  }, []);

  if (!forceShow) return null;

  return (
    <SelectionPopoverComponent
      selection={selection}
      containerRef={containerRef}
      popoverRef={popoverRef}
      closePopover={closePopover}
      onClick={handleClick}
    />
  );
}
