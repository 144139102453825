import {
  ArrowTopRightOnSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  Handle,
  NodeProps,
  NodeResizer,
  NodeToolbar,
  Position,
  useReactFlow,
  useStore,
  useStoreApi,
} from "@xyflow/react";
import { memo, useEffect, useRef, useMemo, useCallback } from "react";

import { Tooltip } from "react-tooltip";
import debounce from "lodash/debounce";
import { getRelativeNodesBounds } from "./utils";
import useDetachNodes from "./useDetachNodes";

const lineStyle = { borderColor: "white" };

function GroupNode({ id, data }: NodeProps) {
  const store = useStoreApi();
  const { deleteElements, setNodes } = useReactFlow();
  const detachNodes = useDetachNodes();
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const { minWidth, minHeight, hasChildNodes } = useStore(
    useCallback((store) => {
      const childNodes = Array.from(store.nodeLookup.values()).filter(
        (n) => n.parentId === id
      );
      const rect = getRelativeNodesBounds(childNodes);

      return {
        minWidth: rect.x + rect.width,
        minHeight: rect.y + rect.height,
        hasChildNodes: childNodes.length > 0,
      };
    }, [id]),
    isEqual
  );

  const onDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
  }, [deleteElements, id]);

  const onDetach = useCallback(() => {
    const childNodeIds = Array.from(store.getState().nodeLookup.values())
      .filter((n) => n.parentId === id)
      .map((n) => n.id);

    detachNodes(childNodeIds, id);
  }, [detachNodes, id, store]);

  const handleResize = useMemo(
    () =>
      debounce(() => {
        // Any resize logic that might cause the ResizeObserver warning can be placed here.
      }, 100),
    []
  );

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserverRef.current.observe(element);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [id, handleResize]);

  const handleNodeMouseEnter = useCallback(() => {
    // Clear selection of all nodes except the one being dragged
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        selected: false,
      }))
    );
  }, [setNodes]);

  const iconStyle = "h-4 m-1 cursor-pointer text-zinc-800";

  return (
    <div
      onMouseEnter={handleNodeMouseEnter}
      id={id}
      className={`rounded-md h-full`}
      style={{
        border: `${data?.border_style} 2px ${data?.color}`,
        background: `${data?.background}`,
      }}
    >
      <div className="w-full flex items-center mb-2 h-[54px]">
        <div
          className={`rounded-br-md mr-2 p-3`}
          style={{ background: `${data?.color}` }}
        >
          <img
            className="h-[30px]"
            src={`${data?.icon_url}`}
            alt={`${data?.label}`}
          />
        </div>
        <div
          className={`text-[${data?.color}] text-sm cursor-text`}
        >{`${data?.label}`}</div>
      </div>

      <NodeResizer
        lineStyle={lineStyle}
        minHeight={minHeight}
        minWidth={minWidth}
        handleStyle={{
          width: "12px", // Resizer handle size for easy grabbing
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          border: 0,
        }}
      />
      <NodeToolbar
        style={{
          borderRadius: 7,
          boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
        }}
        className="nodrag absolute py-[3.2px] top-0 left-0 bg-white border border-zinc-300 px-2 flex items-center justify-center"
      >
        <TrashIcon
          data-tooltip-id="delete-node-tooltip"
          onClick={onDelete}
          className={`${iconStyle} hover:text-red-600 h-[18px]`}
        />

        {hasChildNodes && (
          <ArrowTopRightOnSquareIcon
            data-tooltip-id="ungroup-node-tooltip"
            onClick={onDetach}
            className={`${iconStyle} hover:text-blue-600 h-[18px]`}
          />
        )}
      </NodeToolbar>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="ungroup-node-tooltip" place="top">
        Ungroup
      </Tooltip>

      <Handle
        className="w-[100px] h-[100px] text-lg"
        type="target"
        position={Position.Top}
        id="top-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Bottom}
        id="bottom-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Left}
        id="left-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Right}
        id="right-target"
      />

      <Handle
        type="source"
        className="z-50"
        position={Position.Top}
        id="top-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Bottom}
        id="bottom-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Left}
        id="left-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Right}
        id="right-source"
      />
    </div>
  );
}

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default memo(GroupNode);
