import React, { useEffect, HTMLAttributes, ReactNode, Fragment } from 'react';
import { classNames } from 'src/utils/utilities';

import controlIcon from 'src/assets/icons/control.svg';
import enterIcon from 'src/assets/icons/enter.svg';
import { platform } from 'src/utils/platform';

type KeyType = string | { key: string; ui: ReactNode };

interface KeyboardShortcutProps extends HTMLAttributes<HTMLDivElement> {
  keys: KeyType[];
  onTrigger: () => void;
  withSeparator?: boolean;
}

const KeyboardShortcut: React.FC<KeyboardShortcutProps> = ({
  keys,
  onTrigger,
  className = '',
  withSeparator = false,
  ...props
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const pressedKeys = keys.map((key) => (typeof key === 'string' ? key : key.key));
      const isShortcutTriggered = pressedKeys.every((key) => {
        return event.getModifierState(key) || event.key === key;
      });

      if (isShortcutTriggered) {
        event.preventDefault();
        onTrigger();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [keys, onTrigger]);

  return (
    <div
      className={classNames(
        'py-1 px-1.5 flex items-center gap-1 bg-white/50 rounded-[4px]',
        'text-[12px]/[1] font-semibold text-black/50',
        className
      )}
      {...props}
    >
      {keys.map((key, index) => (
        <Fragment key={index}>
          <kbd className={classNames('flex items-center justify-center')}>{typeof key === 'string' ? key : key.ui}</kbd>
          {withSeparator && index !== keys.length - 1 && <span>+</span>}
        </Fragment>
      ))}
    </div>
  );
};

const ControlEnter: React.FC<Omit<KeyboardShortcutProps, 'keys'>> = ({ className, ...props }) => {
  if (!platform.isWindows && !platform.isLinux && !platform.isMac) {
    return null;
  }

  return (
    <KeyboardShortcut
      {...props}
      withSeparator={!platform.isMac}
      className={classNames(className)}
      keys={[
        {
          key: 'Control',
          ui: platform.isMac ? (
            <img
              src={controlIcon}
              className='size-4 opacity-50'
              alt='Ctrl'
              aria-hidden
            />
          ) : (
            <span>Ctrl</span>
          ),
        },
        {
          key: 'Enter',
          ui: (
            <img
              src={enterIcon}
              className='size-4 opacity-50'
              alt='Enter'
              aria-hidden
            />
          ),
        },
      ]}
    />
  );
};

export default Object.assign(KeyboardShortcut, { ControlEnter });
