import React, { useState, useContext } from "react";
import { DocumentTextIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useAppDispatch } from "../../../../hooks";
import CompleteEmptyPage from "../../../../components/completeEmptyPage";
import CustomModal from '../../../../components/customModal';
import ItemWithDropdown from "../../../../components/itemWithDropdown";
import { setTemplatesCurrentTab } from "../../../../redux/profile/profileSlice";
import DocumentForm from "../documents/documentForm";
import TemplatesProjectsDocumentsModal from "../../../templates/templatesProjectsDocumentsModal";
import { DetailsContext } from "../../../../contexts/details/context";
import CustomButton from "src/components/customButton";

import diagramsSVG from "../../../../assets/images/Illustration-10.svg";


const DocumentsEmpty = () => {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [openDocumentsTemplatesModal, setOpenDocumentsTemplatesModal] =
      useState(false);

  const dispatch = useAppDispatch();

   const {
     createDocument,
   } = useContext(DetailsContext);

  const onCloseModal = () => {
    setFormLoader(false);
  };

  const onOpenModal = () => {
    setOpenFormModal(true);
  };

  const openingTemplateDocuments = (value: number) => {
    dispatch(setTemplatesCurrentTab(value));
    setOpenDocumentsTemplatesModal(true);
  };

  const creatingDocument = async (body: any) => {
    setFormLoader(true);

    try {
      await createDocument(body);

      onCloseModal();
    } finally {
      setFormLoader(false);
    }
  };

  const actionsList = [
    {
      id: 1,
      name: "Black Document",
      onActionClick: () => onOpenModal(),
      icon: (
        <DocumentTextIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
    {
      id: 2,
      name: "My Templates",
      onActionClick: () => openingTemplateDocuments(3),
      icon: (
        <DocumentTextIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
    {
      id: 4,
      name: "Community Templates",
      onActionClick: () => openingTemplateDocuments(2),
      icon: (
        <DocumentTextIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
  ];

  return (
    <>
      <div
        className="flex items-center justify-center"
        style={{ height: "calc(100vh - 140px)" }}
      >
        <div>
          <CompleteEmptyPage
            title="You don't have any Document"
            subTitle="Get started by creating a new Document"
            btnText="Create"
            onClickBtn={null}
            icon={diagramsSVG}
          />
          <div className="mt-6 mx-auto w-[165px] relative">
            <ItemWithDropdown actionsData={actionsList}>
              <CustomButton
                text="Create Document"
                type="button"
                onClickBtn={() => console.log("clicking...")}
                buttonType="primary"
                beforeIcon={
                  <PlusIcon
                    className="-ml-0.5 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                }
              />
            </ItemWithDropdown>
          </div>
        </div>
      </div>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title="Create Document"
        size="max-w-lg"
      >
        <DocumentForm
          onCloseModal={() => onCloseModal()}
          loading={formLoader}
          onSubmitData={creatingDocument}
          editData={null}
        />
      </CustomModal>

      {openDocumentsTemplatesModal && (
        <TemplatesProjectsDocumentsModal
          onCloseModal={() => setOpenDocumentsTemplatesModal(false)}
          openModal={openDocumentsTemplatesModal}
          onUseTemplate={() => console.log("Creating Document")}
        />
      )}
    </>
  );
};

export default DocumentsEmpty;
