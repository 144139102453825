import {
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  TrashIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  SquaresPlusIcon,
} from "@heroicons/react/24/outline";
import {
  Handle,
  NodeResizer,
  NodeToolbar,
  Position,
  useReactFlow,
  useStore,
} from "@xyflow/react";
import { memo, useEffect, useRef, useState, useCallback } from "react";
import {
  setOpenNodeSetting,
  setSelectedNodeSetting,
} from "../../../redux/diagrams/diagramsSlice";

import { Tooltip } from "react-tooltip";
import { debounce } from "lodash";
import { getRelativeNodesBounds } from "./utils";
import { useAppDispatch } from "../../../hooks";
import useDetachNodes from "./useDetachNodes";
import Popover from "../../../components/popover";
import NodeIconsDropdown from "./NodeIconsDropdown";

const lineStyle = { borderColor: "white" };

const SimpleNode = memo(({ id, data }: any) => {
  const [isEditText, setIsEditText] = useState(false);
  const [nodeTitle, setNodeTitle] = useState("");

  const hasParent = useStore((store) => !!store.nodeLookup.get(id)?.parentId);
  const { deleteElements, setNodes } = useReactFlow();
  const detachNodes = useDetachNodes();

  const dispatch = useAppDispatch();

  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const nodeRef = useRef<any>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { minWidth, minHeight } = useStore((store) => {
    const childNodes = Array.from(store.nodeLookup.values()).filter(
      (n) => n.parentId === id
    );
    const rect = getRelativeNodesBounds(childNodes);

    return {
      minWidth: rect.x + rect.width,
      minHeight: rect.y + rect.height,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  const onDelete = useCallback(
    () => deleteElements({ nodes: [{ id }] }),
    [deleteElements, id]
  );
  const onDetach = useCallback(() => detachNodes([id]), [detachNodes, id]);

  const onOpenNodeEditSetting = useCallback(() => {
    dispatch(setSelectedNodeSetting({ id, data }));
    dispatch(setOpenNodeSetting(true));
  }, [dispatch, id, data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debounce(() => {
      // Resize logic
    }, 100),
    []
  );

  const onUpdateNodes = useCallback(() => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? { ...node, data: { ...node.data, label: nodeTitle || data?.label } }
          : node
      )
    );
    setNodeTitle("");
  }, [setNodes, id, nodeTitle, data]);

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserverRef.current.observe(element);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [handleResize, id, onUpdateNodes]);

  const onEditNodeText = useCallback(() => {
    setNodeTitle(data?.label);
    setIsEditText((prev) => !prev);
  }, [data]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey) {
        onUpdateNodes();
        setIsEditText(false);
        event.preventDefault();
      }
    },
    [onUpdateNodes]
  );

  const onChangeNodeTitle = useCallback((e: any) => {
    setNodeTitle(e.target.value);
  }, []);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        textareaRef.current &&
        !textareaRef.current.contains(event.target as Node)
      ) {
        onUpdateNodes();
        setIsEditText(false);
        setNodeTitle("");
      }
    },
    [onUpdateNodes]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const bringNodeToTop = useCallback(() => {
    setNodes((prevNodes) => {
      const nodeToMove = prevNodes.find((node) => node.id === id);
      const otherNodes = prevNodes.filter((node) => node.id !== id);

      return [...otherNodes, nodeToMove!];
    });
  }, [setNodes, id]);

  const sendNodeToBack = useCallback(() => {
    setNodes((prevNodes) => {
      const nodeToMove = prevNodes.find((node) => node.id === id);
      const otherNodes = prevNodes.filter((node) => node.id !== id);

      return [nodeToMove!, ...otherNodes];
    });
  }, [setNodes, id]);

  const toggleZIndex = useCallback(() => {
    if (data.isAtTop) {
      sendNodeToBack();
    } else {
      bringNodeToTop();
    }

    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === id
          ? { ...node, data: { ...node.data, isAtTop: !node.data.isAtTop } }
          : node
      )
    );
  }, [data, sendNodeToBack, bringNodeToTop, setNodes, id]);

  const onChangeNodeIcon = useCallback(
    (url: string, name: string) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === id
            ? {
                ...node,
                data: { ...node.data, icon_url: url, label: name },
              }
            : node
        )
      );
    },
    [setNodes, id]
  );

  const handleNodeMouseEnter = useCallback(() => {
    // Clear selection of all nodes except the one being dragged
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        selected: false,
      }))
    );
  }, [setNodes]);

  const handleNodeMouseLeave = useCallback(() => {
    onUpdateNodes();
    setIsEditText(false);
    setNodeTitle("");
  }, [onUpdateNodes]);

  const iconStyle = "h-[20px] text-[#6f717c]";

  const iconWrapper =
    "cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]";

  // Node styling
  return (
    <div
      onMouseEnter={handleNodeMouseEnter}
      onMouseLeave={handleNodeMouseLeave}
      className="relative px-4 py-2 shadow-xl rounded-xl bg-white h-[100%]"
    >
      <div className="text-center">
        <img src={data?.icon_url} alt="icon" className="h-14 mx-auto mb-3" />
        <div className="text-xs font-semibold cursor-text h-full" ref={nodeRef}>
          {isEditText ? (
            <textarea
              ref={textareaRef}
              autoFocus
              value={nodeTitle || data?.label}
              onChange={onChangeNodeTitle}
              onKeyDown={handleKeyDown}
              className="p-0 pl-1 border-0 bg-white shadow-sm text-xs font-semibold text-center resize-none h-full w-full"
              style={{ boxShadow: "none", minHeight: 40 }}
              draggable={false}
            />
          ) : (
            <span onClick={onEditNodeText}>{data?.label}</span>
          )}
        </div>
      </div>

      <Handle
        type="target"
        className="z-50"
        position={Position.Top}
        id="top-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Bottom}
        id="bottom-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Left}
        id="left-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Right}
        id="right-target"
      />

      <Handle
        type="source"
        className="z-50"
        position={Position.Top}
        id="top-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Bottom}
        id="bottom-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Left}
        id="left-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Right}
        id="right-source"
      />

      <NodeResizer
        lineStyle={lineStyle}
        minHeight={minHeight}
        minWidth={minWidth}
        isVisible={true} // Always show the resizer handles
        handleStyle={{
          width: "12px", // Resizer handle size for easy grabbing
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          border: 0,
        }}
      />

      <NodeToolbar
        style={{
          borderRadius: 7,
          boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
        }}
        className="nodrag nowheel absolute p-[4px] top-0 left-0 bg-white border border-[#e0e0e0] flex items-center justify-center"
      >
        <div className="p">
          <Popover
            buttonContent={
              <div className={`${iconWrapper}`}>
                <SquaresPlusIcon className={`${iconStyle}`} />
              </div>
            }
            popoverContent={
              <NodeIconsDropdown
                onChangeNodeIcon={onChangeNodeIcon}
                nodeIcon={data?.icon_url}
              />
            }
            classes="w-[400px] bottom-[29px] left-[-145px]"
          />
        </div>

        {data?.isAtTop ? (
          <div className={`${iconWrapper}`}>
            <ArrowDownIcon
              data-tooltip-id="move-node-tooltip"
              onClick={toggleZIndex}
              className={`${iconStyle}`}
            />
          </div>
        ) : (
          <div className={`${iconWrapper}`}>
            <ArrowUpIcon
              data-tooltip-id="move-node-tooltip"
              onClick={toggleZIndex}
              className={`${iconStyle}`}
            />
          </div>
        )}

        <div className={`${iconWrapper}`}>
          <TrashIcon
            data-tooltip-id="delete-node-tooltip"
            onClick={onDelete}
            className={`${iconStyle}`}
          />
        </div>

        <div className={`${iconWrapper}`}>
          <PencilIcon
            data-tooltip-id="edit-node-tooltip"
            onClick={onOpenNodeEditSetting}
            className={`${iconStyle}`}
          />
        </div>

        {hasParent && (
          <div className={`${iconWrapper}`}>
            <ArrowTopRightOnSquareIcon
              data-tooltip-id="detach-node-tooltip"
              onClick={onDetach}
              className={`${iconStyle}`}
            />
          </div>
        )}
      </NodeToolbar>

      <Tooltip className="z-[50]" id="move-node-tooltip" place="top">
        {data.isAtTop ? "Send to Back" : "Bring to Top"}
      </Tooltip>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="edit-node-tooltip" place="top">
        Edit Setting
      </Tooltip>

      <Tooltip className="z-[50]" id="detach-node-tooltip" place="top">
        Detach
      </Tooltip>
    </div>
  );
});

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default SimpleNode;
