import { useEffect, useState } from 'react';
import { ArrowTopRightOnSquareIcon, ChevronLeftIcon, Cog6ToothIcon as CogIcon } from '@heroicons/react/24/outline';
import { useAuth0 } from '@auth0/auth0-react';
import ChatGPT from './chatGPT';
import { getAllConversations, createConversation, deleteConversations } from '../../../../redux/chatGPT/chatGPTApi';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { classNames, getLastIdFromUrl } from '../../../../utils/utilities';
import WrapperLoader from 'src/components/wrapperLoader';
import CompleteEmptyPage from '../../../../components/completeEmptyPage';
import ChatAIModal from '../chatAImodal';
import ConversationBox from './conversationBox';
import RoundButton from '../../../../components/roundButton';
import ConfirmationModal from '../../../../components/confirmationModal';
import DropdownSelectionOption from '../../../../components/dropdownSelectionOption';

import aiSVG from '../../../../assets/images/Illustration-09.svg';
import CustomDrawer from 'src/components/customDrawer';
import { getUserAiAssistant, updateUserAiAssistant } from 'src/redux/userAiAssistants/userAiAssistantsApi';
import CreationForm from 'src/pages/aiAssistants/CreationForm';
import { SelectedConversation } from 'src/type';

interface ChatGPTProps {
  addItemToTextEditor: Function;
  selectedConversation: SelectedConversation | null;
  setSelectedConversation: (conversation: SelectedConversation | null) => void;
}

function ChatUpdateModal({
  aiAssistantId,
  open,
  onClose,
}: Readonly<{ aiAssistantId: string; open: boolean; onClose: VoidFunction }>) {
  const [fetchLoader, setFetchLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);

  const selectedAiAssistant = useAppSelector((state) => state.userAiAssistants.getUserAiAssistantRes);
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      setFetchLoader(true);

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        await dispatch(
          getUserAiAssistant({
            accessToken,
            resource_id: aiAssistantId,
          })
        );
      } catch (error) {
        console.error('Error getting AI Assistant:', error);
      } finally {
        setFetchLoader(false);
      }
    };

    if (selectedAiAssistant) {
      setFetchLoader(false);
    } else if (open && !fetchLoader) {
      fetchData();
    }
  }, [selectedAiAssistant, fetchLoader, aiAssistantId, getAccessTokenSilently, dispatch, open]);

  const updatingAIAssistant = async (body: any) => {
    if (!selectedAiAssistant) {
      return;
    }

    setUpdateLoader(true);

    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token');
      }

      await dispatch(
        updateUserAiAssistant({
          body,
          accessToken,
          resource_id: aiAssistantId,
        })
      );

      onClose();
    } catch (error) {
      console.error('Error updating AI Assistant:', error);
    } finally {
      setUpdateLoader(false);
    }
  };

  return (
    <CustomDrawer
      open={open}
      onCloseModal={onClose}
      headerClassName='bg-gradient-primary'
      title={
        <div className='flex items-center'>
          <span>Update AI Assistant</span>
          <a
            href='https://example.com'
            target='_blank'
            rel='noreferrer'
            className='text-xs text-yellow-500 hover:text-yellow-800 flex items-center font-semibold ml-3'
          >
            <span className='mr-1'>Learn More</span>
            <ArrowTopRightOnSquareIcon className='h-3' />
          </a>
        </div>
      }
      description='Update Your AI Assistant.'
    >
      <WrapperLoader loading={fetchLoader}>
        <CreationForm
          onCloseModal={onClose}
          editData={selectedAiAssistant}
          onSubmitForm={updatingAIAssistant}
          loader={updateLoader}
          editMode
        />
      </WrapperLoader>
    </CustomDrawer>
  );
}

export default function ChatGPTSideBar({ addItemToTextEditor, selectedConversation, setSelectedConversation }: ChatGPTProps) {
  const [allConversationsLoader, setAllConversationsLoader] = useState(false);
  const [aiChatsModal, setAiChatsModal] = useState(false);

  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [conversationsList, setConversationsList] = useState<any[]>([]);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { getAllConversationsRes } = useAppSelector((state) => state.chatGPT);
  const { currentAiAssistantTab } = useAppSelector((state) => state.userAiAssistants);

  useEffect(() => {
    const list = [...(getAllConversationsRes || [])];
    if (list?.length > 0) {
      list.reverse();
    }

    setConversationsList(list);
  }, [getAllConversationsRes]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAllConversationsLoader(true);
          await dispatch(
            getAllConversations({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          setAllConversationsLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  }, [dispatch, getAccessTokenSilently]);

  const onOpenAIChatModal = () => {
    setAiChatsModal(true);
  };

  const onCloseAIChatModal = () => {
    setAiChatsModal(false);
  };

  const creatingConversation = (conversation_configuration_id: string) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
              conversation_configuration_id,
              template_type: (currentAiAssistantTab === 1 && 'community') || (currentAiAssistantTab === 2 && 'private'),
            })
          ).then((res) => {
            onSelectedConversation(res?.payload?.data);
            onCloseAIChatModal();
          });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
  };

  const deletingConversation = () => {
    const fetchData = async () => {
      setDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteConversations({
              accessToken,
              project_id: selectedData?.project_id,
              conversation_id: selectedData?.id,
            })
          );

          setDeleteLoader(false);
          closeConfirmationModal();
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setDeleteLoader(false);
        closeConfirmationModal();
      }
    };
    fetchData();
  };

  const deleteConfirmationModal = (e: any, data: any) => {
    e.stopPropagation();

    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  const onSelectedConversation = (data: any) => {
    setSelectedConversation(data);
  };

  return (
    <>
      {conversationsList?.length > 0 ? (
        selectedConversation ? (
          <div>
            <div className="mx-3 h-toolbar border-b border-zinc-100 flex items-center justify-between">
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => onSelectedConversation(null)}
                  className={classNames(
                    `p-2 mr-2 rounded cursor-pointer bg-transparent text-customDarkBlue`,
                    "flex items-center justify-center transition-colors hover:bg-zinc-100 hover:text-customDarkBlue"
                  )}
                >
                  <ChevronLeftIcon className="h-4" />
                </button>

                <span>
                  {selectedConversation && (
                    <DropdownSelectionOption
                      optionsList={conversationsList}
                      selected={selectedConversation}
                      onSelectAssistant={onSelectedConversation}
                      onAddingDocument={() => onOpenAIChatModal()}
                      btnText="New Chat"
                      buttonClassName="!text-base !text-black"
                    />
                  )}

                  {/* <span className="mr-2 text-zinc-500 font-semibold">
                  ASSISTANT:
                </span>
                <span>{selectedConversation?.title}</span> */}
                </span>
              </div>
              <button
                type="button"
                onClick={() => setUpdateModalOpen(true)}
                className={classNames(
                  `p-2 ml-2 rounded cursor-pointer bg-transparent text-customDarkBlue`,
                  "flex items-center justify-center transition-colors hover:bg-zinc-100 hover:text-customDarkBlue"
                )}
              >
                <CogIcon className="size-4" />
              </button>
            </div>
            <ChatGPT
              addItemToTextEditor={addItemToTextEditor}
              selectedConversation={selectedConversation}
              setSelectedConversation={setSelectedConversation}
            />
          </div>
        ) : (
          <WrapperLoader loading={allConversationsLoader}>
            <div className="p-0 px-3">
              <div className="mb-3">
                <h3 className="h-toolbar text-md mb-2 border-b border-zinc-100 flex items-center">
                  Ai Chats
                </h3>
                <div
                  onClick={() => onOpenAIChatModal()}
                  className="p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-blue-400"
                >
                  <RoundButton onClickBtn={() => onOpenAIChatModal()} />
                </div>
              </div>

              <div
                className="overflow-y-hidden hover:overflow-y-auto transition-all"
                style={{ height: "calc(100vh - 163px)" }}
              >
                <ul className="mt-3 grid grid-cols-1">
                  {conversationsList?.length > 0
                    ? conversationsList.map((data: any, index: number) => (
                        <ConversationBox
                          key={index}
                          data={data}
                          onDelete={(e: any) =>
                            deleteConfirmationModal(e, data)
                          }
                          deleteLoader={false}
                          selectedData={selectedData}
                          onSelectConversation={() =>
                            onSelectedConversation(data)
                          }
                          selectedConversation={selectedConversation}
                        />
                      ))
                    : ""}
                </ul>
              </div>
            </div>
          </WrapperLoader>
        )
      ) : (
        <div
          className="flex items-center justify-center home-empty"
          style={{ height: "calc(100vh - 165px)" }}
        >
          <CompleteEmptyPage
            title="You don't have any AI chats"
            subTitle="Get started by creating a new chat"
            btnText="New Chat"
            onClickBtn={onOpenAIChatModal}
            icon={aiSVG}
          />
        </div>
      )}

      {aiChatsModal && (
        <ChatAIModal
          onCloseModal={onCloseAIChatModal}
          openModal={aiChatsModal}
          onUseTemplate={creatingConversation}
        />
      )}

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingConversation}
        deleteLoader={deleteLoader}
      />

      {selectedConversation?.conversation_configuration_id && (
        <ChatUpdateModal
          aiAssistantId={selectedConversation.conversation_configuration_id}
          open={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
        />
      )}
    </>
  );
}
