import React from "react";
import {
  BaseEdge,
  EdgeProps,
  getBezierPath,
  getSmoothStepPath,
  getStraightPath,
  EdgeLabelRenderer,
} from "@xyflow/react"; // Adjust the imports as needed

export function CircleAnimatedEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  style = {},
  data,
}: EdgeProps) {
  let edgePath;

  // Dynamically select the edge type
  if (data?.edgeType === "straight") {
    edgePath = getStraightPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    })[0];
  } else if (data?.edgeType === "bezier") {
    edgePath = getBezierPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    })[0];
  } else if (data?.edgeType === "step") {
    // Manually create step path (a simple polyline for step edges)
    edgePath = `M ${sourceX} ${sourceY} L ${(sourceX + targetX) / 2} ${sourceY} L ${(sourceX + targetX) / 2} ${targetY} L ${targetX} ${targetY}`;
  } else {
    edgePath = getSmoothStepPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    })[0];
  }

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} style={style} />
      <circle r="10" fill={`${data?.color}`}>
        <animateMotion dur="2s" repeatCount="indefinite" path={edgePath} />
      </circle>
    </>
  );
}

export function ImageOnEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: EdgeProps) {
  let edgePath: string;
  let labelX: number;
  let labelY: number;

  // Dynamically select the edge type
  if (data?.edgeType === "straight") {
    // Destructure the array returned by getStraightPath
    [edgePath, labelX, labelY] = getStraightPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
    });
  } else if (data?.edgeType === "bezier") {
    [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    });
  } else if (data?.edgeType === "step") {
    // Manually create step path (polyline for step edges)
    edgePath = `M ${sourceX} ${sourceY} L ${(sourceX + targetX) / 2} ${sourceY} L ${(sourceX + targetX) / 2} ${targetY} L ${targetX} ${targetY}`;
    labelX = (sourceX + targetX) / 2; // Set label at the midpoint
    labelY = (sourceY + targetY) / 2; // Adjust as needed for label position
  } else {
    // Default to smoothstep
    [edgePath, labelX, labelY] = getSmoothStepPath({
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition,
    });
  }

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          className="bg-white border border-zinc-400 p-1 rounded-md shadow-md"
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
          title={`${data?.label}`}
        >
          <img
            src={`${data?.imgUrl}`}
            alt={`${data?.label}`}
            className="w-[35px]"
          />
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
